import TableBtnDetail from "../TableBtnDetail"
import {useJobsByUserId} from "hooks/useJobsByUserId";
import LoadingIcon from "components/stateIcons/LoadingIcon"
import { Localization } from 'utils/localization'; 
import { useSearchParams } from 'react-router-dom';

const local = new Localization({
  en:{
    listOfJobs:"list of jobs"
  },
  es:{
    listOfJobs:"lista de trabajos"
  }
}).strings;

export default function BuyerJobs() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const result = useJobsByUserId(searchParams.get('data'));

  if (result.isLoading) {
    return  <LoadingIcon />
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>
  }
  
  return (
    <div>
      <h1 className="stageTitle"> {local.listOfJobs} </h1>
      <TableBtnDetail column= {['name','createdBy','truckCategories','trucksPerInterval','status','dateCreated','pickupTime']} dataArray={result.data} clickNav="JobDetail"/>
    </div>
  )
}