import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useUserData = () => {
  return useQuery({queryKey: ['/users/me'], refetchOnWindowFocus: false, queryFn: async()=>{
    const rpnse = await goFetch('/users/me');
    const rpn = await rpnse.json();
    return rpn;
  },
})
}