import {useQuery} from '@tanstack/react-query'
import goFetch from '../utils/goFetch'

export const useJobsByUserId = (id) => {
  return  useQuery({ queryKey: [`/jobs/byUser/${id}`], queryFn: async()=>{
    const rpnse = await goFetch(`/jobs/byUser/${id}`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json();
    return rpn;
  }})  
}