import './UserSettings.css';
import { useServerAssets } from "hooks/useServerAssets";
import goFetch from "../utils/goFetch";
import { useState } from 'react';
import { SettingsLocaliseString } from './UserSettings.local';
import { Localization } from '../utils/localization';

export default function UserSettings() {
  let strings = new Localization(SettingsLocaliseString).strings;

  const supportedLangs = useServerAssets( '/assets/supportLanguage' )
  const [isHover, setIsHover] = useState(false);
  const [localize,setLocal] = useState(localStorage.getItem("language"));


  const updateLanguage = async ( newCode ) => {
    strings.setLanguage(newCode);
    if ( supportedLangs?.data.includes( newCode ) ) {
      localStorage.setItem( "language", newCode );
      setLocal( newCode );
      let _userInfo = localStorage.getItem( "userInfo" );
      if (_userInfo) {
        _userInfo = JSON.parse(_userInfo) || {};
      } else {
        _userInfo = {}
      }
      await goFetch( `/users/setUserLanguage1/${_userInfo._id}` ); //goFetch gets localStorage.language and adds it to the header
      window.location.reload();
    }
  }
  const handleMouseEnter = () => {
    setIsHover(true);
 };
 const handleMouseLeave = () => {
    setIsHover(false);
 };
  return (
    <div className="parentDiv">
      <div className='change_language_container'>
        <p className='contentHeading'>{strings.change_language}</p>
        <div className='langBtns'>

          { supportedLangs?.data?.map( ( lang ) =>
            <button className='langBtn oBtn hidden md:block'
              style={ localize === lang ?
                { background: isHover ? 'var(--lightYellow)' : 'var(--yellow)', borderColor: 'var(--yellow)', height: "25px", width: "25px", alignSelf: "center", marginRight: 2, fontSize: "small" } :
                { background: 'var(--lightYellow)', color: 'gray', border: 'solid black 1px', height: "25px", alignSelf: "center", marginRight: 2, fontSize: "small" }
              }
              onClick={ () => updateLanguage( lang ) }
              key={ lang }
              onMouseEnter={ handleMouseEnter }
              onMouseLeave={ handleMouseLeave }
            >
              { localize === lang ? <u>{ lang }</u> : <b>{ lang }</b> }
            </button>
          ) }
        </div>
      </div>
    </div>
  )
}
