import Login from "../components/Login.js"
import Footer from "components/Footer";
export default function Onboarding(props){
    // I hate prop drilling but...
    return(
        <div>
            <Login setLogged={props.setLogged}></Login>
            <Footer/>
        </div>
    )
}