import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from "primereact/hooks";
import goFetch from "../../utils/goFetch";
import { columns } from "./column";
import { data } from "./DATA";
import ViewConstructionCompany from "./ViewConstructionCompany";
import './table.css';
import {useAxios} from '../../hooks/useAxios';
import { CompanyLocaliseString } from "./Company.local";
import { Localization } from "utils";
var _ = require( "lodash" );

export default function Table() {
  const strings = new Localization(CompanyLocaliseString).strings;
  const axios = useAxios();
  const [ visible, setVisible ] = useState( false );
  const [ currentPage, setCurrentPage ] = useState( 1 );

  const [ status, setStatus ] = useState( { 'status': 'NEEDS_REVIEW' } );
  const [ company, setCompany ] = useState( {} );
  const [ diloagData, setDiloageData ] = useState( "" );

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = currentPage * itemsPerPage - itemsPerPage;
  const { userColumns } = columns( openDialog );
  // const [currentItems, setCurrentItems] = useState(() =>
  //   data.slice(indexOfFirstItem, indexOfLastItem)
  // );
  const [ currentItems, setCurrentItems ] = useState( [] );
  const [ pageCount, setPageCount ] = useState( () =>
    Math.ceil( currentItems?.length / itemsPerPage )
  );
  const companyNames = removeDuplicates( data, "company_name" );
  // const _status = removeDuplicates(data, "status");
  const _status = [
    { status: 'NEEDS_REVIEW', t_label: strings["NEEDS_REVIEW"] },
    { status: 'VERIFIED', t_label: strings["VERIFIED"] },
    { status: 'REJECTED', t_label: strings["REJECTED"] }
  ];

  const [ searchString, debounceValue, setSearchString ] = useDebounce( "", 400 );

  // console.log(
  //   "currentItems",
  //   currentItems,
  //   "indexOfFirstItem",
  //   indexOfFirstItem,
  //   "indexOfLastItem",
  //   indexOfLastItem,
  //   data.slice(indexOfFirstItem, indexOfLastItem)
  // );

  async function getConstructionCompanyList() {
    try {
      const _response = await axios.post('/companies', {
        // "type": "construction",
        "status": status.status,
        // "companyId": "65df6174838fecd7ae63fd05",
        "page": currentPage,
        "limit": itemsPerPage,
        "searchString": searchString
      });
      setCurrentItems( _response.data.data );
      setPageCount( Math.ceil( _response.data.metadata.total / itemsPerPage ) );
    } catch(_e) {

    }

    /*
    const constructionCompanyResponse = await goFetch( '/companies', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify( {
        // "type": "construction",
        "status": status.status,
        // "companyId": "65df6174838fecd7ae63fd05",
        "page": currentPage,
        "limit": itemsPerPage,
        "searchString": searchString
      } )
  } );
    const arrResponse = await constructionCompanyResponse.json();
    console.log( "ConstructionCompany", arrResponse.data );
    setCurrentItems( arrResponse.data );
    setPageCount( Math.ceil( arrResponse.metadata.total / itemsPerPage ) );
    // if(arrResponse.success){
    //   setCurrentItems()
    //   alert(rpn.success)
    // }
    // return rpn;
    */
  }
  useEffect( () => {
    getConstructionCompanyList();
  }, [ status, currentPage ] );
  useEffect( () => {
    console.log( "Fetch Product Called", debounceValue );
    setCurrentPage( 1 );
    getConstructionCompanyList();
  }, [ debounceValue ] );

  // useEffect(() => {
  //   // searchResult("reset");
  //   getConstructionCompanyList();
  // }, [ status]);

  function removeDuplicates( data, field ) {
    const _n = _.uniqBy( data, function ( e ) {
      return e?.[ field ];
    } );
    return _n;
  }

  function openDialog( _data, _visible ) {
    setDiloageData( _data );
    setVisible( _visible );
  }

  function searchResult( op ) {
    let filteredData = [];

    if ( Object.keys( company ).length !== 0 && Object.keys( status ).length !== 0 ) {
      filteredData = data
        ?.filter( ( item ) =>
          item?.company_name
            ?.toLowerCase()
            ?.includes( company.company_name?.toLowerCase() )
        )
        .filter( ( item ) =>
          item.status.toLowerCase().includes( status.status.toLowerCase() )
        );
    } else if ( Object.keys( company ).length !== 0 ) {
      filteredData = data?.filter( ( item ) =>
        item?.company_name
          ?.toLowerCase()
          ?.includes( company.company_name?.toLowerCase() )
      );
    } else if ( Object.keys( status ).length !== 0 ) {
      filteredData = data?.filter( ( item ) =>
        item.status.toLowerCase().includes( status.status?.toLowerCase() )
      );
    } else {
      filteredData = data;
    }
    if ( op === "next" ) {
      const current = currentPage + 1;
      const result = filteredData.slice(
        current * itemsPerPage - itemsPerPage,
        current * itemsPerPage
      );
      setCurrentItems( result );
      setCurrentPage( current );
      setPageCount( Math.ceil( filteredData.length / itemsPerPage ) );
    } else if ( op === "previous" ) {
      const current = currentPage - 1;
      const result = filteredData.slice(
        current * itemsPerPage - itemsPerPage,
        current * itemsPerPage
      );
      setCurrentItems( result );
      setCurrentPage( current );
      setPageCount( Math.ceil( filteredData.length / itemsPerPage ) );
    } else if ( op === "reset" ) {
      const result = filteredData.slice(
        1 * itemsPerPage - itemsPerPage,
        1 * itemsPerPage
      );
      setCurrentItems( result );
      setCurrentPage( filteredData.length == 0 ? 0 : 1 );
      setPageCount( Math.ceil( filteredData.length / itemsPerPage ) );
    }
  }

  const nextPage = () => {
    // searchResult("next");
    setCurrentPage( currentPage + 1 );
  };

  const prevoiusPage = () => {
    // searchResult("previous");
    setCurrentPage( currentPage - 1 );
  };

  const table = useReactTable( {
    data: currentItems,
    columns: userColumns,
    enableFilters: true,
    enableColumnFilters: true,
    getCoreRowModel: getCoreRowModel()
  } );
  return (
    <>
      <ViewConstructionCompany
        diloagData={ diloagData }
        visible={ visible }
        setVisible={ setVisible }
      />
      <div className="userTable">
        <div className="headerFilters">
            <input
              className="inputSearch"
              placeholder={strings.search_by_placeholder}
              type="text"
              value={ searchString || "" }
              onChange={ ( event ) => {
                setSearchString(event.target.value);
              } }
            />
            <Dropdown
              className="statusFilter"
              value={ status }
              onChange={ ( e ) => {
                setStatus( e.value );
                setCurrentPage( 1 );
              } }
              options={ _status }
              optionLabel="t_label"
              placeholder={strings.select_status}
              filter
            />
            {/* <Dropdown
          value={company}
          onChange={(e) => setCompany(e.value)}
          options={companyNames}
          optionLabel="company_name"
          placeholder="Select a company"
          filter
        /> */}
          </div>

          { table.getHeaderGroups().map( ( headerGroup ) => (
            <div
              style={ {
                display: "flex",
                justifyContent: "space-evenly",
                fontWeight: "900",
                textAlign: "center",
                width: "100%"
              } }
              key={ headerGroup.id }
            >
              { headerGroup.headers.map( ( header ) => {
                return (
                  <div style={ { width: "100%" } } key={ header.id } className={ 'tdCell' }>
                    { header.isPlaceholder ? null : (
                      <div style={ { width: "100%" } }>
                        { flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        ) }
                      </div>
                    ) }
                  </div>
                );
              } ) }
            </div>
          ) ) }
          { currentItems.length <= 0 && <p>No Product Found</p> }
          { table.getRowModel().rows.map( ( row ) => {
            return (
              <div
                style={ {
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center"
                } }
                key={ row.id }
                tabIndex={ 0 }
                className="trDataC"
              >
                { row.getAllCells().map( ( cell ) => {
                  return (
                    <div style={ { width: "100%" } } key={ cell.id } className={ 'tdCellC' }>
                      { flexRender( cell.column.columnDef.cell, cell.getContext() ) }
                    </div>
                  );
                } ) }
              </div>
            );
          } ) }
          <div className="pagination">
            <button
              className="previous-button"
              onClick={ () => {
                prevoiusPage();
              } }
              disabled={ currentPage === 1 }
            >
              {strings.previous_option}
            </button>
            <span className="cPage">
              { currentPage }
            </span>
            {strings.of_option}
            <span className="cPage">
              { pageCount }
            </span>
            <button
              className="next-button"
              onClick={ () => {
                nextPage();
              } }
              disabled={ currentPage === pageCount }
            >
              {strings.next_option}
            </button>
          </div>
        </div>
      </>
      );
}
