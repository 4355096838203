import { useAllCompanies } from 'hooks/useAllCompanies'
import {useState} from 'react'
export default function SelectCompany(props) {
  const {data,isLoading,isError} = useAllCompanies()
  const [index,setIndex] = useState(props?.current ?? 0)
  const optionStyle = props?.optionStyle ?? {color:'black',padding:"20%"}

  function change(evt){
    setIndex(evt.target.value);
    if(props?.setOrgId){
      props?.setOrgId(evt.target.value);
      setIndex(evt.target.value)
    }
  }
  function submit(evt){
    setIndex(orgData[0]._id)
    if(props?.setOrgId){
      props?.setOrgId(orgData[0]._id);
    }
  }

  if (isLoading || isError) {
    return  <select/>
  }
  const orgData = data.filter((org)=>(org?.drivers.length>0 && org?.trucks.length>0))
  return (
    <>
      <select value={index} name="companyType" onChange={change} >
      { 
        orgData?.map((org,index)=>{
            return <option style={optionStyle} value={org._id} key={org.name+index}>{org.name}</option>
        } )
      }
      </select>
      {index === 0 && <button className='oBtn h-12 text-xl' onClick={submit}>Go</button>}

    </>
  )
}
