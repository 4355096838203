import React from 'react';
import Logo from '../../assets/appIconTest.png';
import { NavLink } from 'react-router-dom';
import {
  Sidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';

import Avatar from '../../assets/avatar.png';
import AvatarC from '../avatar/index';
import './index.scss';
import Toggle from '../../assets/Toggle';
import Home from '../../assets/Home';
import Property from '../../assets/Home';
import Logout from '../../assets/Home';
import { scrollToTop } from '../../utils/const';
import Auth from '../../utils/auth';
import { sideBarLocalizeStrings } from "./Sidebar.local";
import { Localization } from "utils";

const SideBarC = ( {
  collapsed,
  toggled,
  handleToggleSidebar,
  handleMenuItemClick,
  handleCollapsedChange,
  activeIndex,
} ) => {

  const strings = new Localization(sideBarLocalizeStrings).strings;

  function onPressLogOut() {
    Auth.logout();
    localStorage.setItem("userInfo", JSON.stringify({}));
  }

  return (
    <Sidebar
      className='sidebar-p'
      collapsed={ collapsed }
      toggled={ toggled }
      onToggle={ handleToggleSidebar }
      breakPoint="md">
      {/* Header */ }
      <div iconShape="circle">
        <div onClick={ handleCollapsedChange } className="i-g">
          <img src={ Logo } alt="logo" className="logo"  />
        </div>
        {/* Close Icon */ }
        {/* { !collapsed && (
          <MenuItem onClick={ handleCollapsedChange }>
            <Toggle />
          </MenuItem>
        ) } */}
      </div>
      {/* Content */ }
      <Menu iconShape="circle">
        {/* Home */ }
        <MenuItem
          className='a-menu-rm-p'
          // active={selectedOption === "dashboard"}
          active={ activeIndex === 0 }
          onClick={ () => {
            // scrollToTop();
            // handleMenuItemClick("dashboard");
            handleMenuItemClick( 0 );
          } }
        // icon={ <Home /> }
        >
          <NavLink className={ 'a-menu-item' } to="/drivers">{strings.sidebar_option_driver}</NavLink>
        </MenuItem>
        <MenuItem
          className='a-menu-rm-p'
          active={ activeIndex === 1 }
          onClick={ () => handleMenuItemClick( 1 ) }
        // icon={ <Property /> }
        >
          <NavLink className={ 'a-menu-item' } to="/construction-company">{strings.sidebar_option_company}</NavLink>
        </MenuItem>
        <MenuItem
          className='a-menu-rm-p'
          active={ activeIndex === 2 }
          onClick={ () => handleMenuItemClick( 2 ) }
        // icon={ <Property /> }
        >
          <NavLink className={ 'a-menu-item' } to="/settings">{strings.sidebar_option_settings}</NavLink>
        </MenuItem>
      </Menu>
      {/* Footer */ }
      <div className="sidebar-btn-wrapper">
        {/* <div className="footer-link">
          <Menu iconShape="circle">
            <MenuItem
              className='a-menu-rm-p'
              active={ activeIndex === 2 }
              onClick={ () => handleMenuItemClick( 2 ) }
            // icon={ <AvatarC image={ Avatar } size="large" shape="circle" /> }
            >
              <NavLink className={ 'a-menu-item' } to="/profile">Profile</NavLink>
            </MenuItem>
          </Menu>
        </div> */}
        <div className="footer-link">
          <Menu iconShape="circle">
            <MenuItem
            // icon={ <Logout /> }
            onClick={() => onPressLogOut()}
            >
              <NavLink >{strings.sidebar_option_logout}</NavLink>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default React.memo( SideBarC );
