import LoadingIcon from "components/stateIcons/LoadingIcon"
import TruckBtn from "components/modals/TruckBtn"
import {useQuery} from '@tanstack/react-query'
import TableBtnDetail from "components/TableBtnDetail";
import UserPermission from "components/wrappers/UserPermission";
import goFetch from 'utils/goFetch'
import Auth from "utils/auth";

export default function Companytrucks() {
  const companyId = Auth.getUserData().companyId; 
  const {data,isLoading,isError,error} = useQuery({ queryKey: [`/trucks/byCompany/${companyId}`], queryFn: async()=>{
    if(!companyId) return {};
    const rpnse = await goFetch(`/trucks/byCompany/${companyId}`);
    const rpn = await rpnse.json();
    return rpn;
  }})  
  
  if (isLoading) {
    return  <LoadingIcon />
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div>
      <h1 className="stageTitle"> Trucks for company </h1>
      <UserPermission perm="addTruckBtn">
        <TruckBtn/>
      </UserPermission>
      <TableBtnDetail column= {['category','make','model','licensePlate','cDotId']} dataArray={data} clickNav="TruckDetail" />
    </div>
  )
}
