import { Localization } from "utils";
import { faqStrings } from "./FAQ.local";

export default function FAQ() {
  const strings = new Localization(faqStrings).strings;

  return (
    <div className="FAQ" style={{ alignItems: "center", textAlign: "justify" }}>
      <h1 style={{ color: "white" }}>Frequently Asked Questions</h1>
      {/* <h1>{strings.privacyH1}</h1> */}
      <div style={{ color: "white", width: "50%" }}>
        <div>
          <p>
            <strong>What is Haulr?</strong>
            <br />
            Haulr is a mobile application designed to connect individuals and
            businesses with reliable, efficient dirt hauling services. Our
            platform streamlines the process of booking and managing trucking
            services for your construction, landscaping, or renovation projects.
          </p>
          <br />
          <br />
          <p>
            <strong>How do I book a truck on Haulr?</strong>
            <br />
            Booking a truck is simple. After downloading the Haulr app and
            creating an account, you can select the type of service you need,
            specify your location, and choose a suitable time. Our system will
            then match you with a hauler who meets your requirements.
          </p>
          <br />
          <br />
          <p>
            <strong>What types of hauling services does Haulr offer?</strong>
          </p>
          <p>
            We offer a range of services including, but not limited to,
            construction debris removal, landscape material delivery, and
            large-scale dirt removal. Our app allows you to specify your exact
            needs so we can provide the most appropriate service.
          </p>
          <br />
          <br />
          <p>
            <strong>How is the pricing determined?</strong>
          </p>
          <p>
            Pricing is based on several factors including the type of service,
            distance, and the amount of material to be hauled. Our app provides
            a pricing estimate before you confirm your booking.
          </p>
          <br />
          <br />
          <p>
            <strong>Are the haulers vetted?</strong>
          </p>
          <p>
            Yes, we ensure all haulers on our platform are fully licensed,
            insured, and vetted for their experience and reliability.
          </p>
          <br />
          <br />
          <p>
            <strong>Can I schedule services in advance?</strong>
          </p>
          <p>
            Absolutely. Haulr allows you to schedule services days or even weeks
            in advance. This helps ensure that you get the service you need,
            when you need it.
          </p>
          <br />
          <br />
          <p>
            <strong>What happens if I need to cancel or reschedule?</strong>
          </p>
          <p>
            You can cancel or reschedule through the app. Please refer to our
            cancellation policy in the app for details on any potential fees or
            restrictions.
          </p>
          <br />
          <br />
          <p>
            <strong>Is customer support available?</strong>
          </p>
          <p>
            Yes, our customer support team is available to assist you with any
            questions or issues. You can contact us through the app or via our
            website.
          </p>
          <br />
          <br />
          <p>
            <strong>
              How does Haulr ensure the safety and quality of the service?
            </strong>
          </p>
          <p>
            We have stringent safety and quality protocols in place. This
            includes regular checks on haulers&#39; equipment and ensuring
            compliance with all relevant safety regulations.
          </p>
          <br />
          <br />
          <p>
            <strong>
              Can businesses use Haulr for large-scale or regular hauling needs?
            </strong>
          </p>
          <p>
            Yes, Haulr is equipped to handle both one-time and regular hauling
            requirements for businesses of all sizes.
          </p>
        </div>
      </div>
    </div>
  );
}
