import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Image } from 'primereact/image';
import { Button } from "primereact/button";
import goFetch from "../../utils/goFetch";
import Auth from "../../utils/auth";
import './ViewConstructionCompany.css';
import { Link } from "react-router-dom";
import {useAxios} from '../../hooks/useAxios';
import { CompanyLocaliseString } from "./Company.local";
import { Localization } from "utils";

export default function Model( props ) {
  const strings = new Localization(CompanyLocaliseString).strings;
  const axios = useAxios();
  const [ data, setData ] = useState( {} );
  const [ largeImage, setLargeImage ] = useState( '' );

  const handleClose = () => {
    setData( {} );
    props.setVisible( false );
  };

  async function getDriverDetailInfo() {

    try {
      const rspnse = await axios.get(`/companies/byId/${ props?.diloagData?._id }`);
      console.log( "driverDetailResponse", rspnse?.data );
      let _rData = rspnse?.data;
      if (_rData?.reviewComment) {
      } else {
        _rData.reviewComment = '';
      }
      setData( _rData );
    } catch(_e) {

    }
    /*

    const driverDetailResponse = await goFetch( `/companies/byId/${ props?.diloagData?._id }`, {
      method: 'GET',
      headers: {
        'x-access-token': Auth.getToken()
      },
    } );
    let _parseResponse = await driverDetailResponse.json();
    _parseResponse.reviewComment = _parseResponse?.reviewComment || '';
    console.log( "driverDetailResponse", _parseResponse );
    setData( _parseResponse );
    if ( _parseResponse?.documents?.length > 0 ) {
      // setLargeImage( _parseResponse?.documents[ 0 ]?.ref || '' );
    }
    // fetch( `http://localhost:3001/users/byId/${ props?.diloagData?.id }`, {
    //   method: 'GET',
    //   headers: {
    //     'x-access-token': Auth.getToken(),
    //   },
    // } )
    //   .then( response => response.json() )
    //   .then( response => { console.log( response ); setData( response ); } )
    //   .catch( err => console.error( err ) );
    */
  }

  useEffect( () => {
    if ( props?.visible && props?.visible == true ) {
      // let _documents = props.diloagData.document;
      // _documents = _documents.map((item) => {
      //   return {
      //     ...item,
      //     status: "Pending",
      //     message: ""
      //   };
      // });
      // console.log("_documents", _documents);
      // let _d = props.diloagData;
      // _d.document = _documents;
      // setData(_d);
      getDriverDetailInfo();
    } else if ( props?.visible == false ) {
      setData( {} );
      setLargeImage( '' );
    }
    console.log( "props.visible", props?.visible );
  }, [ props.visible ] );

  const handleAccepted = ( documentId ) => {
    setData( {
      ...data,
      documents: data?.documents?.map( ( item ) => {
        if ( item._id === documentId ) {
          item.status = "ACCEPTED";
          item.reviewComment = "";
        }
        return item;
      } )
    } );
  };

  const handleReject = ( documentId ) => {
    setData( {
      ...data,
      documents: data?.documents?.map( ( item ) => {
        if ( item._id === documentId ) {
          item.status = "REJECTED";
          item.reviewComment = "";
        }
        return item;
      } )
    } );
  };

  const onSubmit = () => {
    console.log( "data", data );
  };

  const onSubmitApproved = async () => {
    try {
      const _reqBody = {
        "companyId": data?._id,
        "status": "VERIFIED",
      };
      const verifiedConstructionCompanyResponse = await goFetch( '/companies/verify', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( _reqBody )
      } );
      if ( verifiedConstructionCompanyResponse ) {
        const _r = await verifiedConstructionCompanyResponse.json();
        if ( _r?.error && _r?.error?.message ) {
          alert( _r?.error?.message )
        } else {
          props.setVisible( false );
        }
      }
    } catch ( e ) {
      alert( e?.error?.message )
    }
  }
  const onSubmitRejected = async () => {
    console.log("data?.documents", data);
    if(data?.reviewComment?.length == 0) {
      alert(strings.reject_reviewComment)
      return;
    }
    const _checks = data?.documents.filter((item) =>  item?.reviewComment?.length === 0 && item?.status == "REJECTED")
    if (_checks.length > 0) {
      alert(strings.reject_reviewComment_2)
      return;
    }
    const _documentForRequestBody = data.documents.map( ( dx ) => {
      return {
        ...dx,
        'documentId': dx?._id
      }
    } );
    try {
      const _reqBody = {
        "companyId": data?._id,
        "status": "REJECTED",
        "reviewComment": data?.reviewComment || '',
        "documents": _documentForRequestBody
      };
      console.log( "_reqBody", _reqBody );
      const rejectedConstructionCompanyResponse = await goFetch( '/companies/verify', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( _reqBody )
      } );
      if ( rejectedConstructionCompanyResponse ) {
        const _r = await rejectedConstructionCompanyResponse.json();
        if ( _r?.error && _r?.error?.message ) {
          alert( _r?.error?.message )
        } else {
          props.setVisible( false );
        }
      }
    } catch ( e ) {
      alert( e?.error?.message )
    }
  }
  const onSubmitCancel = () => {

  }

  console.log( "largeImage", largeImage );
  return (
    <div>
      <Dialog
        header={ data.id || strings.loading }
        visible={ props.visible }
        style={ { width: "95vw" } }
        onHide={ handleClose }
        draggable={ false }
      >
        {
          Object.keys( data ).length == 0 && <p className="pleaseWait">{strings.please_wait_detail}</p>
        }
        {
          Object.keys( data ).length > 0 &&
          (
            <div className="parentDiv">
              <div className="sixtyFive">
                <div className="divTr">
                  <div className="divTd">
                    <span className="contentHeading">
                    {strings.first_name_detail}
                    </span>
                    <p className="content">{ data?.owner?.firstName || 'NA' }</p>
                  </div>
                  <div className="divTd">
                    <span className="contentHeading">
                    {strings.last_name_detail}
                    </span>
                    <p className="content">{ data?.owner?.lastName || 'NA' }</p>
                  </div>
                </div>
                <div className="divTr">
                  <div className="divTd">
                    <span className="contentHeading">
                    {strings.email_detail}
                    </span>
                    <p className="content">{ data?.owner?.email || 'NA' }</p>
                  </div>
                  <div className="divTd">
                    <span className="contentHeading">
                    {strings.phone_number_detail}
                    </span>
                    <p className="content">{ data?.owner?.phoneNr || 'NA' }</p>
                  </div>
                </div>
                <div className="divTr">
                  <div className="divTd">
                    <span className="contentHeading">
                      {strings.name_detail}
                    </span>
                    <p className="content">{ data?.name || 'NA' }</p>
                  </div>
                  <div className="divTd">
                    <span className="contentHeading">
                    {strings.company_id_detail}
                    </span>
                    <p className="content">{ data?._id || 'NA' }</p>
                  </div>
                </div>
                <div className="divTr docMT">
                  <p className="contentHeading">{strings.documents_detail}</p>
                </div>
                {/* {
          Object.keys(data).length > 0 && data?.documents?.length > 0 &&
          <p className="contentHeading">Documents</p>
        } */}

                <div>
                  { data?.documents?.map( ( item, index ) => (
                    <div
                      className="tblRow"
                      key={ item._id }
                    >
                      <span className="title">{ item?.documentTitle || ' ' }</span>
                      <div onClick={ () => setLargeImage( item.ref ) }>
                        {/* <Image className="docImage" src={ item?.ref || ' ' } alt="Image" width="250" /> */ }
                        <iframe className="docImage" src={ item?.ref || ' ' } name="iframe_a" height="250px" width="250px" title="Iframe"></iframe>

                        {/* <p className="subContent">Click to View Zoom</p> */}
                        <Link className="subContent" target="_blank" to={ item.ref }>{strings.click_to_view_zoom_detail}</Link>
                      </div>
                      <Button className="button_c" onClick={ () => handleAccepted( item._id ) } label={strings.accept_detail} />
                      <Button className="button_c"
                        onClick={ () => {
                          handleReject( item._id );
                        } }
                        label={strings.reject_detail}
                      />
                      <span className="status">{ strings[item?.status] || ' ' }</span>
                      {/* {item.message !== "" && item.message !== undefined && (
                <div>Message: {item.message}</div>
              )} */}
                      { item?.status == "REJECTED" && (
                        <div>
                          <textarea
                            className="inputReview"
                            placeholder={strings.review_comment_detail}
                            type="text"
                            value={ item?.reviewComment || "" }
                            onChange={ ( event ) => {
                              setData( {
                                ...data,
                                document: data?.documents?.map( ( ix ) => {
                                  if ( ix._id === item._id ) {
                                    ix.reviewComment = event.target.value;
                                  }
                                  return ix;
                                } )
                              } );
                            } }
                          />
                          {/* <button onClick={() => handleRejectConfirm(item.id)}>
                    Confirm Reject
                  </button> */}
                        </div>
                      ) }

                    </div>
                  ) ) }
                </div>
                {
                  Object.keys( data ).length > 0 &&
                  <>
                    <div className="commonReview">
                      <textarea
                        className="inputReview"
                        placeholder={strings.review_comment_general}
                        type="text"
                        value={ data?.reviewComment || "" }
                        onChange={ ( event ) => {
                          setData( {
                            ...data,
                            reviewComment: event.target.value
                          } );
                        } }
                      />
                    </div>
                    <div className="optionsButton">
                      <Button label={strings.submit_approved_detail} className="button_other" onClick={ () => onSubmitApproved() } />
                      {/* <button onClick={ () => onSubmitApproved() }>Submit Approved</button> */ }
                      <Button label={strings.submit_rejected_detail} className="button_other" onClick={ () => onSubmitRejected() } />
                      <Button label={strings.cancel_detail} className="button_other" onClick={ () => handleClose() } />
                    </div>
                  </>
                }
              </div>

              <div className="thirtyFive">
                {/* <Image className="docImageLarge" src={ largeImage } alt="Image" width="250" /> */ }
              </div>
            </div>
          )
        }
      </Dialog>
    </div>
  );
}