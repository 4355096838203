/*

Document

THis uses QUERY_DOCUMENT to get an documentType from the server.

props: 

  documentType : String - REQUIRED - this is the key to search documents array for this type.

*/

import FileUpload from "./FileUpload";
import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import LoadingIcon from "components/stateIcons/LoadingIcon"
export default function Document({ documentType,targetId,className }) {

  const {data,isError,isLoading,error} = useQuery({ queryKey: [`/users/document/${documentType}/${targetId}`], 
    queryFn: async()=>{
    const rpnse = await goFetch(`/users/document/${documentType}/${targetId}`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json({targetId});
    return rpn;
  },
  staleTime: 1000 * 60 * 60
  })  

  return (
		<div>
      {
        isLoading ? <LoadingIcon /> : 
        isError ? <span>Error: {error.message}</span> :
        data && !data.error ? <div> <img src={data.compressedBase64} className={className} alt={documentType}/> </div>:
        <FileUpload documentType={documentType} targetId={targetId}/>
      }
        {/* <FileUpload documentType={documentType} targetId={targetId}/> */}
        
		</div>
	);
}
