import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useMutateJob = (jobId) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({mutate,jobId})=>{
      const rspnse = await goFetch(`/jobs/mutate/${jobId}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(mutate)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error && jobId){
        return await queryClient.setQueryData([`/jobs/byId/${jobId}`],oldData=> ({...oldData, ...data}))
      }
    },
  })
}