import MutateStatus from 'components/MutateStatus';
import Error from 'components/stateIcons/Error';
import {useRef} from 'react'
import goFetch from 'utils/goFetch'
import {useMutation} from '@tanstack/react-query'
export default function InviteNewCompanyBtn() {
  const modalRef = useRef()
  const formRef = useRef()
  const inviteOrg = useMutation({
    mutationFn: async (newUser)=>{
      const rspnse = await goFetch('/companies/invite', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error){
        alert('Invite sent!')
        modalRef.current.close()
      }
    },
  });  

  function backgroundClose(e){
    const dia = modalRef.current.getBoundingClientRect()
    if(e.clientX < dia.left ||
      e.clientX > dia.right ||
      e.clientY < dia.top ||
      e.clientY> dia.bottom
      ){
       modalRef.current.close()
      }
  }
  
  function handleSubmit(evt){
    evt.preventDefault();
    const submit = {
      companyName:formRef.current.elements.companyName.value,
      email:formRef.current.elements.email.value.toLowerCase(),
      companyType:formRef.current.elements.companyType.value,
      //language:formRef.current.elements.language.value,
    }
    inviteOrg.mutate(submit);
  }

  return (
    <div>
      <button onClick={() => modalRef.current.showModal()} className="oBtn">Add Company</button>
      <dialog className='modalStyle' ref={modalRef} onClick={backgroundClose}>
      <Error className="top-1 left-1" useMutation={inviteOrg}/>
        <h1>Invite Company</h1>
        <form onSubmit = {handleSubmit} ref={formRef} method="dialog">
          <div className='flex flex-col'>
          <label htmlFor="companyName">Company Name </label>
          <input placeholder='Company' type="text" name="companyName" required />
          <label htmlFor="email">Admin Email</label>
          <input placeholder='johndoe@email.com' type="text" name="email" required />
          <label htmlFor="companyType">Company Type  </label>
            <select className='text-black' name="companyType">
              <option value="trucking">Trucking</option>
              <option value="construction">Construction</option>
            </select>
          </div>
          <button onClick={()=>modalRef.current.close()} className="oBtn" type="button">Close</button>
          <button onClick={handleSubmit} className="oBtn">Invite</button>
          <MutateStatus useMutation={inviteOrg} />
        </form>
      </dialog>
    </div>
  )
}
