import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import LoadingIcon from "components/stateIcons/LoadingIcon"
import TableBtnDetail from "components/TableBtnDetail"

export default function AllUsers() {
  const {data,isLoading,isError,error} = useQuery({ queryKey: ['allUsers'], queryFn: async()=>{
    const rpnse = await goFetch('/users');
    const rpn = await rpnse.json();
    return rpn;
  }})
  if (isLoading) {
    return  <LoadingIcon/>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }  
  return (
    <div>
      <h1 className="stageTitle">  All Users </h1>
      <TableBtnDetail column= {['username','role','companyId','firstName','lastName','phoneNr','email']} dataArray={data} clickNav="UserDetail" />
    </div>
  )
}
