import {useRef,useEffect} from 'react'
import {  useParams,useNavigate } from 'react-router-dom';
import goFetch from 'utils/goFetch';
import {useQueryClient} from '@tanstack/react-query'
import Auth from 'utils/auth';
import Footer from 'components/Footer';
import { useUserData } from "hooks/useUserData"
export default function ResetPassword() {
  const {userId} = useParams();
  const {data} = useUserData();
  const formRef = useRef()
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  useEffect(() => {
    if(data){
      if(!data.forcePasswordReset){
        queryClient.invalidateQueries({ queryKey: [`/users/me`] })  
        navigate("/userhome")
      }
    }
  }, [data?.forcePasswordReset])
  
  async function submitPassword(evt){

    evt.preventDefault();
    const submit = {
      password:formRef.current.elements.password?.value,
      userId
    }
    
    const rspnse = await goFetch('/users/resetPassword/', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(submit)
    });

    const rpn = await rspnse.json();
    if(rpn){
      if(rpn.token){
        Auth.login(rpn.token);
        queryClient.invalidateQueries({ queryKey: [`/users/me`] })  

      } 
    } 
  }
  
  return (
    <>
      <div className="mainPage">
        <h1> Please enter your new password:</h1>
        <form onSubmit = {submitPassword} ref={formRef} >
          <div className='flex flex-col'>
            <label htmlFor="password">Password:  <input type="text" name="password"  required /></label>
          </div>
          <button onClick={submitPassword} className="oBtn">SUBMIT</button>
        </form>          
      </div>
      <Footer/>
    </>
    )
}
