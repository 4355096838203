import './SelectLanguage.css'

export default function SelectLanguage(props){
    return <div className='selectLang'>
        <h1>Select Language</h1>
        <div className='langBtnWrap'>
            <button onClick={()=>props.setLanguage("en")} className="oBtn">English</button>
            <button onClick={()=>props.setLanguage("es")} className="oBtn">Español</button>
        </div>
    </div>
}