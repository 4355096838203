import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useCreateJob = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (newJob)=>{
      const rspnse = await goFetch('/jobs/create', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newJob)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: ['activeJobs'] })
      }
    },
  })
}