import {useRef,useState} from 'react'
import { useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import ModalMaterialUI from './ModalMaterialUI';
import MutateStatus from 'components/MutateStatus';
export default function RejectJobBtn({groupBy}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formRef = useRef()
  const queryClient = useQueryClient()
  
  const rejectByGroup =  useMutation({
    mutationFn: async ({groupBy,unreject})=>{
      const rspnse = await goFetch('/tickets/rejectByGroup', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({groupBy,unreject})
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/tickets/groupBy/${groupBy}`] })
      }
    },
  })

  function handleSubmit(evt){
    evt.preventDefault();
    if(formRef.current.elements.refer.value=== 'reject'){
      rejectByGroup.mutate({groupBy})
    }
  }
  return (
    <>
      <button onClick={handleOpen} className="dangerBtn">Reject</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        <Error className="top-1 left-1" useMutation={rejectByGroup}/>
          <h1>Reject Job</h1>
          { rejectByGroup.status ? <div>{rejectByGroup.status}</div> : <div/> }
          <form onSubmit = {handleSubmit} ref={formRef}  >
            <h2> Please enter: reject</h2>
            <label htmlFor="refer"  > <input type="text" name="refer" required  /></label>
            <button onClick={handleSubmit} className="oBtn">Reject</button>
            <button onClick={handleClose} className="oBtn">Close</button>
          </form>
          <MutateStatus useMutation={rejectByGroup} />
      </ModalMaterialUI>
    </>
  )
}
