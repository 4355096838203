import { aboutStrings } from "./About.local";
import { Localization } from "../utils/localization";

export default function About() {
  const strings = new Localization(aboutStrings).strings;

  return (
    <div
      className="About"
      style={{ alignItems: "center", textAlign: "justify" }}
    >
      <h1 style={{ color: "white" }}>About Us at Haulr</h1>
      <div className="aboutInfoBox" style={{ color: "white", width: "50%" }}>
        <p>
          Welcome to Haulr - your trusted partner in revolutionizing the dirt
          hauling and trucking industry. Founded by a team of passionate
          innovators and logistics experts, Haulr was born out of a desire to
          simplify and streamline the hauling process for both customers and
          truck operators.
        </p>
        <br />
        <p>
          <strong>Our Journey</strong>
        </p>
        <p>
          Our journey began with a simple observation: the process of hiring
          trucks for dirt hauling was cumbersome, time-consuming, and
          inefficient. We saw an opportunity to leverage technology to solve
          these challenges. Thus, Haulr was created - an app that connects
          individuals and businesses needing hauling services with reliable,
          vetted truck operators at the click of a button.
        </p>
        <br />
        <p>
          <strong>Our Mission</strong>
        </p>
        <p>
          At Haulr, our mission is to provide a seamless, hassle-free hauling
          experience. We are dedicated to offering efficient, reliable, and
          eco-friendly services. Our platform not only makes hauling services
          more accessible but also contributes to reducing environmental impact
          through optimized routes and full truckload strategies.
        </p>
        <br />
        <p>
          <strong>Our Services</strong>
        </p>
        <p>
          We specialize in a variety of hauling services including construction
          debris removal, landscaping material transport, and large-scale dirt
          moving. Our user-friendly app allows customers to easily book, track,
          and manage their hauling needs, ensuring a transparent and efficient
          service.
        </p>
        <br />
        <p>
          <p>
            <strong>Our Values</strong>
          </p>
          <strong>Customer Focus</strong>: We are committed to delivering
          exceptional service and support to our customers.{" "}
          <strong>Innovation</strong>: We continuously seek to improve and
          innovate our services and technology. <strong>Integrity</strong>: We
          operate with honesty and transparency, building trust with our
          customers and partners. <strong>Sustainability</strong>: We are
          dedicated to environmentally friendly practices in all aspects of our
          business.{" "}
        </p>
        <br />
        <p>
          <strong>Our Team</strong>
        </p>
        <p>
          Led by industry veterans and technology enthusiasts, our team is the
          heart of Haulr. We are driven by a shared vision to transform the
          hauling industry, making it more efficient, sustainable, and
          customer-friendly.
        </p>
        <br />
        <p>
          <strong>Join Us</strong>
        </p>
        <p>
          Be it for your business or personal needs, Haulr is here to make your
          hauling experience better than ever. Join us on this journey to
          transform the way hauling works. We are Haulr - moving forward,
          together.
        </p>
      </div>
    </div>
  );
}
