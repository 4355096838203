import {useState,useEffect} from 'react'
import dayjs from 'dayjs'

export default function SubmitDate({dates,setDates}) {
  const [startDate,setStartDate] = useState(dayjs( new Date().toDateString().slice(4), 'MMM DD YYYY')); // xtra stuff is to ensure time is set to midnight
  const [endDate,setEndDate] = useState(dayjs( new Date().toDateString().slice(4), 'MMM DD YYYY'));// xtra stuff is to ensure time is set to midnight
  useEffect(() => {
    const hours = endDate.diff(startDate,'hours')
    const days = Math.ceil(hours / 24) + 1;
    let local = [];
    for(let i = 0; i < days;i++){
      local.push( startDate.add(i,'days').format('YYYY-MM-DD'));
    }
    setDates([...local])
  }, [startDate,endDate])

  function modArray(evt){
    if(evt.target.checked){
      dates.push(evt.target.getAttribute('data-date'))
      dates.sort()
    } else {
    }
  }

  return (
    <>

    <label className="flex items-center flex-col md:flex-row">
      <h1 className='text-white'>
      Between what dates?
      </h1 >
      <input type="date" className='textInput' name="startDate" value={
            startDate.format('YYYY-MM-DD')
          }
          onChange={(e) => {
            setStartDate(dayjs(e.target.value));
          }}/>

      <input type="date" className='textInput' name="endDate" value={
            endDate.format('YYYY-MM-DD')
          }
          onChange={(e) => {
            setEndDate(dayjs(e.target.value));
          }}/>
    </label>

    <div  className="flex items-center text-white flex-col flex-wrap h-[11rem]">
      {dates.map((date,index)=>
      <div key={index} className='flex items-center'>
        <input type="checkbox" data-date={date} defaultChecked="true" onChange={modArray}></input>
        {date}
      </div> 
      )}
    </div>
  
  </>
  )
}

