import {useState,useRef,useEffect} from 'react'
import dayjs from 'dayjs'
import SubmitName from './SubmitName';
import SubmitDate from './SubmitDate';
import SubmitTime from './SubmitTime';
import SubmitNote from './SubmitNote';
import SubmitRate from './SubmitRate';
import SubmitMaterial from './SubmitMaterial';
import SubmitTruck from './SubmitTruck';
import SubmitInterval from './SubmitInterval';
import SubmitLocations from './SubmitLocations';
import SubmitConstruction from './SubmitConstruction';
import SubmitDropLocation from './SubmitDropLocation';
import customParseFormat from "dayjs/plugin/customParseFormat";
import Error from 'components/stateIcons/Error';
import Auth from 'utils/auth';
import { useCreateJob } from "hooks/useCreateJob";
import { useNavigate } from 'react-router-dom'
import { useServerAssets } from 'hooks/useServerAssets';
import { useSearchParams } from 'react-router-dom';
export default function SubmitJob() {
  dayjs.extend(customParseFormat)
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const projectId = searchParams.get('data');
  const [stage,setStage] = useState(0);
  const [truckTypes,setTruckTypes] = useState([]);
  const [construction,setConstruction] = useState();

  const [dates,setDates] = useState([]); // passed too SubmitDate to modifty, this is an array of working days.
  const formRef = useRef();
  const createJob = useCreateJob();
  const truckCategories = useServerAssets(`/assets/truckCategories`);
  const stages = [
    <SubmitName/>,
    <SubmitConstruction construction={construction}  setConstruction={setConstruction} projectId={projectId}/>,
    <SubmitMaterial/>,
    <SubmitDate dates={dates} setDates={setDates}/>,
    <SubmitTime/>,
    <SubmitRate/>,
    <SubmitTruck truckTypes={truckTypes} setTruckTypes={setTruckTypes}/>,
    <SubmitInterval/>,
    <SubmitLocations/>,
    <SubmitDropLocation/>,
    <SubmitNote/>
  ];
  const navigate = useNavigate();
  function prevStage(evt){
    if(stage > 0){
      setStage(stage-1);
    }
  }
  function nextStage(evt){
    if(stage < stages.length-1 ){
      setStage(stage+1);
    }
  }
  async function submit(evt){
    evt.preventDefault();
    const start =    dayjs(formRef.current.elements.startDate.value, "YYYY-MM-DD")
    const end =    dayjs( formRef.current.elements.endDate.value, "YYYY-MM-DD")

    const pickAddres= {
      postalAddress:formRef.current.elements.pickAddress.value,
      city: formRef.current.elements.pickCity.value,
      state:formRef.current.elements.pickState.value,
      zipCode:formRef.current.elements.pickZip.value,
      latitude:formRef.current.elements.pickLatitude.value,
      longitude:formRef.current.elements.pickLongitude.value,
    }
    const dropAddres= {
      postalAddress:formRef.current.elements.dropAddress.value,
      city: formRef.current.elements.dropCity.value,
      state:formRef.current.elements.dropState.value,
      zipCode:formRef.current.elements.dropZip.value,
      latitude:formRef.current.elements.dropLatitude.value,
      longitude:formRef.current.elements.dropLongitude.value,
    }

    if(!pickAddres.longitude && formRef.current.elements.pickAddress.value){
      const strng = formRef.current.elements.pickAddress.value.replace(/\s/g, '%20') ;
      const rspnt = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${strng}%2C%20${formRef.current.elements.pickCity.value}%2C%20${formRef.current.elements.pickState.value}%20${formRef.current.elements.pickZip.value}.json?types=address&access_token=pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg`)
      const rpn = await rspnt.json();
      if(rpn.features[0]){
        pickAddres.longitude =  rpn.features[0].center[0]
        pickAddres.latitude = rpn.features[0].center[1]
      }
    }
    if(!dropAddres.longitude && formRef.current.elements.dropAddress.value){
      const strng2 = formRef.current.elements.dropAddress.value.replace(/\s/g, '%20') ;
      const rspnt2 = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${strng2}%2C%20${formRef.current.elements.dropCity.value}%2C%20${formRef.current.elements.dropState.value}%20${formRef.current.elements.dropZip.value}.json?types=address&access_token=pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg`)
      const rpn2 = await rspnt2.json();
      if(rpn2.features[0]){
        dropAddres.longitude =  rpn2.features[0].center[0]
        dropAddres.latitude = rpn2.features[0].center[1]
      }
    }

    const submit={
      name:formRef.current.elements.jobName.value,
      projectId,
      materialHauled:formRef.current.elements.materialHauled.value,
      createdBy:Auth.getUser().data._id, 
      workDays:dates.map(date=>dayjs(date+formRef.current.elements.dailyStartTime?.value,"YYYY-MM-DDHH:mm").format()),
      constructionId: construction,
      trucksPerInterval:formRef.current.elements.trucksPerInterval?.value,
      estimatedWorkHours: formRef.current.elements.estimatedWorkHours?.value,
      numberOfTrucksNeeded:  formRef.current.elements.numberOfTrucksNeeded?.value,
      dailyStartTime: dayjs(formRef.current.elements.dailyStartTime?.value,"HH:mm").format(),
      truckCatorgies:truckTypes, // array of strings
      truckTypes : truckTypes.map(type=>truckCategories.data.indexOf(type)), // array of numbers
      notes:formRef.current.elements.note?.value,
      brokerRate:formRef.current.elements.brokerRate?.value,
      truckIntervalMinutes:formRef.current.elements.truckIntervalMinutes?.value,
      scheduledStart:start.format() ,
      scheduledEnd:end.format() ,
      locations:[{address:pickAddres,longitude:pickAddres.longitude,latitude:pickAddres.latitude,locationType:"Pick up"},{address:dropAddres,longitude:dropAddres.longitude,latitude:dropAddres.latitude,locationType:"Dropoff"}],
    }
    if(!pickAddres.longitude){
      alert('Pick up address error')
    } else 
    if(!dropAddres.longitude){
      alert('drop up address error')
    } else {
      createJob.mutate(submit);
    }

  }
  useEffect(() => {
    if(!createJob.data?.error && createJob.data?._id){
      navigate(`/dashboard?stage=JobDetail&data=${createJob.data?._id}`);
    } else if(createJob.data?.error) {
      alert(createJob.data.error.message)
    }
  }, [createJob.data])
  

  return (
    <div className='flex flex-col justify-top items-center'>
      <Error className="top-1 left-1" useMutation={createJob}/>
      <h1 className="text-white">Submit Job </h1> 
      <h1 className="text-white"> {projectId ? "for project" : ""} </h1>

      
      <form ref={formRef} onSubmit={submit} className='h-3/5 flex items-center p-5'>
        {
          stages.map((thisStage,index)=>
          <>
          <div className={index === stage ? '' : 'hidden'}>
            {thisStage}
          </div>
          </>
          )
        }
      </form>
      <br/>
      {
          stage === stages.length-1 ? 
          <button className='oBtn h-[5rem] w-[10rem]' onClick={submit}>Submit Job</button>:
          <button className='oBtn h-[5rem] w-[10rem]' onClick={nextStage}>Next</button>
      }
      <br/>
      {
          stage === 0 ? 
          <></>:
          <button className='oBtn h-[5rem] w-[10rem]'  onClick={prevStage}>Prev</button>
      }

    </div>
  )
}
