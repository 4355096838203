import {useMutation} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useTicketUpdateByDriver = () => {
  return useMutation({
    mutationFn: async (update)=>{
      const rspnse = await goFetch('/tickets/driverUpdate', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(update)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
  })
}