import "./TicketTableContent.css"
import { useNavigate } from 'react-router-dom'
import ParseDate from 'components/wrappers/ParseDate'

export default function TicketTableContent(props){
    const selectedCompany=props?.selectedCompany
    const companyItems=props?.companyItems.filter(item=>item[0]?.companyId?.name === selectedCompany)
    const navigate = useNavigate();

    function examineAssigments(assigments){
      navigate(`/dashboard?stage=TicketDetail&data=${assigments[0].groupBy}`);
    }

    return <>
    {companyItems && 
      <div>
        <table>
          <thead>
            <tr>
              <th className='tableHeader'>Driver</th>
              <th className='tableHeader'>Truck</th>
              <th className='tableHeader'>Status</th>
              <th className='tableHeader'>Scheduled Start</th>
              <th className='tableHeader'>Total Days</th>
            </tr>
          </thead>
          <tbody>
            {companyItems.map((item)=>{
              return <tr key={item[0]?._id+"table"} onClick={()=>examineAssigments(item)}>
                <td key={item[0]?._id+"username"  } data-cell="username" >{item[0]?.driverId?.username}</td>
                <td key={item[0]?._id+"truck"} data-cell="truck">{item[0]?.truckId?.make} {item[0]?.truckId?.model} <br/> {item[0]?.truckId?.licensePlate}</td>
                
                <td key={item[0]?._id+"startDate"} data-cell="startDate"><ParseDate>{item[0]?.startDate}</ParseDate></td>
                <td key={item[0]?._id+"totalDays"} data-cell="totalDays">{item.length}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
  }
  </>
}