import React from 'react'
import OpenCompanyJobs from './OpenCompanyJobs'
import OpenActiveJobs from './OpenActiveJobs'
import UserPermission from 'components/wrappers/UserPermission'
export default function Board() {
  return (
    <div className='bg-slate-300 p-5'>
      <h1>Boards</h1>
        <UserPermission perm='jobsForCompany'>
          <OpenActiveJobs/>
        </UserPermission>
        <UserPermission perm='jobsForDriver'>
          <OpenCompanyJobs/>   
        </UserPermission>
    </div>
  )
}
