import {useState} from 'react'

export default function Chip(props){
    const title=props?.title
    const selected = props?.selected

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
       setIsHover(true);
    };
    const handleMouseLeave = () => {
       setIsHover(false);
    };
 
    if(selected){
        return <div onClick={props?.onClick} className='chip' style={{backgroundColor: isHover ? 'var(--lightGray)' : props.title === '+'? 'var(--yellow)' : 'var(--gray)',color: isHover ? 'black' : 'white',padding:10,margin:"5px 1px",borderRadius:"10px 10px 5px 5px",border: '2px solid black '}}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    {title}
                </div>
    }

    return <div onClick={props?.onClick} className='chip' style={{backgroundColor: isHover ? 'var(--lightGray)' : props.title === '+'? 'var(--yellow)' : 'var(--medGray)',color: isHover ? 'black' : 'gray',padding:10,margin:"5px 1px",borderRadius:"10px 10px 5px 5px",border: '1px solid black '}}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                    {title}
            </div>
}