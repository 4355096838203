import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useUserProjects = () => {
  return useQuery({queryKey: ['/projects/myProjects'], queryFn: async()=>{
    const rpnse = await goFetch('/projects/myProjects');
    const rpn = await rpnse.json();
    return rpn;
  }, 
  refetchInterval:10000
})
}