import * as React from 'react';


export default function Fulfillment() {
  return (
   <div className='bg-[var(--gray)] flex  justify-start items-center text-center flex-col text-slate-300 h-[100dvh]'>
    <h1 className="stageTitle">Fulfillment Policy</h1>
    <div className='m-3'>
        <h2>CANCELLATION POLICY</h2>
        <p className='text-slate-200 text-xl'>Customer may cancel orders within 3 days of the starting date. </p>
        <p className='text-slate-200 text-xl'>Cancelation process is as simple as clicking the cancel button in the job details.</p>
        <p className='text-slate-200 text-xl'>To cancel a job already processing please contact customer support, same day cancelation are subject too fees.         </p>
        <p className='text-slate-200 text-xl'>4 Hour minimum work day.</p>
        <br/>
        <br/>
        <h2>REFUND POLICY</h2>
        <p className='text-slate-200 text-xl'>We offer full refunds on all services within 90 days of invoice payment date.</p>
        <p className='text-slate-200 text-xl'> Refunds occur when driver did not show up in a 60 minute window on the start time.</p>
        <p className='text-slate-200 text-xl'> To initiate a refund please contact customer support, we will review logs and determine if a refund is nesscary </p>
        <p className='text-slate-200 text-xl'> Refunds will be a return to payment via credit card. Haulrcorp reserves the right to refund in credit too.</p>
      </div>
   </div>
  );
}