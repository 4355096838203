import React from 'react';
import { Avatar } from 'primereact/avatar';
import VerificationBanner from "../components/VerificationBanner"
import UploadDocuments from "./UploadDocuments";
import { Toolbar } from 'primereact/toolbar';
import Auth from '../../src/utils/auth';
import { UploadDocumentsLocaliseString } from "./UploadDocuments/UploadDocuments.local";
import { Localization } from "utils";

export default function Company() {
  const strings = new Localization(UploadDocumentsLocaliseString).strings;
  let _userInfo = localStorage.getItem( "userInfo" );
  if ( _userInfo ) {
    _userInfo = JSON.parse( _userInfo ) || {};
  } else {
    _userInfo = {};
  }
  console.log( "_userInfo Login", _userInfo )
  const _company = _userInfo?.companyId;
  console.log( "_companyInfo", _company );

  function onPressLogOut() {
    Auth.logout();
    localStorage.setItem("userInfo", JSON.stringify({}));
  }
  const endContent = (
    <React.Fragment>
      <div className="flex align-items-center gap-2">
        {/* <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" /> */}
        <div className="font-bold text-bluegray-50"  style={ { cursor: "pointer" } } onClick={() => onPressLogOut()}>{strings.log_out}</div>
      </div>
    </React.Fragment>
  );
  return (
    <>
      <div className="card">
        <Toolbar end={ endContent } style={ { backgroundColor: 'white' } } />
      </div>
      <VerificationBanner companyType={ _company?.type } />
      <UploadDocuments />
    </>

  )
}