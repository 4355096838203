import "./PrivacyPolicy.css";
import { privacyStrings } from "./PrivacyPolicy.local";
import { Localization } from "../utils/localization";

export default function PrivacyPolicy() {
  const strings = new Localization(privacyStrings).strings;

  return (
    <div
      className="PrivacyPolicy"
      style={{ alignItems: "center", textAlign: "justify" }}
    >
      {/* <h1>{strings.privacyH1}</h1> */}
      <div style={{ color: "white", width: "50%" }}>
        <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
        <h2 style={{ textAlign: "center" }}>Last updated Feb 12, 2024</h2>
        <br />
        <p style={{ color: "white" }}>
          <strong>Introduction</strong> At Haulr, we are committed to protecting
          the privacy and security of our users. This privacy statement outlines
          our practices concerning the collection, use, and sharing of your
          personal information through the Haulr app.
        </p>
        <br />
        <p>
          <strong>Information Collection and Use</strong>
        </p>
        <p>
          <strong>A. Personal Information</strong>: We collect personal
          information that you provide when using the Haulr app. This may
          include your name, email address, phone number, and payment
          information.
        </p>
        <br />
        <p>
          <strong>B. Usage Data</strong>: We collect information on how the
          Haulr app is accessed and used, including data about your app
          interactions, device type, operating system, and usage times.
        </p>
        <br />
        <p>
          <strong>C. Location Data</strong>: For effective service delivery, we
          may collect location data if you permit the app to access this
          information.
        </p>
        <br />
        <p>
          <strong>Data Sharing and Disclosure</strong>
        </p>
        <p>
          <strong>A. Service Providers</strong>: We may share your data with
          third-party service providers to facilitate our service, provide the
          service on our behalf, or assist us in analyzing how our service is
          used.
        </p>
        <br />
        <p>
          <strong>B. Legal Requirements</strong>: Haulr may disclose your
          Personal Data in the belief that such action is necessary to comply
          with a legal obligation, protect and defend our rights or property,
          prevent or investigate possible wrongdoing in connection with the
          service, or protect the personal safety of users of the service or the
          public.
        </p>
        <br />
        <p>
          <strong>Data Retention</strong>: Haulr will retain your Personal Data
          only for as long as is necessary for the purposes set out in this
          Privacy Policy.
        </p>
        <br />
        <p>
          <strong>Your Data Protection Rights</strong>: You have certain data
          protection rights. Haulr aims to take reasonable steps to allow you to
          correct, amend, delete, or limit the use of your Personal Data.
        </p>
        <br />
        <p>
          <strong>Changes to This Privacy Statement</strong>: We may update our
          Privacy Statement from time to time. We will notify you of any changes
          by posting the new Privacy Statement on this page.
        </p>
        <br />
        <p>
          <strong>Contact Us</strong>: If you have any questions about this
          Privacy Statement, please contact us at {" "}<a href="tel:+1(720)969-9307">+1 (720) 969-9307</a>.
        </p>
      </div>
    </div>
  );
}
