import {useState} from 'react';
import ModalMaterialUI from './ModalMaterialUI';
import RoleModal from './RoleModal';

export default function AddRoleBtn({company,role}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const displayText =  role ? 'edit' : 'new';

  if(!company && role?.companyId?.name){
    company = role.companyId;
  }

  return (
    <div>
      <button onClick={handleOpen} className="oBtn">{displayText} Role</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        <RoleModal company={company} setOpen={handleClose}/>
      </ModalMaterialUI>
    </div>
  )
}
