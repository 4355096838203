import React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate} from 'react-router-dom';
import { Localization } from '../utils/localization';

export default function UserDropdown({items,localStrings}) {
    const local = new Localization(localStrings).strings;
    const navigate = useNavigate();
    // const strings = new Localization(navbarStrings).strings;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const navClassName = 'flex items-center transition-colors hover:bg-[var(--lightGray)] hover:text-black'

    const handleClose = () => {
      setAnchorEl(null);
    };


    function getMenu(){
      let tto = [];
      items?.forEach(ele=>
        {
          if(local[ele]){
            tto.push(ele );
          }
        }
        )         
      return tto.map(ele=><div className={navClassName}>
          <MenuItem onClick={()=>{  navigate(`/dashboard?stage=${ele}`)}} >
  
              <h1 className="text-white" key={ele}> {local[ele]} </h1>
  
          </MenuItem>
        </div>)
      
    }


    // console.log(items);
    return (
        <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {/* have this grab first letter of username */}
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            color: 'white',
            backgroundColor: 'rgb(47, 45, 45)',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mt: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'rgb(47, 45, 45)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        >
            {getMenu()}

        </Menu>
      </React.Fragment>
    )
    }