import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function ModalMaterialUI({children,open,onClose}) {
  return (
      <Modal open={open} onClose={onClose}>
        <Box className="modalStyle top-[3rem] md:top-1/4 md:left-1/3 ">
          {children}
        </Box>
      </Modal>
  )
}
