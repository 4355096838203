import React from 'react'
import Auth from 'utils/auth';
import Stack from 'components/Stack';
import dayjs from 'dayjs';
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';
import { useTicketsByCompId } from 'hooks/useTicketsByCompId';
import { useTicketsAsDriver } from "hooks/useTicketsAsDriver";

export default function OpenCompanyJobs() {
  const {data} = useTicketsByCompId(Auth.getUserData().companyId)
  const [stacks] = useTicketsGroupBy(data);
  const [availStacks,setAvailStacks] = React.useState([]);
  
  const {data:data2} = useTicketsAsDriver(Auth.getUserData()._id);
  
  React.useEffect( ()=>{
    let freeStacks = [];
    if(data2 && stacks){
      // This big 3xO notation shit here is seeing if any 'card' on the stack has is the same day as a ticket date.
      // stacks.forEach -> days.find -> stack.find == card = day;
      // If it free, so not found, then it gets added to the availableStacks/jobs
      const days = data2.map( ticket => ticket.startDate);
      stacks.forEach( (stack)=>{
       const found = stack.find(card=> days.find(day=> dayjs(day).isSame(dayjs(card.startDate),'day') ))
        if(!found){
          freeStacks.push(stack);
        }
      })
      setAvailStacks(freeStacks);
    }
  },[data2,stacks])

  return (
    <div className='bg-blue-300 p-2 m-2'>
      <h1>Driving job company vacancies:</h1>
      <h2>This is for drivers that are looking for unassigned tickets in their company </h2>
      {
        availStacks.map( (stack,index) => stack[0].driverId ?<div key={index}> </div>: <div key={index}> <Stack stackData={stack} key={index}/> </div>)
      }
    </div>
  )
}
