import {useQuery, useQueryClient} from '@tanstack/react-query'
import goFetch from '../utils/goFetch'


export const useJobByJobId = (id) => {
  const queryClient = useQueryClient()
  return  useQuery({ 
    queryKey: [`/jobs/byId/${id}`], 
    queryFn: async()=>{
      if(!id) return {};

      const rpnse = await goFetch(`/jobs/byId/${id}`,{
            headers: { "Content-Type": "application/json" },
      });
      const rpn = await rpnse.json();
      return rpn;
      },
    refetchInterval: 10000,
    initialData: ()=>{
        return queryClient.getQueryData(['activeJobs'])?.find((d)=> d._id === id)
    }
  })  
}