import React from 'react'

export default function SubmitTime() {
  return (
    <>
    <label className="flex items-center  flex-col md:flex-row">
      <h1 className='text-white text-center'>
      What time is the start time?
      </h1>
      <input type="time" name="dailyStartTime"className='textInput' defaultValue={"07:00"}/>
    </label>
    <label className="flex items-center  flex-col md:flex-row">
      <h1 className='text-white text-center'>
      Estimated Hours per day
      </h1>
      <input type="number" name="estimatedWorkHours" className='textInput' defaultValue="8" min="4"/>
    </label>
    </>
  )
}
