import { createColumnHelper } from "@tanstack/react-table";
import { Button } from "primereact/button";
import './table.css';
import { DriverLocaliseString } from "./Driver.local";
import { Localization } from "utils";

export const columns = (openDialog) => {
  const strings = new Localization(DriverLocaliseString).strings;
  const columnHelper = createColumnHelper();
  const userColumns = [
    // columnHelper.accessor("_id", {
    //   id: "_id",
    //   header: "id",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    columnHelper.accessor("firstName", {
      id: "firstName",
      header: strings.first_name_detail,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    columnHelper.accessor("lastName", {
      id: "lastName",
      header: strings.last_name_detail,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: strings.email_detail,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    columnHelper.accessor("phoneNr", {
      id: "phoneNr",
      header: strings.phone_number_detail,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    columnHelper.accessor("driverStatus", {
      id: "driverStatus",
      header: strings.status_table_heading,
      cell: (props) => <span>{strings[props.getValue()]}</span>
    }),
    // columnHelper.accessor("email", {
    //   id: "email",
    //   header: "Email",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    // columnHelper.accessor("gender", {
    //   id: "gender",
    //   header: "Gender",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    columnHelper.accessor("view", {
      id: "View",
      header: strings.view_option,
      cell: (props) => (
        <div>
          <Button
            onClick={() => {
              openDialog(props.row.original, true);
            }}
            // style={{ margin: "1px", padding: "10px" }}
            label={strings.view_option}
          />
        </div>
      )
    })
  ];
  return { userColumns };
};
