import { useSearchParams } from 'react-router-dom';
import { useProjectById } from 'hooks/useProjectById';
import { useJobsByProjectId } from 'hooks/useJobsByProjectId';
import ParseDate from 'components/wrappers/ParseDate';
import LoadingIcon from "components/stateIcons/LoadingIcon"
import { useNavigate } from 'react-router-dom'
import TableBtnDetail from "components/TableBtnDetail"
export default function ProjectDetail() {
  const [searchParams] = useSearchParams(); 
  const navigate = useNavigate();
  const projectId = searchParams.get('data')
  const {data:project,isLoading,isError,error} = useProjectById(projectId);
  const jobs = useJobsByProjectId(projectId);
  if (isLoading || jobs.isLoading) {
    return  <LoadingIcon />
  }

  if (isError || jobs.isError ) {
    return <span>Error: {error.message}</span>
  }

  function newJob(evt){
    navigate(`/dashboard?stage=SubmitJob&data=${projectId}`);
  };
  function addLocation(evt){

  }
  console.log(jobs.data);
  return (
    <div>
      <h1 className='stageTitle'> Project Details </h1>
      <hr/>
      <h2> name: {project?.name} </h2>
      <h2> state: {project?.state} </h2>
      <h3> <ParseDate>{project?.scheduledEnd}</ParseDate> </h3>
      <h3> <ParseDate>{project?.scheduledStart}</ParseDate> </h3>
      {project?.truckCatorgies.map(cat=>cat+", ")}
      <hr/>
      <h1> Jobs </h1>
      <button className='oBtn' onClick={newJob}>Add Job</button>
      <TableBtnDetail column= {['name','scheduledStart','scheduledEnd','dateCreated']} dataArray={jobs.data} clickNav="JobDetail" />
      <hr/>
      <h1> Locations </h1>
      <button className='oBtn' onClick={addLocation}>Add Location</button>
      </div>
  )
}
