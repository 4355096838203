import { userAgreeStrings } from "./UserAgreement.local";
import { Localization } from '../utils/localization';

export default function UserAgreement() {
    const strings = new Localization(userAgreeStrings).strings;

    return(
        <div className="UserAgreement">
            <h1>{strings.userAgreeH1}</h1>
            <div className="userAgreeInfo">
                <span>{strings.userAgreeInfo}</span>
            </div>
        </div>
    )

}