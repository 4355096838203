import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useTicketById = (id) => {
  return  useQuery({ queryKey: [`/tickets/byId/${id}`], queryFn: async()=>{
    if(!id) return {};
    const rpnse = await goFetch(`/tickets/byId/${id}`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json();
    return rpn;
  }})  
}