import { useState,useEffect } from 'react'
import { useUserData } from "hooks/useUserData"
import LoadingIcon from "components/stateIcons/LoadingIcon"
import TextField from '@mui/material/TextField';
import goFetch from 'utils/goFetch';

export default function VerifyAccount(props) {
  const [code, setCode] = useState()
  const {data,isLoading} = useUserData();

  function sendEmail(){
    if(!localStorage.getItem('sentEmail')){
      goFetch('/users/sendVerifyEmail')
      localStorage.setItem("sentEmail",true)
    }
  }

  function resend(){
    goFetch('/users/sendVerifyEmail')
  }
  useEffect(() => {
    sendEmail();
  }, [])


  async function submit(evt){
    evt.preventDefault();

    let rpns = await goFetch(`/users/verifyCode/${code}`);
    await rpns.json();
    props.doSubmit();
  }

  if (isLoading) {
    return  <LoadingIcon />
  }


  return (
    <div className='flex flex-col w-full bg-[var(--medGray)] text-white w-9/12 p-6 border-2 border-[var(--yellow)]'>
      <h1> Verify Account </h1>
      <h2> We are sending an email to: {data.email}</h2>
      <h2> Please input the six digit number below </h2>
      <br/>
      <form onSubmit ={submit}>
        <TextField id="outlined-basic" label="Six Digit Code" variant="outlined" value={code}
        onChange={ e => setCode(e.target.value)  }/>
      </form>
      <div>
        <button className="oBtn" onClick={submit} > Enter </button>
        <button className="oBtn" onClick={resend} > Resend Code </button>
      </div>
    </div>
  )
}
