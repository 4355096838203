import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useCreateProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (newProject)=>{
      const rspnse = await goFetch('/projects/create', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newProject)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      if(!data.error){
        // queryClient.invalidateQueries({ queryKey: ['activeJobs'] })
      }
    },
  })
}