/*
What is this shit?
Well it's an error that can be wrapped or passsed useMutation value and will display an absolute posistion error.

*/
export default function Error(props) {
	return (
    <>
	    { 
        props?.useMutation?.data?.error?.message ?
            <div className= { `${props.className} errorText`}> {props.useMutation?.data?.error?.message}</div>
            :
            props.children?.data?.error?.message ?
                <div className= { `${props.className} errorText`}> {props.children?.data?.error?.message}</div>
                :
                <></>
       }
    </>
	);
}
