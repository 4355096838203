import { useState } from "react";
import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import MutateStatus from 'components/MutateStatus';

export default function FileUpload(props) {
	const {documentType,targetId} = props;
	const queryClient = useQueryClient()
  const submitDoc = useMutation({
    mutationFn: async (document)=>{
      const rspnse = await goFetch('/users/submitDocument/', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(document)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [`/users/document/${documentType}/${targetId}`] })
    },

  });  

	const [file, setFile] = useState();

	function handleChange(event) {
		setFile(event.target.files[0]);
	}

	function convertToBase64(file) {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	}


	async function submitFile(event) {
		event.preventDefault();
		const compressedBase64 = await convertToBase64(file);
		submitDoc.mutate({ compressedBase64, documentType, fileName: file.name ,targetId });
	}

	return (
		<div>
			<Error className="top-1 left-1" useMutation={submitDoc}/>
			<form>
				<input type="file" name="image" onChange={handleChange} />
				<button onClick={submitFile}> Upload </button>
			</form>
			<MutateStatus useMutation={submitDoc} />
		</div>
	);
}
