/*
You can grab files from server/assets that is being served by express.js in app.js
These files are never stale, because they are const.
*/

import goFetch from '../utils/goFetch'
import {useQuery} from '@tanstack/react-query'
import {useAxios} from '../hooks/useAxios';

export const useServerAssets = (url) => {
  const axios = useAxios();
  return  useQuery({
    queryKey: [`${url}.json`],
    queryFn: async()=>{
      if(!url) return {};
      // const rpnse = await goFetch(`${url}.json`,{
      //         headers: { "Content-Type": "application/json" },
      //         mode: "no-cors",
      //       });
      // const rpn = await rpnse.json();
      // console.log("rpn", rpn)
      // return rpn;

      const _response = await axios.get(`${url}.json`);
      console.log("_response", _response);
      return _response.data;
    },
    staleTime: 1000 * 60 * 60 * 24 * 365
})
}