export const contactStrings = {
    en:{
        questions: "QUESTIONS?",
        submittingForm: "Submitting Form...",
        name: "Name",
        emailAddress: "Email Address",
        message: "Message",
        submit: "SUBMIT",
        messagePlacehold: "How can we help you? (max 500 characters)"
    },
    es:{
        questions: "¿PREGUNTAS?", 
        submittingForm: "Envío de formulario...", 
        name: "Nombre", 
        emailAddress: "Dirección de correo electrónico", 
        message: "Mensaje", 
        submit: "ENVIAR",
        messagePlacehold: "¿Cómo podemos ayudarte? (máximo 500 caracteres)"
    }
}