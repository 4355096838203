export const tableStrings = {
  
  en:{
    Details:"Details",
    roleName:"role",
    startDate:"start date",
    numberOfTrucksNeeded:"# trucks",
    name:"Name",
    make:"make",
    model:"model",
    ticketNumber:"ticket#",
    clockedIn:"clockedIn",
    clockIn:"clocked In",
    clockOut:"clocked out",
    licensePlate:"licensePlate",
    permissions:"permissions",
    role:"role",
    companyId:'Company',
    constructionId:"construction",
    hourlyRate:"Hourly Rate",
    firstName:"First Name",
    lastName:"Last Name",
    phoneNr:"Phone #",
    owner:"owner",
    type:"type",
    createdBy:"Requested By",
    workDate:'workDate',
    scheduledStart:"Scheduled Start",
    scheduledEnd:"Scheduled End",
    truckTypes:"Truck Types",
    truckCategories:"Truck Categories",
    username:"User",
    details:"Details",
    category:"category",
    insurancePolicy:"insurancePolicy",
    status:"status",
    cDotId:"cDotId",
    notes:"Notes",
    pickupTime:"Pickup Time",
    email:"email",
    trucksPerInterval:"trucks per interval",
    dateCreated:"Date Created"
  },
  es:{
    Details:"Detalles",
    truckTypes:"Tipo de camións",
    name:"Nombre",
    username:"Usuario",
    email:"email",
    createdBy:"Creado por",
    details:"Detalles",
    notes:"Notas",
    scheduledStart:"Inicio programado",
    scheduledEnd:"Fin programado",
    trucksPerInterval:"Número de camiones necesarios",
    dateCreated:"Fecha de creacion"
  }
};