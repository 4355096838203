import { useSearchParams } from 'react-router-dom';
import { useTicketById } from 'hooks/useTicketById';
import { useJobByJobId } from "hooks/useJobByJobId"
import {useMutation} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import ParseDate from 'components/wrappers/ParseDate';
import dayjs from 'dayjs';
import { useCompanyById } from "hooks/useCompanyById"
import logo from "../../../assets/HaulrLogo.png"
import { useTicketToday } from "hooks/useTicketToday";
import UserPermission from 'components/wrappers/UserPermission';
import Auth from 'utils/auth';
export default function TruckTicket() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const ticketId = searchParams.get('data')
    let assign =      useTicketById(ticketId); // these need to be the same populate.
  const assignToday = useTicketToday(Auth.getUserData()._id) // these need to be the same populate.
    if(!ticketId){
      assign = assignToday;
    }

  const emailTicket = useMutation({
    mutationFn: async ()=>{
      const rspnse = await goFetch(`/tickets/emailTruckTickets/${ticketId}`, {
        headers: { "Content-Type": "application/json" }
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
  })
  const job = useJobByJobId(assign.data?.jobId?._id?? assign.data?.jobId )
  const construction = useCompanyById(job.data?.constructionId?._id ?? job.data?.constructionId)
  const truckCompany = assign?.data?.companyId;
  const constructionCompany = construction.data;
  const driver = assign?.data?.driverId;
  const truck = assign?.data?.truckId;

  function locationToString(location,index){
    if(location && location[index]){
      if(location[index].address.postalAddress){
        return `${location[index].address.postalAddress} ${location[index].address.city}, ${location[index].address.state} ${location[index].address.zipCode} `
      } else {
        return `${location[index].address.latitude} ${location[index].address.city}, ${location[index].address.longitude}} `
      }
    }
  }

  function sendEmails(evt){
    emailTicket.mutate(ticketId)
    alert('Sending emails');
  }

  function getWorkTime(){
    return dayjs(assign?.data?.clockOut).diff( assign?.data?.clockIn)/1000/60/60
  }
  
  return (
    <>
    <div className='text-slate-200'>
      <h1 className='stageTitle'>Truck Ticket</h1>
      <UserPermission perm='ceo'>
        <button className='oBtn' onClick={sendEmails}>Send Email</button>
      </UserPermission>
      <div className='border-2 border-orange-500 p-4 bg-slate-100 text-black md:min-w-[50rem]'>
        <div className='h-[10rem] flex'>
          <div className=" w-1/3">
            <div className='h-1/3  border border-slate-800'>
              <h2 className='relative text-sm'> Ticket #:</h2>
              <h2 className="p-1 text-sm"> {assign?.data?.ticketNumber} </h2>
            </div>
            <div className='h-1/3'>
            </div>
            <div className=' border border-slate-800 h-1/3' >
              <h2 className='relative text-sm'> Truck No:</h2>
              <h2 className="p-1 text-sm"> {truck?.licensePlate} </h2>
            </div>

          </div>
          <div className='w-1/3 flex items-center justify-center flex-col'>
            <img src={logo} className='h-[8rem]' alt="Truck Logo with Haulr on bottom" />
            <h1 className='text-base md:text-4xl'> HAULRCORP</h1>
          </div>
          
          <div  className=" w-1/3">
            <div className='h-1/2 border border-slate-800'>
              <h2 className='relative text-sm'> phone: </h2>
              <h2 className="p-1 text-sm md:text-md"> 720-930-6825</h2>
            </div>
            <div className='h-1/2 border border-slate-800' >
              <h2 className='relative text-sm'>Date</h2>
              <h2 className="p-1 text-sm md:text-md"> <ParseDate>{assign?.data?.startDate}</ParseDate> </h2>
            </div>
          </div>
        </div>
        <br/>
        <div className=' h-[8rem] flex'> 
          <div className=' w-2/3'> 
            <div className='border border-slate-800 h-[4rem]'>
              <h2 className='relative text-sm'>Job / Project name:</h2>
              <h2 className="p-1 text-sm md:text-md"> {job?.data?.name} </h2>
            </div>        

            <div className='border border-slate-800 h-[4rem]'>
              <h2 className='relative text-sm'>Bill To:</h2>
              <h2 className="p-1 text-sm md:text-md"> {constructionCompany?.name} </h2>
              
            </div>

          </div>
          <div className='border border-slate-800  w-1/3 '> 
              <h2 className='relative text-sm'>Truck Company Name:</h2>
              <h2 className="p-1 text-sm md:text-md"> {truckCompany?.name} </h2>
          </div>
        </div>

        <br/>
        <div className=' h-[14rem] flex'>
          <div className=' w-2/3'>
            <h2 className='relative text-sm  bg-slate-800 text-white'> LOCATION AND DESCRIPTION </h2>
            <div className='h-[68px] border border-slate-800'>

              <h2 className='relative text-sm'>Load At:</h2>
              <h2 className="p-1 text-sm"> {locationToString(job?.data?.locations,0)}</h2>

            </div>
            <div className='h-[68px] border border-slate-800'>
              <h2 className='relative text-sm'> Unload At:</h2>
              <h2 className="p-1 text-sm wrap"> {locationToString(job?.data?.locations,1)} </h2>

            </div>
            <div className='h-[68px] border border-slate-800'>
              <h2 className='relative text-sm'>Material Hauled: </h2>
              <h2 className="p-1 text-sm md:text-md"> {job?.data?.materialHauled} </h2>

            </div>

          </div>
          <div  className='w-1/3 flex flex-wrap'>
            <div className='h-1/2 w-1/2 border border-slate-800'>
              <h2 className='relative text-sm bg-slate-800 text-white'>START TIME: </h2>
              <h2 className="p-1 text-sm md:text-md"> <ParseDate>{assign?.data?.clockIn}</ParseDate> </h2>

            </div>
            <div className='h-1/2 w-1/2 border border-slate-800'>
              <h2 className='relative text-sm  bg-slate-800 text-white'>STOP TIME </h2>
              <h2 className="p-1 text-sm md:text-md"> <ParseDate>{assign?.data?.clockOut}</ParseDate> </h2>

            </div>
            <div className='h-1/2 w-1/2 border border-slate-800'>
              <h2 className='relative text-sm  bg-slate-800 text-white'> TRAVEL TIME </h2>
              <h2 className="p-1 text-sm md:text-md"> {assign?.data?.travelTimeMinutes} </h2>

            </div>
            <div className='h-1/2 w-1/2 border border-slate-800'>
              <h2 className='relative text-sm  bg-slate-800 text-white'>TOTAL HOURS </h2>
              <h2 className="p-1 text-sm md:text-md"> {getWorkTime().toFixed(2)} Hours </h2>

            </div>

          </div>
        </div>
        <div className='border border-slate-800 h-[20rem] flex flex-col'>
          <h2 className='relative text-sm  bg-slate-800 text-white'> LOAD TIMES, UNLOAD TIMES </h2>
          {assign?.data?.driverEvents?.map(event=><div className='m-2'>
            {event.description}@<ParseDate>{event.timeStamp}</ParseDate>
          </div>)
          }

        </div>
        <div className=' h-[12rem] flex'>
          <div className='w-2/3'>
            <div className='border border-slate-800 h-1/2'>

            </div>
            <div className='border border-slate-800 h-1/2'>
              <h2 className='relative text-sm'>  Operator:</h2>
              <h2 className="p-1 text-sm"> {driver?.firstName} {driver?.lastName} </h2>
              
            </div>

          </div>
          <div className='border border-slate-800 w-1/3'>
              <h2 className='relative text-sm'> Type of truck: </h2>
              <h2 className="p-1 text-sm md:text-md"> {truck?.category} </h2>

          </div>
        </div>
      </div>
    </div>
    </>
  )
}
