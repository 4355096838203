import { useSearchParams } from 'react-router-dom';
import { useTruckById } from 'hooks/useTruckById';
import {useQuery} from '@tanstack/react-query'
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';
import goFetch from 'utils/goFetch'
import Stack from 'components/Stack';
import LoadingIcon from "components/stateIcons/LoadingIcon"
import TruckBtn from 'components/modals/TruckBtn';
import UserPermission from 'components/wrappers/UserPermission';
export default function TruckDetail() {
  const [searchParams] = useSearchParams(); 
  const truckId = searchParams.get('data')
  const {data:truck,isLoading,isError,error} = useTruckById(truckId);
  const assignsQ = useQuery({ queryKey: [`/tickets/byTruck/${truckId}`], queryFn: async()=>{
    const rpnse = await goFetch(`/tickets/byTruck/${truckId}`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json();
    return rpn;
  }})  
  const [stacks] = useTicketsGroupBy(assignsQ.data);
  if (isLoading || assignsQ.isLoading) {
    return  <LoadingIcon />
  }

  if (isError || assignsQ.isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div>
      <h1 className='stageTitle'> {truck.category} </h1>
      <UserPermission perm="organize">
       <TruckBtn truck={truck} company={truck.companyId}/>
      </UserPermission>
      

      <div className="text-slate-300">
        <h3> {truck.model}  {truck.make} @ {truck.companyId.name} </h3>
        <h3> licensePlate: {truck.licensePlate} </h3>
        <h3> cDotId: {truck.cDotId} </h3>

      </div>
      <h2  className="text-slate-300"> Tickets </h2>
      {
       stacks.map( stack => <Stack stackData={stack}/>)
      }

      </div>
  )
}
