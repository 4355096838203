import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useTicketToday = (driverId) => {
  return  useQuery({ queryKey: [`${driverId}todayAssignment`], queryFn: async()=>{
    if(driverId){
      const driverTime = new Date();
      const rpnse = await goFetch(`/tickets/myTicketToday/${driverTime}`,{
        headers: { "Content-Type": "application/json" },
      });
      const rpn = await rpnse.json();
      return rpn;
    }
  }})  
}