import Chip from './Chip'

export default function TicketTableChips(props){
    const chipTitles=props?.chipTitles
    const selectedCompany = props?.selectedCompany
    const updateSelectedCompany = props?.onSelect
    const showModal=props?.showModal;
    function addOrg(){
        showModal()
    }
    return <div className='chipBar' style={{display:"flex",flexDirection:"row"}}>
        {chipTitles.map((title,index)=>{
                return <Chip key={index} onClick={()=>updateSelectedCompany(index)} title={title} selected={index === selectedCompany}/>
        })}
        <Chip onClick={addOrg} title={"+"}/>
    </div>
}