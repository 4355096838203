import {useRef,useState} from 'react'
import ModalMaterialUI from './ModalMaterialUI';
import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import MutateStatus from 'components/MutateStatus';
import EditIcon from '@mui/icons-material/Edit';

export default function ChangeCompAddressBtn({orgId}) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient()
  const handleClose = () => setOpen(false);
  const formRef = useRef()
  const changeRate = useMutation({
    mutationFn: async (infobody)=>{
      const rspnse = await goFetch(`/companies/mutate/${orgId}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infobody)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    // Always refetch after error or success:
    onSettled: () => {
      setOpen(false)
      queryClient.invalidateQueries({ queryKey: [`/companies/byId/${orgId}`] })
    },
  })
  async function handleSubmit(evt){
    evt.preventDefault();
    let strng = formRef.current.elements.address.value.replace(/\s/g, '%20') ;
    const rspnt = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${strng}%2C%20${formRef.current.elements.city.value}%2C%20${formRef.current.elements.state.value}%20${formRef.current.elements.zip.value}.json?types=address&access_token=pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg`)
    const rpn = await rspnt.json();

    const address = {
      postalAddress: formRef.current.elements.address.value,
      city: formRef.current.elements.city.value,
      state: formRef.current.elements.state.value ,
      zipCode: formRef.current.elements.zip.value,
      longitude: rpn?.features[0]?.center[0],
      latitude: rpn?.features[0]?.center[1]
    }
    changeRate.mutate( {address} )
  }

  return (
    <>
      <button onClick={() => setOpen(true)} className="oBtn"><EditIcon/></button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        <Error className="top-1 left-1" useMutation={changeRate}/>
            <h1>Change Address</h1>
            <form onSubmit={handleSubmit} ref={formRef}>
            <label className='flex justify-between'>
              <h3>Street Address:&nbsp; </h3>
              <input placeholder='1234 Place St.' type="address" name="address" />
            </label>
            <label className='flex justify-between'>
              <h3>City:&nbsp;</h3>
              <input placeholder='City' type="city"  name="city"  />
            </label>
            <label className='flex justify-between'>
              <h3>State:&nbsp;</h3>
              <input placeholder='State' type="state" name="state"/>
            </label>
            <label className='flex justify-between'>
              <h3>Zipcode:&nbsp;</h3>
              <input placeholder='12345' type="zip" name="zip"/>
            </label>
            <div>
              <button onClick={handleClose} className="oBtn">Close</button>
              <button onClick={handleSubmit} className="oBtn">Update</button>
            </div>
            </form>
        <MutateStatus useMutation={changeRate} />
      </ModalMaterialUI>   
    </>
  )
}
