import "./Login.css";
import Auth from "../utils/auth";
import goFetch from "../utils/goFetch";
import { useState, useRef, useEffect } from "react";
import { loginStrings } from "./Login.local";
import { Localization } from "utils";
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Login = ( props ) => {
  const toast = useRef( null );
  const [ formState, setFormState ] = useState( { email: "", password: "" } );
  const [ error, setError ] = useState();
  const strings = new Localization( loginStrings ).strings;
  const forgotEmail = useRef()
  const [ forgot, setForgot ] = useState( false );
  const navigate = useNavigate();
  console.log("window.location.hostname", window.location.hostname);
  useEffect( () => {
    let _userInfo = localStorage.getItem( "userInfo" );
    if ( _userInfo ) {
      _userInfo = JSON.parse( _userInfo ) || {};
    } else {
      _userInfo = {};
    }
    console.log("_userInfo Login", _userInfo)
    if ( Object.keys( _userInfo ).length > 0 ) {
      const _company = _userInfo?.companyId;
      if ( _userInfo?.role == 'driver' ) {
        // open driver
      }
      else if ( _company?.type == 'construction' ) {

      } else if ( _company?.type == 'trucking' ) {
        navigate( '/company' );
      } else if ( _company?.role == 'driver' ) {

      } else {
        navigate( "/drivers" )
      }
    }
  }, [] );

  const handleChange = ( event ) => {
    const { name, value } = event.target;
    setError( null );
    setFormState( {
      ...formState,
      [ name ]: value,
    } );
  };

  const handleFormSubmit = async ( event ) => {
    event.preventDefault();
    try {
      const rspnse = await goFetch( '/users/login/', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( formState )
      } );
      const rpn = await rspnse.json();
      console.log("rpn", rpn)
      if ( rpn.error ) {
        throw rpn.error
      }
      if ( rpn.token ) {
        Auth.login( rpn.token );
        props.setLogged( true );

        let _user = rpn.user;
        const _company = _user?.companyId;
        // await goFetch( `/users/setUserLanguage1/${_user._id}` ); //goFetch gets localStorage.language and adds it to the header
        // Commented - Because When User LogOut and Call set language API, it's breaking
        if ( rpn.user && !rpn.user.language ) {
          _user.language = localStorage.getItem("language");
          await goFetch( `/users/setUserLanguage1/${_user._id}` ); //goFetch gets localStorage.language and adds it to the header
        } else if ( rpn.user?.language ) {
          localStorage.setItem( "language", rpn.user.language );
        }

        localStorage.setItem( "userInfo", JSON.stringify( _user ) );
        if ( _user?.role == 'driver' ) {
          // open driver
        }
        else if ( _company?.type == 'construction' ) {
          navigate( '/company' );
        } else if ( _company?.type == 'trucking' ) {
          navigate( '/company' );
        } else if ( _company?.role == 'driver' ) {

        } else {
          navigate( "/drivers" )
        }
      }

    } catch ( e ) {
      setError( e );
    }

    // clear form values
    setFormState( {
      password: "",
      email: ""
    } );
  };

  async function sendForgot( evt ) {
    const email = forgotEmail.current.value.toLowerCase();
    const rspnse = await goFetch( '/users/sendPswdEmail/', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify( { email } )
    } );

    const rpn = await rspnse.json();
    if ( rpn.success ) {
      forgotEmail.current.value = "";
      // toast.current.show({severity:'secondary', summary: 'Secondary', detail:rpn.success.message, life: 3000});
      alert( rpn.success.message )

    }
    return rpn;
  }

  useEffect( () => {
    if ( Auth.loggedIn() ) {
      // navigate("/userhome")
      navigate( "/drivers" )
    }
  }, [ navigate ] )


  return (
    <div className="loginCard">
      <h1 className="loginCardTitle">{ strings.userLogin }</h1>
      <Toast ref={ toast } />
      <form onSubmit={ handleFormSubmit } className="loginFormContainer">
        <input
          className="loginFormInput"
          placeholder={ strings.usernamePlacehold }
          name="email"
          type="username"
          value={ formState.email }
          onChange={ handleChange }
          style={ error ? { backgroundColor: "red" } : null }
        />
        <input
          className="loginFormInput"
          placeholder="******"
          name="password"
          type="password"
          value={ formState.password }
          onChange={ handleChange }
          style={ error ? { backgroundColor: "red" } : null }
        />
        <button
          onClick={ handleFormSubmit }
          className="oBtn" id="loginSubmitBtn"
          style={ { cursor: "pointer" } }
          type="submit"
        >
          { strings.login }
        </button>
        {/* <button className="createNewAccountBtn"
               onClick={toggleExistingUser}
               style={{ cursor: "pointer" }}
               >
                Create Account
              </button> */}
        <small className="forgotPassword cursor-pointer" onClick={ () => setForgot( !forgot ) }>{ strings.forgotPassword }</small>
      </form>
      { error && (
        <div className="text-red-400 ">{ error.message }</div>
      ) }

      {
        forgot ?
          <div>
            <h1> Please enter email below </h1>
            <input
              className="loginFormInput h-8"
              name="email"
              type="email"
              placeholder="johndoe@email.com"
              ref={ forgotEmail }
              style={ error ? { backgroundColor: "red" } : null }
            />
            <button onClick={ sendForgot } className="oBtn">Confirm</button>
          </div>
          :
          <></>
      }

    </div>
  );
};

export default Login;
