import { useState} from 'react'
import TicketTableChips from './TicketTableChips'
import TicketTableContent from './TicketTableContent'

export default function AssignedDriversTable(props){
    const companyItems=props?.companyItems
    const currentCompanies = [...new Set(companyItems.map(item=>item[0]?.companyId?.name))];
    const [selectedCompanyIndex,setSelectedCompanyIndex] = useState(0);
    const showModal = props?.showModal;
    function updateSelectedCompany(toIndex){

      if(currentCompanies[toIndex]){
        setSelectedCompanyIndex(toIndex);
      }
    }
    return <>
      <h1>Assigned trucks:</h1>
      <TicketTableChips chipTitles={currentCompanies} selectedCompany={selectedCompanyIndex} onSelect={updateSelectedCompany} showModal={showModal}/>
      {companyItems && <TicketTableContent companyItems={companyItems} selectedCompany={currentCompanies[selectedCompanyIndex]}/>}
    </>
}