import ParseDate from "components/wrappers/ParseDate"
import { useNavigate } from 'react-router-dom'

export default function Stack({stackData}) {
  const navigate = useNavigate();
  const endData=stackData[stackData.length-1]
  const ticket = stackData[0];

  function goToDetails(){
    navigate(`/dashboard?stage=TicketDetail&data=${stackData[0].groupBy}`) 
  }

  return (
    <div className="p-2 bg-slate-200 m-2 h-fit cursor-pointer" onClick={goToDetails}> 


    <div className="m-1 p-1" key={ticket?.groupBy}>
      <h1 className=""> {ticket?.companyId.name} </h1>
      {ticket.rejected ? "REJECTED" :
      <>
      {ticket?.driverId?.username ? <h2>Driver: {ticket?.driverId?.username} </h2> : <h2>Driver: unassigned</h2>}
      {ticket?.truckId?.licensePlate && <h2>Truck: {ticket?.truckId?.make ?? ''} - {ticket?.truckId?.licensePlate ??'' }</h2>}
      <h2>${ticket?.hourlyRate} per hour</h2>
      </>
       }
      <h3> <ParseDate>{ticket?.startDate}</ParseDate> </h3>
      <h3>  <ParseDate>{endData?.endDate}</ParseDate> </h3>
    </div>

    </div >
  )
}
