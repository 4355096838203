import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Helmet , HelmetProvider } from 'react-helmet-async';
import Favicon from 'react-favicon';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <HelmetProvider>
      <Favicon src='../assets/HaulRFavicon.png' url='favicon.ico' ></Favicon>
      <Helmet>
        <title>HAUL'R</title>
      </Helmet>
      <App />
    </HelmetProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
