import { Localization } from '../utils/localization';
import './TableBtnDetail.css'
import { tableStrings } from './TableBtnDetail.local';
import ParseDate from "./wrappers/ParseDate"
import { useNavigate  } from 'react-router-dom';
import ModalMaterialUI from './modals/ModalMaterialUI';
import {useState,cloneElement} from 'react'
export default function TableBtnDetail({column,dataArray,clickNav,clickModal,setModalData,className}){
  const navigate = useNavigate();
  const local = new Localization(tableStrings).strings;
  const dateField = ['workDate','scheduledStart','scheduledEnd','dateCreated','pickupTime','startDate','clockIn','clockOut']
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  if(!Array.isArray(dataArray) ){
    return <div> ERR: data invalid. Check permissions on user acct </div>
  }
  const newArray = dataArray.map(data=>{
    if(data.createdBy && data.createdBy.username){
      data.createdBy = data.createdBy.username;
    }
    if(data.companyId && data.companyId.name){
      data.companyId = data.companyId.name;
    }
    if(data.constructionId && data.constructionId.name){
      data.constructionId = data.constructionId.name;
    }
    if(data.owner && data.owner.username){
      data.owner = data.owner.username;
    }
    return data;
  })


  function doClick(evt){
    if(clickNav){
      navigate(`/dashboard?stage=${clickNav}&data=${evt.target.getAttribute('data-nav')}`)
    }
    if(clickModal){
      setModalData(evt.target.getAttribute('data-colone'))
      setOpen(true)
    }
  }


  let ClonedClickModal;
  if(clickModal){
    ClonedClickModal = cloneElement(
      clickModal, 
      { setOpen:handleClose }
    );
  }
  return (
    <>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        {ClonedClickModal}
        <button className='oBtn' onClick={handleClose}>close</button>
      </ModalMaterialUI>
        <table className={className}>
          <thead>
            {
              column.map( key=><th key={key} className='p-2'>{local[key]}</th>)
            }
          </thead>
          <tbody>
            {
              newArray.map((data,index)=>
                <tr key={index} onClick={doClick}>
                  {
                    column.map( key =><td className='p-2' key={key} data-colone={data[column[0]]} data-cell={local[key]} data-nav={data._id} > {dateField.find(ele=>ele===key)  ? <ParseDate>{data[key]}</ParseDate> :  Array.isArray(data[key]) ?  data[key].toString()      : data[key]             }</td>)
                  }
                </tr>
              )
            }    
            </tbody>
        </table>
      </>
  )
}