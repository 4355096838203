import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useUserById = (userId) => {
  return useQuery({queryKey: [`/users/byId/${userId }`], queryFn: async()=>{
    if(!userId) return {};
    const rpnse = await goFetch(`/users/byId/${userId}`);
    const rpn = await rpnse.json();
    return rpn;
  }, 
})
}

