class QuickbooksService {

	getData() {
		let token = this.getToken();
    return token;
	}

  getToken() {
    return {
			access_token: localStorage.getItem('qb_access_token'),
			refresh_token:localStorage.getItem('qb_refresh_token'),
			id_token:localStorage.getItem('qb_id_token'),
		};
	}
	
	clearToken(){
		localStorage.removeItem('qb_access_token')
		localStorage.removeItem('qb_refresh_token')
		localStorage.removeItem('qb_id_token')
	}

	saveTokens(idToken) {
		// Saves user token to localStorage and reloads the application for logged in status to take effect
    for(let key in idToken){
      localStorage.setItem("qb_"+key, idToken[key]);
    }
	}

}

const QuickbooksToken = new QuickbooksService();
export default QuickbooksToken;
