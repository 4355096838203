export const loginStrings = {
    en:{
        userLogin: "USER LOGIN",
        usernamePlacehold: "username",
        login: "Login",
        forgotPassword: "Forgot Password?"
    },
    es:{
        userLogin: "INICIO DE SESIÓN DE USUARIO",
        usernamePlacehold: "nombre de usuario",
        login: "iniciar sesión",
        forgotPassword: "contraseña olvidado?"
    }
}