/*

CompanyJobs - shows tickets - or jobs that have tickets?

*/
// import TableBtnDetail from "components/TableBtnDetail";
// import LoadingIcon from "components/stateIcons/LoadingIcon"
// import { useJobsByCompId } from 'hooks/useJobsByCompId';
import Auth from "utils/auth";


export default function CompanyJobs() {
  const companyId = Auth.getUserData().companyId; //searchParams.get('data');
  console.log(companyId)
  // const assignsF = useJobsByCompId(companyId);
    
  // if (assignsF.isLoading) {
  //   return  <LoadingIcon />
  // }

  // if (assignsF.isError) {
  //   return <span>Error: {assignsF.error.message}</span>
  // }
  
  return (
    <div className="orgJobTable">
      <h1 className="stageTitle"> Jobs for company </h1>
      {/* stage wrap breaks table? */}
      {/* <TableBtnDetail column= {['name','companyId','createdBy','truckCategories','trucksPerInterval','status','dateCreated','scheduledStart','scheduledEnd']} dataArray={assignsF.data} clickNav="JobDetail" /> */}
    </div>
  )
}
