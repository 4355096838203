export default function SubmitName() {
  return (
    <label className="flex items-center  flex-col md:flex-row">
      <h1 className='text-white text-center'>
      What is the broker rate?
      </h1>
      <input type="text" name="brokerRate" defaultValue="95.00" className="textInput"/>
    </label>
  )
}
