import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WorkIcon from '@mui/icons-material/Work';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate} from 'react-router-dom';
import Auth from '../utils/auth';
import { navbarStrings } from './Navbar.local';
import { Localization } from '../utils/localization';
import { useUserData } from "hooks/useUserData"
export default function UserDropdown() {
    const {data} = useUserData();
    const navigate = useNavigate();
    const strings = new Localization(navbarStrings).strings;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const navClassName = 'flex items-center transition-colors hover:bg-[var(--lightGray)] hover:text-black'

    function openSettings(evt){
      setAnchorEl(null);
      navigate('/settings')
    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    function goToUserDetail(evt){
      navigate(`/dashboard?stage=UserDetail&data=${data._id}`);
    }

    function goToOrgDetail(evt){
      navigate(`/dashboard?stage=CompanyDetail&data=${data.companyId._id}`);
    }
    return (
        <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {/* have this grab first letter of username */}
            <Avatar sx={{ width: 32, height: 32 }}>{data?.username[0]}</Avatar>
          </IconButton>
        </Box>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            color: 'white',
            backgroundColor: 'rgb(47, 45, 45)',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mt: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'rgb(47, 45, 45)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        >
          <div className={navClassName}>
        <MenuItem onClick={goToUserDetail} >
            <Avatar /> 
            <div className='flex flex-col'>
              <span>{data?.firstName} {data?.lastName}</span>
              <span>@{data?.username}</span>
            </div>
        </MenuItem>
        </div>
        <hr className="mx-3"/>
        {
          data?.companyId?.name ? 
          <div className={navClassName}>
          <MenuItem onClick={goToOrgDetail}>
            <ListItemIcon sx={{color: 'white'}}>
              <WorkIcon fontSize="small" />
            </ListItemIcon>
            {data?.companyId?.name}
          </MenuItem>
          </div>:
          <></>
        }

        <div className={navClassName}>
        <MenuItem className='w-full' onClick={openSettings}>
          <ListItemIcon sx={{color: 'white'}}>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        </div>
        <hr className="mx-3"/>
        <div className={navClassName}>
        <MenuItem className='w-full' onClick={Auth.logout}>
          <ListItemIcon sx={{color: 'white'}}>
            <Logout fontSize="small" />
          </ListItemIcon>
          {strings.logout}
        </MenuItem>
        </div>
      </Menu>
        </React.Fragment>
    )
    }