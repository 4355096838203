export const landingStrings = {
    en:{
        buyerTab: 'BUYER',
        driverTab: 'DRIVER',
        driverStrong1: 'CREATE',
        driverString1: 'YOUR SCHEDULE',
        driverStrong2: 'FOLLOW',
        driverString2: 'PRECISE DIRECTIONS',
        driverStrong3: 'GET PAID',
        driverString3: 'WEEKLY',
        buyerStrong1: 'SCHEDULE',
        buyerString1: 'YOUR TRUCKS',
        buyerStrong2: 'TRACK',
        buyerString2: 'YOUR MATERIAL',
        buyerStrong3: 'SIMPLIFY',
        buyerString3: 'THE PAPERWORK',
        howItWorks: 'HOW IT WORKS',
        whoWeAre:"WHO WE ARE",
        whoWeAreMsg:"Haul'r is a Colorado-based tech startup that provides a next-generation logistics software suite. We make managing materials-transport easier than it has ever been.",
        whatWeOffer:"WHAT WE OFFER",
        whatWeOfferMsg:"Haul'r offers vetted drivers and low hourly pricing to companies in Colorado for all their short-haul transportation needs. Our pricing is much lower than the competition because nearly all of the logistical heavy lifting is done by our software.",
        howToUse:"HOW TO USE IT",
        howToUseMsg1:"As a buyer, enter job details the app to schedule trucks. Currently, we are in beta. If you'd like to participate, contact Miguel at (email), then we'll begin the verification process.",
        howToUseMsg2:"After you've been verified, you or your supervisors will be able to use the app to reserve trucks on demand or in advance.",
        driverHowToUse:"If you'd like to drive with us, this is also invite based. After completing verification you'll be able to add yourself and your truck(s) to the app, manage your scheduling, track your miles. Pick up your job, and log your hours and mileage, and start Haulin'!",
        ourServices:"OUR SERVICES",
        ourServicesMsg:"We provide simplified, digitized service to the construction industry. Use our app to order and organize trucks of all types and oversee them in the field. Use our online portal to view your job history and documentation.",
        comingSoon:"Coming soon to the App Store!",
    },
    es:{
        buyerTab: 'COMPRADOR',
        driverTab: 'CONDUCTOR',
        driverStrong1: 'CREA',
        driverString1: 'TU HORARIO',
        driverStrong2: 'SIGA',
        driverString2: 'INSTRUCCIONES PRECISAS',
        driverStrong3: 'RECIBA PAGOS',
        driverString3: 'SEMANALES',
        buyerStrong1: 'PROGRAME',
        buyerString1: 'SUS CAMIONES',
        buyerStrong2: 'RASTREA',
        buyerString2: 'TU MATERIAL',
        buyerStrong3: 'SIMPLIFIQUE',
        buyerString3: 'EL PAPELEO',
        howItWorks: 'CÓMO FUNCIONA',
        whoWeAre:"QUIENES SOMOS",
        whoWeAreMsg:"Haul'r es una startup tecnológica con sede en Colorado que ofrece un paquete de software de logística de próxima generación. Hacemos que la gestión del transporte de materiales sea más fácil que nunca.",
        whatWeOffer:"LO QUE OFRECEMOS",
        whatWeOfferMsg:"Haul'r ofrece conductores aprobados y precios bajos por hora a empresas en Colorado para todas sus necesidades de transporte de corta distancia. Nuestro precio es mucho más bajo que el de la competencia porque casi todo el trabajo pesado logístico lo realiza nuestro software.",
        howToUse:"CÓMO USARLO",
        howToUseMsg1:"Como comprador, ingrese los detalles del trabajo en la aplicación para programar camiones. Actualmente, estamos en beta. Si desea participar, comuníquese con Miguel al (correo electrónico), luego comenzaremos el proceso de verificación.",
        howToUseMsg2:"Una vez que haya sido verificado, usted o sus supervisores podrán usar la aplicación para reservar camiones a pedido o por adelantado.",
        driverHowToUse:"Si desea conducir con nosotros, esto también se basa en invitaciones. Después de completar la verificación, podrá agregarse a sí mismo y a su(s) camión(es) a la aplicación, administrar su programación, realizar un seguimiento de sus millas. Retome su trabajo, registre sus horas y millas, ¡y comience a transportar!",
        ourServices:"NUESTRO SERVICIO",
        ourServicesMsg:"Brindamos un servicio simplificado y digitalizado a la industria de la construcción. Utilice nuestra aplicación para ordenar y organizar camiones de todo tipo y supervisarlos en el campo. Utilice nuestro portal en línea para ver el historial y la documentación de su trabajo.",
        comingSoon:"¡Próximamente en la App Store!",
    }
};