import {useState,useRef,useEffect} from 'react'
import { useDeleteJob } from "hooks/useDeleteJob";
import ModalMaterialUI from './ModalMaterialUI';
import MutateStatus from 'components/MutateStatus';
import { useNavigate } from 'react-router-dom'

export default function DeleteJobBtn({jobId,jobName}) {
  const {mutate:deleteJob,status,isSuccess,data} = useDeleteJob();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formRef = useRef()

  function handleSubmit(evt){
    evt.preventDefault();
    if(formRef.current.elements.refer.value === jobName || formRef.current.elements.refer.value.toLowerCase() === jobName.toLowerCase()){
      deleteJob(jobId)
    }
  }
  useEffect(() => {
    if(isSuccess && !data?.error){
      setOpen(false);
      navigate(`/dashboard?stage=ActiveJobs`) 
    }
  }, [isSuccess])

  return (
    <>
      <button onClick={handleOpen} className="dangerBtn">delete job</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
          <h1>DELETE JOB</h1>
          <form onSubmit = {handleSubmit} ref={formRef}  >
            <h3 className='font-bold'> Are you sure you want to delete '{jobName}'?</h3>
            <h3  className='text-red-600 underline'> This will also delete all tickets </h3>
            <h3 className='text-red-600 underline'>This action cannot be undone.</h3>
            <br/>
            <label htmlFor="refer">Please enter the job name to delete '{jobName}': 
            <br/> 
            <input placeholder='********' type="text" name="refer" required  /></label>
            <button onClick={handleSubmit} className="dangerBtn">DELETE</button>
            <button onClick={handleClose} className="oBtn">Close</button>
          </form>
          <MutateStatus status={status}/>
      </ModalMaterialUI>
    </>
  )
}
