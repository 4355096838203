import React, { useEffect, useState } from "react";
import { useUserData } from "../../hooks/useUserDataOnce";
import { useQueryClient } from '@tanstack/react-query'
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import './styles.css';
import goFetch from "../../utils/goFetch";
import axios from 'axios';
import { UploadDocumentsLocaliseString } from "./UploadDocuments.local";
import { Localization } from "utils";

export default function UploadDocuments() {
  const strings = new Localization(UploadDocumentsLocaliseString).strings;
  const { data: user, isFetching, refetch } = useUserData(
    ( data ) => {
      console.warn( 'data', data?.data?.companyId?.documents );
      if ( data?.data?.companyId.status == 'REJECTED' ) {
        // setCompanyDocuments( data?.data?.companyId?.documents || [] )
      }
    }
  )
  const company = user?.companyId || {}
  // const axios = useAxios()
  const queryClient = useQueryClient()

  const [ companyName, setCompanyName ] = useState( company?.name || '' );
  const [ companyAddress, setCompanyAddress ] = useState( `${company?.address?.postalAddress}, ${company?.address?.city}, ${company?.address?.state}, ${company?.address?.zipCode}` || '' );
  const [ isSubmitting, setIsSubmitting ] = useState( false );
  const [ companyDocuments, setCompanyDocuments ] = React.useState( [] );

  useEffect(() => {
    setCompanyName(company?.name);
    setCompanyAddress(`${company?.address?.postalAddress}, ${company?.address?.city}, ${company?.address?.state}, ${company?.address?.zipCode}`);
  }, [user]);

  const requiredDocuments = company.type == 'construction' ?
    [
      {
        id: 1,
        name: 'business_registration',
        title: 'Business Registration',
        value: 'file',
        downloadLink: null,
      },
      // {
      //   id: 2,
      //   name: 'direct_deposit',
      //   title: 'Direct Deposit',
      //   value: 'file',
      //   downloadLink: '',
      // },
      // {
      //   id: 3,
      //   name: 'w9_form',
      //   title: 'W9 Form',
      //   value: 'file',
      //   downloadLink: '',
      // },

    ] : [
      {
        id: 1,
        name: 'dot_registration',
        title: 'DOT Registration',
        value: 'file',
        downloadLink: null,
      },
      {
        id: 2,
        name: 'buisness_insurance',
        title: 'Business Insurance',
        value: 'file',
        downloadLink: null,
      },
      {
        id: 3,
        name: 'certificate_of_liability',
        title: 'Certificate of Liability',
        value: 'file',
        downloadLink: null,
      },
      {
        id: 4,
        name: 'w9_form',
        title: 'W9 Form',
        value: 'file',
        downloadLink: '',
      },
      {
        id: 5,
        name: 'direct_deposit',
        title: 'Direct Deposit',
        value: 'file',
        downloadLink: '',
      },
    ]


  const openDocumentPickerAsync = async ( documentType ) => {
  };

  const onCompanySubmit = async () => {
    if (companyName === '') {
        alert(strings.enter_company_name);
        return;
    }

    if (companyAddress === '') {
        alert(strings.enter_company_address);
        return;
    }

    console.log("companyDocuments", companyDocuments);
    console.log("requiredDocuments", requiredDocuments);

    if (company.status == 'CREATED' && (companyDocuments.length !== requiredDocuments.length)) {
        alert(strings.upload_all_documents)
        return;
    } else {
        const rejectedDocs =  company?.documents.filter((doc) => doc.status == 'REJECTED')
        const uploadedDocs = companyDocuments.filter((doc) => doc.status == 'UPLOADED')

        if (rejectedDocs.length != uploadedDocs.length) {
            alert(strings.upload_rejected_documents)
            return;
        }
    }


    const formData = new FormData();
    formData.append( 'companyName', companyName );
    formData.append( 'companyAddress', companyAddress );
    formData.append( 'userId', user?._id );
    formData.append( 'companyId', company?._id );
    if ( company.status == 'REJECTED' ) {
      const uploadedDocs = companyDocuments.filter( ( doc ) => doc.status == 'UPLOADED' )

      uploadedDocs.forEach( ( item, index ) => {

        console.log( "item", {
          uri: item.file.objectURL,
          type: item.type,
          name: `${ item.name }`,
        } );
        const _file = item.file;
        const newFile = new File( [ _file ], item.name, { type: item.type } );

        formData.append( 'documents', newFile )

      } );
    } else if ( company.status == 'CREATED' ) {
      requiredDocuments.forEach( ( item, index ) => {
        if ( companyDocuments[ index ] ) {
          // console.log( "item", {
          //   uri: companyDocuments[ index ].objectURL,
          //   type: companyDocuments[ index ].type,
          //   name: `${ item.name }`,
          // } );
          const _file = companyDocuments[ index ].file;
          const newFile = new File( [ _file ], item.name, { type: companyDocuments[ index ].type } );

          formData.append( 'documents', newFile )
        }

      } );
    }

    setIsSubmitting( true );
    let _baseURL = '';
    if(window.location.hostname == 'localhost'){
      _baseURL = `https://haulr-api.dev.sarithm.com`
    } else {
      _baseURL = `https://${window.location.hostname}`;
    }
    const axiosInstance = axios.create( {
      baseURL: _baseURL,
      // baseURL: 'http://localhost:3001',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user?.token || '',
        'user-language': user?.language || 'en',
      },
    } );
    for ( let pair of formData.entries() ) {
      console.log( pair )
    }
    console.log( "SON323232", formData )
    // return;

    await axiosInstance.post( '/users/registerCompany', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } )
      .then( ( res ) => {
        // queryClient.invalidateQueries(ApiCollection.user.me)
        setIsSubmitting( false );
        refetch();
        alert(strings.sent_for_review)
        // showToast({ message: 'Sent for Review !', type: 'success' });
        // navigation.goBack()
        console.log( "====>Success===>", res.data )

      } )
      .catch( ( err ) => {
        // setIsSubmitting(false)
        console.warn( err );
        // errorHandler(err, 'Company Review')
        console.log( "====>Success===>", err )
        alert( strings.error )
      } );

  }

  console.log( "company?.documents", company?.documents );
  const findDocumentByType = ( documentType ) => {
    let temp = [ ...companyDocuments ];

    let index = temp.findIndex( item => item.documentType === documentType );

    if ( index !== -1 ) {
      return temp[ index ];
    }
    return null;
  };

  const findDocumentMapping = ( docName ) => {
    let data;
    requiredDocuments.forEach( ( item, index ) => {
      if ( item.name === docName ) {
        data = item;
        return
      }
    } )
    return data;
  }

  const removeDocument = ( name ) => {
    let temp = [ ...companyDocuments ];
    let index = temp.findIndex( item => item.documentType === name );
    temp.splice( index, 1 );
    setCompanyDocuments( temp );
  };

  const onUploadDocumentPress = ( args, documentType ) => {
    // openDocumentPickerAsync(documentType);
    console.log( "documentType\n\n\n\n\n", args, documentType )
    if ( company.status == 'CREATED' ) {
      let temp = [ ...companyDocuments ];
      let files = args.files;
      const data = {
        ...files[ 0 ],
        file: files[ 0 ],
        name: documentType,
        type: files[ 0 ].type,
        documentType,
      };

      temp.push( data );

      setCompanyDocuments( temp );
    } else {
      let temp = [ ...companyDocuments ];
      let files = args.files;
      console.log( "files", files )

      const data = {
        file: files[ 0 ],
        type: files[ 0 ].type,
        name: documentType,
        documentType,
        isNew: true,
        status: 'UPLOADED'
      };
      // temp.push( _f );
      temp.push( data )
      setCompanyDocuments( temp );
    }
  };


  return (
    <div className="container">
      {
        company.status == 'VERIFIED' ?
          <div>
            <p className="heading">{strings.your_account_verified}</p>
          </div>
          :
          company.status != "NEEDS_REVIEW" ?
            <div>
              {/* <p>{ isSubmitting ? 'Loading' : 'Not Loading' }</p> */}
              {
                company.status == 'REJECTED' && <div>
                  <p className="heading">{strings.review_rejected}</p>
                  <ul>
                  {

                    company?.documents?.length > 0 ?
                      company?.documents?.map( ( doc, index ) => (
                        ( doc.status == 'REJECTED' || doc.status == 'UPLOADED' ) &&
                        <li className="contentText"> { doc.reviewComment }</li>
                      ) )
                      :
                      <li className="contentText">{ company?.reviewComment || '' }</li>
                  }
                  </ul>
                </div>
              }
              <div className="input-fields">
                <InputText className="p-inputtext-d" placeholder={ strings.company_name } value={ companyName } onChange={ ( e ) => setCompanyName( e.target.value ) } />
              </div>
              <div>
              <InputText className="p-inputtext-d" placeholder={ strings.company_address } value={ companyAddress } onChange={ ( e ) => setCompanyAddress( e.target.value ) } />
              </div>
              {
                company.status == 'REJECTED' ?
                  <div className="div-upload-doc">
                    <p className="heading">{strings.upload_documents}</p>
                    { company.documents.map( _item =>
                      _item.status == 'REJECTED' ?
                        findDocumentByType( _item.name ) === null ? (
                          <div className="upload-doc-c">
                            { company?.documents?.length > 0 && company?.documents?.find( ( doc ) => doc.name === _item.name )?.reviewComment &&
                              (
                                <p className="p-reason">
                                  { company?.documents?.find( ( doc ) => doc.name === _item.name )?.reviewComment }
                                </p>
                              )
                            }
                            <FileUpload
                              name="documents[]"
                              accept=".jpg,.jpeg,.png,.webp"
                              customUpload={ true }
                              uploadHandler={ ( e ) => onUploadDocumentPress( e, _item.name ) }
                              onValidationFail={ () => {
                                alert(strings.error )
                              } }
                              mode="basic"
                              chooseLabel={ `${ strings.upload } ${ findDocumentMapping( _item.name )?.title }` }
                              auto={ true }
                            />
                            {/* <Button
                            label={ `${ 'Upload' } ${ findDocumentMapping( _item.name )?.title }` }
                            onClick={ () => onUploadDocumentPress( _item.name ) }
                          /> */}
                          </div>
                        ) : (
                          <div className="upload-doc-c" key={ _item.id } >
                            {/* <Image src={  } alt="Image" width="250" preview /> */ }
                            <p className="p-reason">
                              { findDocumentMapping( _item.name )?.title }
                            </p>
                            {/* <Button
                            label={ `AppLevelCross` }
                            onClick={ () => removeDocument( _item.name ) }
                          /> */}
                            {/* <Button icon="pi pi-times" rounded severity="danger" aria-label="Cancel" onClick={ () => removeDocument( _item.name ) } /> */ }
                            <Button className="p-button-d" label={strings.remove} icon="pi pi-times" rounded text raised severity="danger" aria-label="Cancel" onClick={ () => removeDocument( _item.name ) } />

                          </div>
                        )
                        :
                        null
                    ) }
                  </div>
                  :
                  <div className="div-upload-doc">
                    <p className="heading">{strings.upload_documents}</p>
                    { requiredDocuments.map( _item =>
                      findDocumentByType( _item.name ) === null ? (
                        <div className="upload-doc-c">
                          {/* <Button
                        label={ `${ 'Upload'  } ${ _item.title }` }
                        onClick={ () => onUploadDocumentPress( _item.name ) }
                      /> */}
                          {/* <FileUpload mode="basic" name="demo[]" accept="image/*" maxFileSize={1000000} onUpload={(e) => onUploadDocumentPress(e, _item.name)} /> */ }
                          <p className="p-reason">
                              { findDocumentMapping( _item.name )?.title }
                            </p>
                          <FileUpload
                            name="documents[]"
                            accept=".jpg,.jpeg,.png,.webp"
                            customUpload={ true }
                            uploadHandler={ ( e ) => onUploadDocumentPress( e, _item.name ) }
                            onValidationFail={ () => {
                              alert( strings.error )
                            } }
                            mode="basic"
                            chooseLabel={strings.upload_image}
                            auto={ true }
                          />

                        </div>
                      ) : (
                        <div key={ _item.id } className="div-upload-doc " >
                          <p className="p-reason">{ _item.title }</p>
                          <Button
                            label={ strings.remove }
                            onClick={ () => removeDocument( _item.name ) }
                          />
                        </div>
                      ),
                    ) }
                  </div>
              }
              <Button label={ strings.submit } className="submitOption" onClick={ onCompanySubmit } />
            </div>
            :
            <div>
              <p className="heading">
                {strings.account_under_review}
              </p>
              <p className="p-reason">
                {strings.check_later}
              </p>
            </div>

      }
    </div>
  )
}