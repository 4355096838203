import LoadingIcon from "components/stateIcons/LoadingIcon"
import { Localization } from 'utils/localization';
import TableBtnDetail from "../TableBtnDetail"
import {useActiveJobs} from "hooks/useActiveJobs"
const local = new Localization({
  en:{
    allJobs:"All active jobs"
  },
  es:{
    allJobs:"Todos los trabajos activos"
  }
}).strings;


export default function ActiveJobs() {
  const result = useActiveJobs();

  if (result.isLoading) {
    return  <LoadingIcon/>
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>
  }
  return(

       <div className="stageFormat ">
          <h1 className="stageTitle"> {local.allJobs}  </h1>
          <TableBtnDetail column= {['name','constructionId','numberOfTrucksNeeded','scheduledStart','scheduledEnd','dateCreated']} dataArray={result.data} clickNav="JobDetail" />
       </div>
   )
}