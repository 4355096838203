import { useState, useEffect } from 'react';
import { useJobByJobId } from './useJobByJobId';
// This hook returns number of drivers already assigned and 
// those tickets that have been assigned.
export const useJobAssigned = (jobId) =>{
  const [numOfDrivers,setNumOfDrivers] = useState(0)
  const [companyItems,setAssignmentItems] = useState([])
  const {data:job} = useJobByJobId(jobId);
  useEffect(() => {
    let newCompanys = {};
    let startDateCount = {};
    let totalAssign = 0;
    job?.tickets?.forEach(assign=>{
      if(!newCompanys[assign.groupBy]){
        newCompanys[assign.groupBy] = [];
        totalAssign++;

      }
      if(!startDateCount[assign.startDate] ){
        startDateCount[assign.startDate] = 0
      }
      startDateCount[assign.startDate] ++
      newCompanys[assign.groupBy].push(assign)
    });
    setNumOfDrivers(totalAssign);
    let rtnStack = [];
    for(let key in newCompanys){
      rtnStack.push( newCompanys[key] )
    }
    setAssignmentItems(rtnStack);
  }, [job])

  return [numOfDrivers,companyItems] ;
}

