import { useServerAssets } from 'hooks/useServerAssets';
export default function SubmitTruck({truckTypes,setTruckTypes}) {
  const truckCategories = useServerAssets(`/assets/truckCategories`);
  function modArray(evt){
    if(evt.target.checked){
      truckTypes.push(evt.target.getAttribute('data-type'))
      truckTypes.sort()
    } else {
    }
  }  

  return (
  <label className="flex items-center flex-col md:flex-col">
    <h1 className='text-white'>
    What types of trucks?
    </h1>
    <div className='md:flex-col'>
    {
      truckCategories?.data?.map((type,index)=>(
        <div className='flex items-center text-white text-lg ' key={index} >
          <input type="checkbox" data-type={type}  onChange={modArray}/> 
          <h2>{type}</h2>
        </div>))
    }
    </div>
  </label>
  )
}
