import { createColumnHelper } from "@tanstack/react-table";
import { Button } from "primereact/button";
import './table.css';
import { CompanyLocaliseString } from "./Company.local";
import { Localization } from "utils";

export const columns = (openDialog) => {
  const strings = new Localization(CompanyLocaliseString).strings;
  const columnHelper = createColumnHelper();
  const userColumns = [
    // columnHelper.accessor("_id", {
    //   id: "_id",
    //   header: "id",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    columnHelper.accessor("name", {
      id: "name",
      header: strings.name_table_heading,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    columnHelper.accessor("owner", {
      id: "owner",
      header: strings.owner_table_heading,
      cell: (props) => <span>{props.getValue()}</span>
    }),
    // columnHelper.accessor("email", {
    //   id: "email",
    //   header: "Email",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    // columnHelper.accessor("phoneNr", {
    //   id: "phoneNr",
    //   header: "Phone",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    columnHelper.accessor("status", {
      id: "status",
      header: strings.status_table_heading,
      cell: (props) => <span>{strings[props.getValue()]}</span>
    }),
    columnHelper.accessor("address.postalAddress", {
      id: "address.postalAddress",
      header: strings.postal_address_table_heading,
      cell: (props) => <span>{`${props?.row?.original?.address?.postalAddress}, ${props?.row?.original?.address?.city}, ${props?.row?.original?.address?.state}, ${props?.row?.original?.address?.zipCode}`}</span>
    }),
    // columnHelper.accessor("gender", {
    //   id: "gender",
    //   header: "Gender",
    //   cell: (props) => <span>{props.getValue()}</span>
    // }),
    columnHelper.accessor("view", {
      id: "View",
      header: strings.view_option,
      cell: (props) => (
        <div>
          <Button
            onClick={() => {
              openDialog(props.row.original, true);
            }}
            // style={{ margin: "1px", padding: "10px" }}
            label={strings.view_option}
          />
        </div>
      )
    })
  ];
  return { userColumns };
};
