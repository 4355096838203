import { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import LoadingIcon from "components/stateIcons/LoadingIcon"
import InviteNewUserBtn from "components/modals/InviteNewUserBtn";
import TruckBtn from "components/modals/TruckBtn"
import TableBtnDetail from "components/TableBtnDetail";
import { useCompanyById } from 'hooks/useCompanyById';
import UserPermission from 'components/wrappers/UserPermission';
import ChangeCompAddressBtn from 'components/modals/ChangeCompAddressBtn';
import AddRoleBtn from 'components/modals/AddRoleBtn'
import RoleModal from 'components/modals/RoleModal';
import Auth from 'utils/auth';

export default function CompanyDetail() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const companyId = searchParams.get('data') ?? Auth.getUserData().companyId;
  const {data,isLoading,isError,error} = useCompanyById(companyId);
  const [showOrg,setShowOrg] = useState(false)
  const [modalRole,setModalRole] = useState(false)
  
  if (isLoading) {
    return  <LoadingIcon />
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className='stageFormat text-center '>
      <h1 className="stageTitle">Company: {data.name} <UserPermission perm="ceo"><button className="oBtn" onClick={()=> setShowOrg(!showOrg) }> +</button></UserPermission></h1>
      
        {
          showOrg ?
          <div className='p-4 flex flex-col w-full'>
            <TableBtnDetail column={['roleName','permissions']} dataArray={data.roles} clickModal={ <RoleModal company={data} role={modalRole} /> } setModalData={(evt)=>setModalRole(evt)} />
            <AddRoleBtn company={data} />
          </div>:
          <></>
        }

      <h2 className='text-white strong2 inline'> Owner:&nbsp;</h2>
      <h2 className='text-white inline capitalize'>{data?.owner?.firstName} {data?.owner?.lastName} </h2>
      

      <h2  className="text-white"> 
        Address:&nbsp;
        <UserPermission perm="organize">
          <ChangeCompAddressBtn orgId={companyId}/>
        </UserPermission>
      </h2> 

      { data?.address ? 
        <div  className="text-white">
          <h2>{data.address.postalAddress} {data.address.city}, {data.address.state} {data.address.zipCode}</h2>
        </div> : 
        <></>
      }
              <UserPermission perm="organize">
              <div className='flex items-center justify-around'>
                <TruckBtn company={data} />
                <InviteNewUserBtn company={data}/>
              </div>
              </UserPermission>
        <div className='p-4 mt-4 flex flex-col w-full'>
        {
            data.users.length > 0 ? 
            <>
              <h2 className='text-white strong2'> {data.name}'s Users </h2>
              <TableBtnDetail column={['role','firstName','lastName','username','phoneNr','driverLicenseNr']} dataArray={data.users} clickNav="UserDetail"/>

              <UserPermission perm="organize">
              </UserPermission>

            </>
            :
            <></>
          }
          {
            data.drivers.length > 0 ? 
            <>
              <h2 className='text-white strong2'> {data.name}'s Drivers </h2>
              <TableBtnDetail column={['firstName','lastName','username','phoneNr','driverLicenseNr']} dataArray={data.drivers} clickNav="UserDetail"/>
            </>
            :
            <>
            </>
          }
          {
            data.trucks.length > 0 ?
            <>
              <h2 className='text-white strong2'> {data.name}'s Trucks </h2>
              <TableBtnDetail column={['make','model','category','licensePlate','cDotId']} dataArray={data.trucks} clickNav="TruckDetail"/>

            </>
            :
            <>

            </>
          }

      </div>
    </div>
  )
}
