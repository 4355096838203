export default function SubmitDropLocation() {
  return (
    <div className="flex items-center flex-col">
    <h1 className='text-white'> Drop Off Location</h1>
    <label className="flex items-center">
      <h1 className='text-white'>
      Address
      </h1>
      <input type="text" name="dropAddress"  className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      City
      </h1>
      <input type="text" name="dropCity"  className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      State
      </h1>
      <input type="text" name="dropState" placeholder="CO"  className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      Zipcode
      </h1>
      <input type="text" name="dropZip"  className="textInput"/>
    </label>
    <h2>or </h2>

      <label className="flex items-center">
      <h1 className='text-white'>
      latitude
      </h1>
      <input type="text" name="dropLatitude"  className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      longitude
      </h1>
      <input type="text" name="dropLongitude"  className="textInput"/>
    </label>
  </div>
  )
}
