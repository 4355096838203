import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useTicketsAsDriver = (id) => {
  return useQuery({ queryKey: [`${id}assign`], queryFn: async()=>{
    const rpnse = await goFetch(`/tickets/asDriver/`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json();
    return rpn;
  }})  
}