import {useRef,useState} from 'react';
import {useUserData} from 'hooks/useUserData'
import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import ModalMaterialUI from './ModalMaterialUI';
import MutateStatus from 'components/MutateStatus';
import UserPermission from 'components/wrappers/UserPermission';

export default function InviteNewUserBtn({company,user}) {
  const {data:userData} = useUserData();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formRef = useRef()
  const displayText =  user ? 'edit' : 'invite';
  const queryClient = useQueryClient();
  if(!company && user?.companyId?.name){
    company = user.companyId;
  }
  const createUser = useMutation({
    mutationFn: async (newUser)=>{
      const rspnse = await goFetch('/users/invite', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      if(!data.error){
        alert('invite sent!');
        handleClose()
      }
    },
  });  
  const mutateUser = useMutation({
    mutationFn: async (submit)=>{
      const rspnse = await goFetch(`/users/mutate/${user._id}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(submit)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      if(!data.error){
        handleClose()
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: [`/users/byId/${user._id}`] })
      }
    },
  }); 
  function newUser(evt){
    evt.preventDefault();
    const submit = {
      email:formRef.current.elements.email.value.toLowerCase(),
      username:formRef.current.elements.username.value,
      password:formRef.current.elements.password.value,
      firstName:formRef.current.elements.firstName.value,
      lastName:formRef.current.elements.lastName.value,
      phoneNr:formRef.current.elements.phoneNr.value,
      companyId: company?._id ?? userData.companyId._id,
      companyName: company?.name ?? userData.companyId.name,
      language:formRef.current.elements.language?.value,
      driverLicenseNr: formRef.current.elements.driverLicenseNr.value,
      role: formRef.current.elements.role?.value ?? user.role,
      address:{
        postalAddress: formRef.current.elements.postalAddress.value,
        city: formRef.current.elements.city.value,
        state: formRef.current.elements.state.value,
        zipCode: formRef.current.elements.zip.value,
      }
    }
    if(user){
      mutateUser.mutate(submit);
    } else {
      createUser.mutate(submit);
    }
  }
  return (
    <div>
      <button onClick={handleOpen} className="oBtn">{displayText} user</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        <Error className="top-1 left-1" useMutation={createUser}/>
        <h1>{displayText} User to {company?.name ?? userData?.companyId.name}</h1>
          <form onSubmit = {newUser} ref={formRef} >
            <div className='flex flex-col p-5'>
              {
                displayText === 'edit' ?
                <></>:
                <>
                  <label htmlFor="email" className="flex justify-between items-center"> 
                    <h3>Email:</h3>  
                    <input placeholder='johndoe@email.com' type="text" name="email" className='textInput' defaultValue={user? user.email : ''}  required />
                  </label>
                  <label htmlFor="email" className="flex justify-between items-center"> 
                    <h3>username:</h3>  
                    <input placeholder='johndoe@email.com' type="text" name="username" className='textInput' defaultValue={user? user.username : ''}  required />
                  </label>
                  <label htmlFor="email" className="flex justify-between items-center"> 
                    <h3>password:</h3>  
                    <input placeholder='johndoe@email.com' type="text" name="password" className='textInput'  defaultValue={user? user.password : ''}  required />
                  </label>
                </>
              }

              {
                displayText === 'edit' ? 
                <>
                  <label htmlFor="email" className="flex justify-between "> 
                    <h3>username:</h3>  
                    <input placeholder='johndoe@email.com' type="text" name="username" defaultValue={user? user.username : ''}  required />
                  </label>
                <label htmlFor="firstName" className="flex justify-between"> 
                  <h3>First&nbsp;Name:</h3>  
                  <input placeholder='Name' type="text" name="firstName"  defaultValue={user? user.firstName : ''}   required />
                </label>
                <label htmlFor="lastName" className="flex justify-between"> 
                  <h3>Last&nbsp;Name:</h3>  
                  <input placeholder='Last Name' type="text" name="lastName" defaultValue={user? user.lastName : ''}  required />
                </label>
                <label placeholder='' htmlFor="phoneNr" className="flex justify-between"> 
                  <h3>Phone&nbsp;#:</h3>  
                  <input placeholder='(000)-000-0000' type="text" name="phoneNr" defaultValue={user? user.phoneNr : ''}  required />
                </label>
                <label htmlFor="driverLicenseNr" className="flex justify-between"> 
                  <h3>Driver&nbsp;License&nbsp;#:</h3>  
                  <input placeholder='Drivers License ID' type="text" name="driverLicenseNr" defaultValue={user? user.driverLicenseNr : ''} required />
                </label>
                <label htmlFor="address" className="flex justify-between"> 
                  <h3>Street&nbsp;Address:</h3>  
                  <input placeholder='1234 Place St.' type="address" name="postalAddress" defaultValue={user?.address ? user.address.postalAddress : ''}/>
                </label>
                <label htmlFor="city" className="flex justify-between"> 
                  <h3>City:</h3>  
                  <input placeholder='City' type="city"  name="city"  defaultValue={user?.address ? user.address.city : ''}/>
                  </label>
                <label htmlFor="state" className="flex justify-between"> 
                  <h3>State:</h3>  
                  <input placeholder='State' type="state" name="state" defaultValue={user?.address ? user.address.state : ''}/>
                  </label>
                <label htmlFor="zip" className="flex justify-between"> 
                  <h3>Zipcode:</h3>  
                  <input placeholder='12345' type="zip" name="zip" defaultValue={user?.address ? user.address.zipCode : ''}/>
                </label>                
                </>
                :<></>
              }
              <label htmlFor="role"  className="flex justify-between  mt-5">
                <h3>role:  </h3>
                <UserPermission perm="organize" falseDisplay={user?.role}>
                  <select name="role" required className='text-black' defaultValue={user? user.role : ''} >
                    {

                      company  ?
                        company.roles.map(role=><option value={role.roleName} key={role.roleName} >{role.roleName}</option>)
                          :
                        userData?.companyId.roles 
                          ?
                        userData.companyId.roles.map(role=><option value={role.roleName} key={role.roleName} >{role.roleName}</option>)
                        :
                        <option value="new">new</option>     
                    }
                  </select>
                </UserPermission>
              </label>


            </div>
            <hr/>
            <br/>
            <div className='flex justify-around'>
              <button onClick={handleClose} className="oBtn">Close</button>
              <button onClick={newUser} className="oBtn">{displayText}</button>
            </div>
            <MutateStatus useMutation={createUser} />
          </form>          
      </ModalMaterialUI>
    </div>
  )
}
