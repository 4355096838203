export const footerStrings = {
    en:{
        about: 'ABOUT',
        aboutUs: 'About Us',
        faqs: 'FAQs',
        legal: 'LEGAL',
        privacyPolicy: 'Privacy Policy',
        termsOfService: 'Terms of Service',
        userAgreement: 'User Agreement',
        social: 'SOCIAL',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        instagram: 'Instagram',
        contact: 'CONTACT',
        getInTouch: 'Get in Touch'
    },
    es:{
        about: 'Sobre',
        aboutUs: 'Sobre Nosotros',
        faqs: 'Preguntas más Frecuentes',
        legal: 'Legal',
        privacyPolicy: 'Política de Privacidad',
        termsOfService: 'Términos de Servicio',
        userAgreement: 'Términos de Servicio',
        social: 'Sociales',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedIn: 'LinkedIn',
        contact: 'Contacto',
        getInTouch: 'Ponerse en Contacto'
    }
};