import { tosStrings } from "./TermsOfService.local";
import { Localization } from "../utils/localization";

export default function TOS() {
  const strings = new Localization(tosStrings).strings;

  return (
    <div className="TOS" style={{ alignItems: "center", textAlign: "justify" }}>
      <h1 style={{ color: "white" }}>Terms and Conditions for Haulr</h1>
      <div style={{ color: "white", width: "50%" }}>
        <p>
          <strong>Introduction</strong>
        </p>
        <br/>
        <p>
          Welcome to Haulr! These Terms and Conditions govern your use of the
          Haulr app and provide information about the Haulr service, outlined
          below. When you create a Haulr account or use Haulr, you agree to
          these terms.
        </p>
        <br />
        <br />
        <p>
          <strong>Using Haulr</strong>
          <br />
          <br />
          a. <strong>Who Can Use Haulr</strong>: You must be at least 18 years
          old or the minimum legal age in your country to use Haulr.
        </p>
        <br />
        <p>
          b. <strong>Our License to You</strong>: Subject to these Terms and
          Conditions, we grant you a limited, non-exclusive, non-transferable,
          and revocable license to use our app.
        </p>
        <br />
        <br />
        <p>
          <strong>Your Commitments</strong>
          <br />
          <br />
          a. <strong>No Illegal Use</strong>: You may not use our service for
          any illegal or unauthorized purpose.
        </p>
        <br />
        <p>
          b. <strong>Accuracy of Information</strong>: The information you
          provide must be accurate and up to date.
        </p>
        <br />
        <p>
          c. <strong>Safety</strong>: You must not compromise the safety of any
          person or the app.
        </p>
        <br />
        <br />
        <p>
          <strong>Non-Compete Clause</strong>
          <br />
          <br />
          As a user of Haulr, you agree not to engage in, start, or participate
          in any business that competes directly with the Haulr app within a
          region of 120 miles from your primary location of use, for a period of
          3 years after the last use of your Haulr account. Additionally, you
          shall not approach any company using the Haulr app for competing
          business purposes during this period.
        </p>
        <br />
        <br />
        <p>
          <strong>Dedication and Completion Clause</strong>
          <br />
          <br />
          a. <strong>Commitment to Completion</strong>: Parties involved in
          development, operation, and maintenance are required to fulfill their
          duties for timely and complete execution of the app.
        </p>
        <br />
        <p>
          b. <strong>Failure to Complete Duties</strong>: Failure to perform or
          abandonment of responsibilities may result in liability for damages to
          Haulr, including delays and financial losses.
        </p>
        <br />
        <p>
          c. <strong>Withholding of Payment</strong>: Haulr may withhold payment
          or recover funds for damages due to incomplete or substandard work.
        </p>
        <br />
        <p>
          d. <strong>Dispute Resolution</strong>: Disputes related to this
          clause will be resolved through mediation or arbitration as detailed
          in individual agreements.
        </p>
        <br />
        <br />
        <p>
          <strong>Limitation of Liability</strong>
          <br />
          Haulr is not liable for any damages or losses resulting from your use
          of the app.
        </p>
        <br />
        <br />
        <p>
          <strong>Termination</strong>
          <br />
          We may terminate or suspend access to our service immediately, without
          prior notice or liability, for any reason.
          <br />
          <br />
          <strong>Changes</strong>
          <br />
          We reserve the right to modify these terms at any time.
          <br />
          <br />
          <strong>Contact Us</strong>
          <br />
          If you have any questions about these Terms, please contact us at
          {" "}<a href="tel:+1(720)969-9307">+1 (720) 969-9307</a>
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}
