export default function SubmitLocation() {
  return (
    <div className="flex items-center flex-col ">
    <h1 className='text-white'> Pick up Location</h1>
    <label className="flex items-center">
      <h1 className='text-white'>
      Address
      </h1>
      <input type="text" name="pickAddress" className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      City
      </h1>
      <input type="text" name="pickCity" className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      State
      </h1>
      <input type="text" name="pickState" placeholder="CO" className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      Zipcode
      </h1>
      <input type="text" name="pickZip" className="textInput"/>
    </label>
      <h2>or </h2>
      <label className="flex items-center">
      <h1 className='text-white'>
      latitude
      </h1>
      <input type="text" name="pickLatitude" className="textInput"/>
    </label>
    <label className="flex items-center">
      <h1 className='text-white'>
      longitude
      </h1>
      <input type="text" name="pickLongitude" className="textInput"/>
    </label>

  </div>
  )
}
