import { useState, useEffect } from 'react';

export const useTicketsGroupBy = (data) =>{
  // This creates an array of arrays of tickets filtered by GroupBy
  const [stacks, setStacks] = useState([]);
  useEffect(() => {
    if(data){
      let parsedDates = {};
        if(!data.error){
          data?.forEach(assign=>{
            if(!parsedDates[assign.groupBy]){
              parsedDates[assign.groupBy] = [];
            }
            parsedDates[assign.groupBy].push(assign)
          })
        } else {
          // console.log(data.error);
        }
      let timeFillCount = [];
      for(let k in parsedDates){
        timeFillCount.push( parsedDates[k] )
      }    
      setStacks(timeFillCount )
    }    
  },[data]);
  return [stacks] ;
}

