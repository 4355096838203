import {useRef,useEffect} from "react";
import {  useParams } from 'react-router-dom';
import LoadingIcon from "components/stateIcons/LoadingIcon"
import {useMutation} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from "components/stateIcons/Error";
import Auth from "utils/auth";
import Footer from "components/Footer";
import { useUserById } from "hooks/useUserById";

export default function AcctCreate() {
  const formRef = useRef()
  let {inviteId} = useParams();
  const result = useUserById(inviteId);
  const invitedUser = useMutation(async (newUser)=>{
      const rspnse = await goFetch('/users/byinvite', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser)
      });    
      const rpn = await rspnse.json();
      return rpn;
    } );  


  useEffect(() => {
    if(invitedUser.isSuccess && !invitedUser.data?.error){
      if( invitedUser.data.token ){
        Auth.login(invitedUser.data.token);

      }
    }
  }, [invitedUser.isSuccess,invitedUser.data])

  function newUser(evt){
    evt.preventDefault();
    const submit = {
      email:formRef.current.elements.email?.value.toLowerCase(),
      username: formRef.current.elements.username?.value,
      password: formRef.current.elements.password?.value,
      firstName: formRef.current.elements.firstName?.value,
      lastName: formRef.current.elements.lastName?.value,
      role: result.data.role,
      id: result.data._id
    }
    invitedUser.mutate(submit)
  }
    
  
  if (result.isLoading) {
    return  <LoadingIcon />
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>
  }

    return(
      <>
        <div className="About text-[var(--lightGray)] ">
          <Error useMutation={invitedUser} />
          <h1> hello {result.data.email} </h1>
          Please fill out the detail below and click create account
          <form onSubmit = {newUser} ref={formRef} >
            <div className='flex flex-col'>
            {/* <label htmlFor="email">email:  <input type="text" name="email" default={result.data?.email}  required /></label> */}
            <label htmlFor="username">username:  <input type="text" name="username"  required /></label>
            <label htmlFor="firstName">first name:  <input type="text" name="firstName"  required /></label>
            <label htmlFor="lastName">last name:  <input type="text" name="lastName"  required /></label>
            <label htmlFor="password">password:  <input type="text" name="password"  required /></label>

            <label htmlFor="role"> new account role : {result.data.role} </label>

            </div>
            <button onClick={newUser} className="oBtn">Create</button>
          </form>          
        </div>
        <Footer/>
        </>
    )

}