export default function SubmitName() {
  return (
    <label className="flex flex-col md:flex-row items-center flex-wrap">
      <h1 className='text-white text-center'>
      What is the name of this project?
      </h1>
      <input type="text" name="jobName" className="textInput"/>
    </label>
  )
}
