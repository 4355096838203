export default function SubmitMaterial() {
  return (
    <label className="flex flex-col md:flex-col items-center">
      <h1 className='text-white text-center'>
        What is the materials being hauled.
      </h1>
      <input type="text" name="materialHauled" className="textInput" />
      <h1 className='text-white text-center'>
        How much material being moved in cubic yards.
      </h1>
      <input type="text" name="materialAmount" className="textInput" />
    </label>
  )
}
