export const data = [
  {
    id: 1,
    company_name: "Rhynyx",
    status: "in Progress",
    ip_address: "128.243.40.190",
    document: [
      {
        name: "Document 1",
        id: 1
      },
      {
        name: "Document 2",
        id: 2
      },
      {
        name: "Document 3",
        id: 3
      },
      {
        name: "Document 4",
        id: 4
      }
    ]
  },
  {
    id: 2,
    company_name: "Rhynyx",
    status: "complated",
    ip_address: "116.82.201.221",
    document: [
      {
        name: "Document 1",
        id: 1
      },
      {
        name: "Document 2",
        id: 2
      },
      {
        name: "Document 3",
        id: 3
      },
      {
        name: "Document 4",
        id: 4
      }
    ]
  },
  {
    id: 3,
    company_name: "Oyoloo",
    status: "pending",
    ip_address: "84.134.101.169",
    document: [
      {
        name: "Document 1",
        id: 1
      },
      {
        name: "Document 2",
        id: 2
      },
      {
        name: "Document 3",
        id: 3
      },
      {
        name: "Document 4",
        id: 4
      }
    ]
  },
  {
    id: 4,
    company_name: "Cogibox",
    status: "complated",
    ip_address: "181.194.175.9",
    document: [
      {
        name: "Document 1",
        id: 1
      },
      {
        name: "Document 2",
        id: 2
      },
      {
        name: "Document 3",
        id: 3
      },
      {
        name: "Document 4",
        id: 4
      }
    ]
  },
  {
    id: 5,
    company_name: "Viva",
    status: "complated",
    ip_address: "121.96.170.148",
    document: [
      {
        name: "Document 1",
        id: 1
      },
      {
        name: "Document 2",
        id: 2
      },
      {
        name: "Document 3",
        id: 3
      },
      {
        name: "Document 4",
        id: 4
      }
    ]
  },
  {
    id: 6,
    company_name: "Browsecat",
    status: "pending",
    ip_address: "10.118.21.37"
  },
  {
    id: 7,
    company_name: "Cogibox",
    status: "pending",
    ip_address: "247.185.253.105"
  },
  {
    id: 8,
    company_name: "Avavee",
    status: "in Progress",
    ip_address: "158.247.140.148"
  },
  {
    id: 9,
    company_name: "Realbridge",
    status: "pending",
    ip_address: "11.112.2.178"
  },
  {
    id: 10,
    company_name: "Voomm",
    status: "in Progress",
    ip_address: "120.4.207.29"
  },
  {
    id: 11,
    company_name: "Bubblemix",
    status: "pending",
    ip_address: "96.4.192.227"
  },
  {
    id: 12,
    company_name: "Eadel",
    status: "in Progress",
    ip_address: "21.178.21.61"
  },
  {
    id: 13,
    company_name: "Yacero",
    status: "in Progress",
    ip_address: "218.16.192.146"
  },
  {
    id: 14,
    company_name: "Tekfly",
    status: "in Progress",
    ip_address: "22.119.41.217"
  },
  {
    id: 15,
    company_name: "Fivebridge",
    status: "in Progress",
    ip_address: "179.179.14.99"
  },
  {
    id: 16,
    company_name: "Trudoo",
    status: "in Progress",
    ip_address: "240.183.189.229"
  },
  {
    id: 17,
    company_name: "Voomm",
    status: "complated",
    ip_address: "237.42.219.206"
  },
  {
    id: 18,
    company_name: "Trudoo",
    status: "pending",
    ip_address: "220.152.75.180"
  },
  {
    id: 19,
    company_name: "Gigashots",
    status: "pending",
    ip_address: "142.253.39.196"
  },
  {
    id: 20,
    company_name: "Dynazzy",
    status: "pending",
    ip_address: "86.162.212.86"
  },
  {
    id: 21,
    company_name: "Brainlounge",
    status: "complated",
    ip_address: "145.190.7.41"
  },
  {
    id: 22,
    company_name: "Yoveo",
    status: "complated",
    ip_address: "164.229.149.205"
  },
  {
    id: 23,
    company_name: "Rhynyx",
    status: "in Progress",
    ip_address: "97.117.62.208"
  },
  {
    id: 24,
    company_name: "Wordware",
    status: "in Progress",
    ip_address: "239.189.72.176"
  },
  {
    id: 25,
    company_name: "Jayo",
    status: "complated",
    ip_address: "254.157.132.35"
  },
  {
    id: 26,
    company_name: "Zoomlounge",
    status: "in Progress",
    ip_address: "221.217.190.107"
  },
  {
    id: 27,
    company_name: "Trudoo",
    status: "complated",
    ip_address: "142.242.210.10"
  },
  {
    id: 28,
    company_name: "Mymm",
    status: "in Progress",
    ip_address: "176.171.31.69"
  },
  {
    id: 29,
    company_name: "Twinder",
    status: "pending",
    ip_address: "47.125.210.92"
  },
  {
    id: 30,
    company_name: "Devbug",
    status: "complated",
    ip_address: "3.252.21.249"
  },
  {
    id: 31,
    company_name: "Fivespan",
    status: "in Progress",
    ip_address: "221.105.182.31"
  },
  {
    id: 32,
    company_name: "Vidoo",
    status: "in Progress",
    ip_address: "67.35.124.153"
  },
  {
    id: 33,
    company_name: "Quimba",
    status: "in Progress",
    ip_address: "208.149.30.35"
  },
  {
    id: 34,
    company_name: "Quamba",
    status: "complated",
    ip_address: "220.217.51.69"
  },
  {
    id: 35,
    company_name: "Trudoo",
    status: "complated",
    ip_address: "140.54.100.189"
  },
  {
    id: 36,
    company_name: "Yombu",
    status: "in Progress",
    ip_address: "205.151.215.8"
  },
  {
    id: 37,
    company_name: "Wordtune",
    status: "in Progress",
    ip_address: "104.77.142.193"
  },
  {
    id: 38,
    company_name: "Jabberbean",
    status: "complated",
    ip_address: "86.148.2.197"
  },
  {
    id: 39,
    company_name: "Janyx",
    status: "pending",
    ip_address: "158.138.76.203"
  },
  {
    id: 40,
    company_name: "Twiyo",
    status: "pending",
    ip_address: "180.214.51.37"
  },
  {
    id: 41,
    company_name: "Blogtag",
    status: "complated",
    ip_address: "172.38.216.157"
  },
  {
    id: 42,
    company_name: "Brainsphere",
    status: "pending",
    ip_address: "212.116.37.137"
  },
  {
    id: 43,
    company_name: "Oyope",
    status: "pending",
    ip_address: "194.2.117.39"
  },
  {
    id: 44,
    company_name: "Centizu",
    status: "pending",
    ip_address: "194.120.199.227"
  },
  {
    id: 45,
    company_name: "Einti",
    status: "pending",
    ip_address: "135.10.165.81"
  },
  {
    id: 46,
    company_name: "Quamba",
    status: "pending",
    ip_address: "188.109.138.172"
  },
  {
    id: 47,
    company_name: "Devify",
    status: "complated",
    ip_address: "7.196.199.194"
  },
  {
    id: 48,
    company_name: "Tagtune",
    status: "complated",
    ip_address: "219.214.208.22"
  },
  {
    id: 49,
    company_name: "Ailane",
    status: "in Progress",
    ip_address: "9.53.160.167"
  },
  {
    id: 50,
    company_name: "Avavee",
    status: "in Progress",
    ip_address: "159.206.164.209"
  }
];
