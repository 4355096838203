import {useState} from 'react'
export default function SubmitInterval() {
  const [useInterval,setUseInterval] = useState(false);
  return (
    <>
    <div className='flex  flex-col md:flex-row'>
    <h1 className='text-white text-center'>Should trucks arrive in intervals?</h1>
      <select value={useInterval} onChange={e => setUseInterval(e.target.value)} >
        <option value={true}>yes</option>
        <option value={false}>no</option>
      </select>
      </div>
    {
      useInterval === 'true' ? 
      <> 
        <label className="flex items-center  flex-col md:flex-row">
          <h1 className='text-white'>
          How often (in minutes)?
          </h1>
          <input type="number" name="truckIntervalMinutes" defaultValue="0" min="0"/>
        </label>
        <label className="flex items-center  flex-col md:flex-row">
          <h1 className='text-white'>
          How many trucks per interval?
          </h1>
          <input type="number" name="trucksPerInterval"  defaultValue="0"  min="0"/>
        </label>
      </>
      :
      <></>
    }
  </>
  )
}
