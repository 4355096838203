import {useState,useRef} from 'react'
import { useSearchParams } from 'react-router-dom';
import {useJobByJobId} from "hooks/useJobByJobId"
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';
import Auth from 'utils/auth';
import ParseDate from 'components/wrappers/ParseDate'
import { useCompanyAvailability } from 'hooks/useCompanyAvailability';
import JobCreateTicketModal from 'components/modals/JobCreateTicketModal';

export default function OpenActiveJobDetail() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const jobId = searchParams.get('data');
  const {data:job} = useJobByJobId(jobId);
  const modalRef = useRef()
  const companyId = Auth.getUserData().companyId;
  const [stacks] = useTicketsGroupBy(job?.tickets);
  const [maxRequestAllowed] = useCompanyAvailability(Auth.getUserData().companyId,jobId);
  const [modalData,setModalData] = useState({})
  
  function showModal(evt){
    evt?.preventDefault();
    const jobId = job._id;
    setModalData({driverOrgId:0,jobId})
    modalRef.current?.showModal()
  }

  return (
    <div className='bg-slate-200 p-10'>
      <h1>Open Job Detail </h1>
      <div className='flex flex-col'>
        <h2 className='strong2 inline'> Total Trucks Needed:&nbsp;{job?.numberOfTrucksNeeded}</h2>
        <h2 className='strong2 inline'> Total free slots available:{job?.numberOfTrucksNeeded -stacks.length}</h2>
        <h2 className='strong2 inline'> Job Date:&nbsp;<ParseDate options={{dateStyle:'short'}}>{job?.scheduledStart} </ParseDate></h2>
        <h2 className='strong2 inline'> Shift Start Time:&nbsp;<ParseDate options={{timeStyle:'short'}}>{job?.dailyStartTime}</ParseDate></h2>
      </div>
      <div>
        <h2>{maxRequestAllowed} drivers available from this company</h2>
      </div>
      
      <JobCreateTicketModal companyId={companyId} jobId={jobId} ref={modalRef} modalData={modalData} setModalData={setModalData}/>
      {
         maxRequestAllowed > 0  && job?.numberOfTrucksNeeded -stacks.length > 0 ? <button className='oBtn' onClick={showModal}>Accept Job</button>        : <></>
      }
      
    </div>
  )
}
