import { useOpenJobs } from 'hooks/useOpenJobs';
import TableBtnDetail from 'components/TableBtnDetail';
export default function OpenActiveJobs() {
  const openJobs = useOpenJobs();
  return (
    <div className='bg-yellow-300 p-2 m-2'>
      <h1>Open active jobs</h1>
      <TableBtnDetail column= {['companyId','name','createdBy','status','dateCreated','numberOfTrucksNeeded','truckCategories','scheduledStart','scheduledEnd']} dataArray={openJobs.data} clickNav="OpenActiveJobDetail" />
    </div>
  )
}
