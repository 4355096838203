import { Outlet } from "react-router";
import {  useState } from "react";
import SideBarC from "../../components/sidebar";
import styles from "./layout.module.scss";

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled] = useState(false);

  const [activeIndex, setActiveIndex] = useState(() => {
    const initialIndex =
      window.location.pathname === "/drivers"
        ? 0
        : window.location.pathname === "/construction-company"
        ? 1
        : window.location.pathname === "/settings"
        ? 2
        : 0;
    return initialIndex;
  });

  const handleMenuItemClick = (option: any) => {
    setActiveIndex(option);
  };

  const handleCollapsedChange = () => {
    // setCollapsed(!collapsed);
  };

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div
      className={`${styles.layout} ${
        collapsed === true ? `${styles.collapsed}` : ""
      }`}
    >
      <SideBarC
        collapsed={collapsed}
        toggled={toggled}
        handleMenuItemClick={handleMenuItemClick}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        activeIndex={activeIndex}
      />
        <Outlet />
    </div>
  );
};

export default Layout;
