import { useEffect } from 'react';
import { useUserData } from "hooks/useUserData"
import CircularProgress from '@mui/material/CircularProgress';
import VerifyAccount from "components/VerifyAccount";
import LoadingIcon from "components/stateIcons/LoadingIcon"
import { useNavigate } from 'react-router-dom'
import Auth from 'utils/auth';
export default function UserHome() {
    const navigate = useNavigate();
    const {data,isLoading, isError,refetch} = useUserData();

    useEffect(() => {
        if(data && data?.forcePasswordReset){
            navigate(`/resetPassword/${Auth.getUserData()._id}`);
        } else 
        if(data && data?.emailVerified){
            navigate("/dashboard");
        }
    }, [data,navigate])
    
    if (isLoading) {
        return  <LoadingIcon />
    }
    if (isError) {
        return  <div className="flex items-center justify-center w-full h-[20rem]"> <CircularProgress />Err </div>
    }
    
    function doSubmit(){
        refetch();
    }
    
    return(
        <div className="UserHome">
            {
                !data.emailVerified ?
                <VerifyAccount doSubmit={doSubmit}/> :
                <div> <LoadingIcon /> </div>
            }
        </div>
    )
}