import React from 'react'
import Auth from 'utils/auth';
import Stack from 'components/Stack';
import { useTicketsAsDriver } from "hooks/useTicketsAsDriver";
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';
export default function Schedule() {
  const {data} = useTicketsAsDriver(Auth.getUserData()._id);
  const [stacks] = useTicketsGroupBy(data);
  return (
    <div className='bg-slate-300 p-5 m-5'>
      <h1>Scheduled work</h1>
      {
       stacks.map( stack => <Stack stackData={stack}/>)
      }      
      
    </div>
  )
}
