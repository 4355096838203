import {useRef,useState} from 'react'
import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import ModalMaterialUI from './ModalMaterialUI';
import MutateStatus from 'components/MutateStatus';
import EditIcon from '@mui/icons-material/Edit';
export default function ChangeTimeTraveledByGroupBtn({ groupBy}) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const hourRef = useRef()
  const minuteRef = useRef()
  const changeTravelTime = useMutation({
    jobId:null,
    mutationFn: async (infobody)=>{
      const rspnse = await goFetch(`/tickets/mutateByGroup/${groupBy}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infobody)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    // Always refetch after error or success:
    onSettled: () => {
      setOpen(false)
      queryClient.invalidateQueries({ queryKey: [`/tickets/groupBy/${groupBy}`] })
    },
  })
  function handleSubmit(evt){
    evt.preventDefault();
    let minutes= parseInt(hourRef.current.value)*60;
    if(minuteRef.current.value.length){
      minutes += parseInt(minuteRef.current.value);
    }
   changeTravelTime.mutate( {  travelTimeMinutes:minutes } )
  }

  return (
    <>
      <button onClick={handleOpen} className="eBtn"><EditIcon/></button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
        <Error className="top-1 left-1" useMutation={changeTravelTime}/>
        <h1>Change Time Worked</h1>
        <div> Hours:<input type="email" ref={hourRef}/> </div>
        <div> Minutes:<input type="email" ref={minuteRef}/> </div>
        <div>
          <button onClick={handleClose} className="oBtn">Close</button>
          <button onClick={handleSubmit} className="oBtn">Update</button>
        </div>
        <MutateStatus useMutation={changeTravelTime} />
      </ModalMaterialUI>
    </>
  )
}
