import { useUserById } from "hooks/useUserById"
import { useSearchParams } from 'react-router-dom';
import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Stack from 'components/Stack';
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';
import LoadingIcon from "components/stateIcons/LoadingIcon"
import UserPermission from "components/wrappers/UserPermission";
import Auth from "utils/auth";
import InviteNewUserBtn from "components/modals/InviteNewUserBtn";
import Document from "components/Document";
import { useUserMutate } from "hooks/useUserMutate";

export default function UserDetail() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const mutateUser = useUserMutate();
  const userId = searchParams.get('data')
  const {data,isLoading,isError,error} = useUserById(userId);
  const assignsQ = useQuery({ queryKey: [`/tickets/byDriver/${userId}`], queryFn: async()=>{
    const rpnse = await goFetch(`/tickets/byDriver/${userId}`,{
            headers: { "Content-Type": "application/json" },
          });
    const rpn = await rpnse.json();
    return rpn;
  }})  
  const [stacks] = useTicketsGroupBy(assignsQ.data);

  if (isLoading || assignsQ.isLoading) {
    return  <LoadingIcon />
  }

  if (isError || assignsQ.isError) {
    return <span>Error: {error.message}</span>
  }
  const user = data;

  async function doForceReset(evt){
    evt.preventDefault();
    const password = prompt("Enter new temp password(6) for user");
    if(password){
      mutateUser.mutate({userId,body:{forcePasswordReset:true,password}});
    }
  }

  return (
    <div>
      <h1 className="text-white"> {user.firstName} {user.lastName} </h1><h2 className="text-white">@{user.username}</h2>
      {
          user._id === Auth.getUserData()._id ?
          <InviteNewUserBtn user={user} /> :   // This is edit when you give it an user
          <UserPermission perm={["ceo",'organize']}>
            <InviteNewUserBtn user={user} /> 
            <button onClick={doForceReset} className="oBtn">Reset Password</button>
          </UserPermission>
      }


      <div className="text-slate-300">
        <Document documentType="Avatar" targetId={user._id} className="h-[10rem] w-[10rem]" />
        <h3 className="uppercase"> {user.role} @ {user?.companyId?.name} {user.emailVerified ? '*' : 'Not Verified' }</h3>
        <h3> {user.phoneNr} </h3>
        <h3> Language: {user.language}</h3>
        <h3> {user.driverLicenseNr} </h3>
        <div className="flex">
        <h2 > Address:</h2> 

        { user?.address ? 
          <div  className="">
            <h2>         {user.address.postalAddress}      {user.address.city}      ,      {user.address.state}            {user.address.zipCode}          </h2>
          </div> : 
          <></>
        }
        
        </div>
        <h3> </h3>
        <h3> </h3>
        <h3> ID: {user.driverLicenseNr} </h3>
      </div>
      <h2  className="text-slate-300"> Tickets </h2>
      {
       stacks.map( stack => <Stack stackData={stack}/>)
      }
      

    </div>
  )
}

