import {useRef, useState} from 'react';
import {useUserData} from 'hooks/useUserData'
import { useServerAssets } from 'hooks/useServerAssets';
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import Auth from 'utils/auth';
import MutateStatus from 'components/MutateStatus';
import {useMutation,useQueryClient} from '@tanstack/react-query'
import ModalMaterialUI from './ModalMaterialUI';
import 'animate.css';

export default function TruckBtn({company,truck}) {
  const truckCategories = useServerAssets(`/assets/truckCategories`);
  const {data:me} = useUserData(); 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient();
  if(!company && truck?.companyId?.name){
    company = truck.companyId;
  }

  const createBtn = useMutation({
    mutationFn: async (newTruck)=>{
      const rspnse = await goFetch('/trucks/create', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newTruck)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [`/companies/byId/${company?._id}`] })
      
      if(!data.error){
        setOpen(false);
      }
    },
  })

  const mutateTruck = useMutation({
    mutationFn: async (submit)=>{
      const rspnse = await goFetch(`/trucks/mutate/${truck._id}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(submit)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/trucks/byId/${truck._id}`] })
        setOpen(false);
      }
    },
  })


  const formRef = useRef()
  const displayText =  truck ? 'edit' : 'add';

  function handleSubmit(evt){
    evt.preventDefault();
    const submit = {
      truckNr:formRef.current.elements.truckNr?.value,
      make:formRef.current.elements.make?.value,
      model:formRef.current.elements.model?.value,
      
      licensePlate:formRef.current.elements.licensePlate?.value,
      category:formRef.current.elements.category?.value,
      truckType: truckCategories.data.indexOf(formRef.current.elements.category?.value),
      cDotId: formRef.current.elements.cDotId.value,
      companyId:company?._id ?? Auth.getUserData().companyId
    }
    // console.log(submit)

    if(truck){
      mutateTruck.mutate(submit);
    } else {
      createBtn.mutate(submit);
    }


  }
  function hardcoded(){
    const types=["Side Dump", "End Dump","Tandem","Belly Dump","Truck and Trailer","Flat Bed","Drop Deck","Low Boy","Crane","Concrete"]
    return types.map(type=>(<option value={type} key={type}>{type}</option>))
  }
  
  return (
    <div>
      <button onClick={handleOpen} className="oBtn">{displayText} truck</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
      <Error className=" animate__headShake">{createBtn}</Error>
        <h1>{displayText} truck to {company?.name ?? me?.companyId.name}</h1>
          <form onSubmit = {handleSubmit} ref={formRef} method="dialog">
            <div className='text-left'>
              <label htmlFor="truckNr"  className="flex justify-between items-center">
                <h3>Truck Number: </h3>   
                <input type="text" name="truckNr"  className='textInput' required placeholder='AAC-001' defaultValue={truck? truck.truckNr : ''} />
              </label>
              <label htmlFor="make"  className="flex justify-between items-center">
                <h3>make: </h3>   
                <input type="text" name="make"  className='textInput' required placeholder='Toyota, Chevy, etc.' defaultValue={truck? truck.make : ''} />
              </label>
              <label htmlFor="model"  className="flex justify-between items-center">
                <h3>model:</h3>    
                <input type="text" name="model"  className='textInput' required placeholder='F-150, Sierra, etc.'  defaultValue={truck? truck.model : ''} />
              </label>
              <label htmlFor="licensePlate"  className="flex justify-between items-center">
                <h3>licensePlate&nbsp;#:</h3>  
                <input type="text" name="licensePlate"  className='textInput' required placeholder='xxx-xxx' defaultValue={truck? truck.licensePlate : ''}  /> 
              </label> 
              <label htmlFor="cDotId"  className="flex justify-between items-center"  >
                <h3>cDotId:  </h3>
                <input placeholder='DOT1234567' type="text"  className='textInput' name="cDotId" required defaultValue={truck? truck.cDotId : ''}/>
              </label>
              <label htmlFor="category"  className="flex justify-between ">
                <h3>category: </h3>  
              <select className='text-black' name="category" defaultValue={truck? truck.category : ''} >
                {
                  truckCategories?.data?.map(type=>(<option value={type} key={type}>{type}</option>)) ?? hardcoded()
                }
              </select>

              </label>
            </div>
            <br/>
            <br/>
            <div className='flex justify-around'>
              <button onClick={handleClose} className="oBtn" type="button">Close</button>
              <button onClick={handleSubmit} className="oBtn">{displayText}</button>
            </div>

          </form>
          <MutateStatus useMutation={createBtn} />
        </ModalMaterialUI>
    </div>
  )
}
