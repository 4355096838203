import {useState,useRef} from 'react'
import ModalMaterialUI from './ModalMaterialUI';
import { useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import MutateStatus from 'components/MutateStatus';

export default function DeleteAssignBtn({groupBy}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formRef = useRef()
  const queryClient = useQueryClient()
  const deleteByGroup =  useMutation({
    mutationFn: async (groupBy)=>{
      const rspnse = await goFetch(`/tickets/byGroup/${groupBy}`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [`/tickets/groupBy/${groupBy}`] })
      setOpen(false);
    },
  })

  function handleSubmit(evt){
    evt.preventDefault();
    if(formRef.current.elements.refer.value=== 'Delete'){
      deleteByGroup.mutate(groupBy);
    }
  }
  return (
    <>
      <button onClick={handleOpen} className="dangerBtn">Delete</button>
      <ModalMaterialUI open={open} onClose={ handleClose }>
          <h1>Delete Tickets</h1>
          { deleteByGroup.status ? <div>{deleteByGroup.status}</div> : <div/> }
          <form onSubmit = {handleSubmit} ref={formRef}  >
            <h2> Please enter: </h2>
            <h3> Delete </h3>
            
            <label htmlFor="refer"  ><input type="text" name="refer" required  /></label>
            <button onClick={handleSubmit} className="oBtn">delete</button>
            <button onClick={handleClose} className="oBtn">Close</button>
          </form>
          <MutateStatus useMutation={deleteByGroup} />
      </ModalMaterialUI>
    </>
  )
}
