import { useEffect,useState } from 'react'
import { useCompanyById } from "hooks/useCompanyById"
import { useJobByJobId } from "hooks/useJobByJobId"
import { useTicketsByCompId } from 'hooks/useTicketsByCompId';
export const useCompanyAvailability = (companyId,jobId) => {
  const organF = useCompanyById(companyId)
  const jobF = useJobByJobId(jobId)
  const assignsF = useTicketsByCompId(companyId)
  const [maxRequestAllowed,setMaxRequestAllowed] = useState(0);
  function orderDatesByAssignGroupBy(tickets){
    let groupBy = {};
    for(let i in tickets){
      if(!groupBy[tickets[i].groupBy]){
        groupBy[tickets[i].groupBy] = [];
        groupBy[tickets[i].groupBy].push(tickets[i].startDate)
      } else {
        groupBy[tickets[i].groupBy].push(tickets[i].startDate)
      }
    }
    return groupBy;
  }
  function analyzeDays(workDays, tickets){
    let groupBy = orderDatesByAssignGroupBy(tickets);
    let totalScheduleConflicts = 0;
    for(let k in groupBy){
      for(let i in groupBy[k]){
        const found = workDays.find(day => {
          return day.slice(0,10) === groupBy[k][i].slice(0,10);
        })
        if(found){
          totalScheduleConflicts++;
          break;
        }
      }
    }
    return totalScheduleConflicts;
  }


  useEffect(() => {
    let maxRequest = organF.data?.drivers?.length > organF.data?.trucks?.length ? organF.data?.trucks?.length : organF.data?.drivers?.length;
    if(jobF?.data?.workDays &&  assignsF?.data){
      maxRequest -= analyzeDays(jobF.data.workDays, assignsF.data);
      if(maxRequest < 0) maxRequest = 0;
    }
    setMaxRequestAllowed(maxRequest);
  }, [organF.data,jobF.data,assignsF.data])
  

  return [maxRequestAllowed];
}