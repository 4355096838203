import {useActiveDrivers} from "hooks/useActiveDrivers";
import LoadingIcon from "components/stateIcons/LoadingIcon";
import TableBtnDetail from "../TableBtnDetail"

export default function SignedInDrivers() {
  const {data,error,isError,isLoading} = useActiveDrivers();
  if (isLoading) {
    return  <LoadingIcon />
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }
  return (
    <div>
      <h1 className="stageTitle">Active Drivers</h1>
      <TableBtnDetail column= {['username']} dataArray={data}/>
    </div>
  )
}
