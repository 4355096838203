import {useRef} from 'react';
import {useUserData} from 'hooks/useUserData'
import { useServerAssets } from 'hooks/useServerAssets';
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import MutateStatus from 'components/MutateStatus';
import {useMutation,useQueryClient} from '@tanstack/react-query'

export default function RoleModal({company,role,setOpen}) {
  const allPermissions = useServerAssets(`/assets/userPermissions`);
  const {data:me} = useUserData(); 
  const queryClient = useQueryClient();

  if(!company && role?.companyId?.name){
    company = role.companyId;
  }

  const createBtn = useMutation({
    mutationFn: async (newRole)=>{
      const rspnse = await goFetch(`/companies/mutateAddRole/${company._id}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newRole)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/companies/byId/${company._id}`] })
        if(setOpen) setOpen(false);
      }
    },
  })
  const found = company.roles.find(r=>r.roleName === role);
  let obj = {};
  allPermissions?.data?.forEach(perm=>{
    obj[perm] = false;
  })

  found?.permissions.forEach(perm=>{
    obj[perm] = true;
  })

  const formRef = useRef()
  const displayText =  role ? 'edit' : 'new';

  function handleSubmit(evt){
    evt.preventDefault();
    const submit = {
      role: formRef.current.elements.role?.value ?? role
    }

    let oneChecked = false;
    allPermissions?.data.forEach(perm=>{
      submit[perm] = formRef.current.elements[perm].checked;
      if(formRef.current.elements[perm].checked){
        oneChecked = true;
      }
    })

    if(role){
      createBtn.mutate(submit);
    }
     else {
      if(oneChecked && formRef.current.elements.role.value.length > 0){
        createBtn.mutate(submit);
      }
    }
     
  } 

  return (
      <>
        <Error className="top-0 left-0">{createBtn}</Error>
        <h1>{displayText} {role??'role'} {role? 'in' : 'to'} {company?.name ?? me?.companyId.name}</h1>
        <form onSubmit = {handleSubmit} ref={formRef} method="dialog">
          <div className='text-left'>
            {
              role ?
              <></>:
              <label htmlFor="role"  className="flex justify-between">
                <h3>Role: </h3>   
                <input type="text" name="role" required placeholder='Admin,Driver' defaultValue='' />
              </label>
            }
            {
              allPermissions?.data?.map(perm=><label htmlFor={perm}  className="flex justify-between">
                  <h3>{perm} </h3>   
                <h2>{ obj[perm] }</h2>
                  <input type="checkbox" name={perm} required placeholder='Admin,Driver' defaultChecked= {obj[perm]} />
                </label>
              )
            }
          </div>
          <button onClick={handleSubmit} className="oBtn">{displayText}</button>
        </form>
        <MutateStatus useMutation={createBtn} />
      </>
  )
}
