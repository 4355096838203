export default function SubmitNotes() {
  return (
  <label className="flex items-center flex-col md:flex-row">
    <h1 className='text-white'>
    Notes:
    </h1>
    <textArea name="note"> </textArea>
  </label>
  )
}
