import {useQuery} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useActiveDrivers = () => {
  return useQuery({ queryKey: [`activeDrivers`], queryFn: async()=>{
    const rpnse = await goFetch(`/users/activeDrivers`, {
      headers: { "Content-Type": "application/json" },
    });
    const rpn = await rpnse.json();
    return rpn;
  }})  
}