import axios from 'axios';
import Auth from "../utils/auth";

export const useAxios = () => {
  const token = Auth.getToken()

  let _baseURL = '';
  if(window.location.hostname == 'localhost'){
    _baseURL = `https://haulr-api.dev.sarithm.com`
  } else {
    _baseURL = `https://${window.location.hostname}`;
  }

  const axiosConfig =  {
    baseURL: _baseURL,
    headers: {
      'x-access-token': token || '',
      'user-language': localStorage.getItem("language") || 'en',
      'platform': 'web',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  };
  const axiosInstance = axios.create(axiosConfig);

  const interceptor = axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      console.log('---1---', error);
      if (error.response && error.response.status === 401) {
        axiosInstance.interceptors.response.eject(interceptor);
        try {
          console.log('---2---');
          Auth.logout();
          return Promise.reject(error);
        } catch (_err) {
          console.log('---3---');
          Auth.logout();
          return Promise.reject(_err);
        }
      }
      console.log('---4---');
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};
