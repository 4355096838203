export const UploadDocumentsLocaliseString = {
  en:{
    upload_documents: "Upload Documents",
    upload_image: "Upload Image",
    submit: "Submit",
    remove: "Remove",
    account_under_review: "Your account is under review",
    check_later: "Please come back later once it is approved !",
    upload: "Upload",
    company_name: "Company Name",
    company_address: "Company Address",
    review_rejected: "Review Rejected",
    your_account_verified: "Your account verified",
    log_out: "Log Out",
    sent_for_review: "Sent for Review!",
    error: "Error!",
    enter_company_name: "Please enter company name",
    enter_company_address: "Please enter company address",
    upload_all_documents: "Please upload all documents",
    upload_rejected_documents: "Please upload all rejected documents"
  },
  es:{
    upload_documents: "subir documentos",
    upload_image: "Cargar imagen",
    submit: "Entregar",
    remove: "Eliminar",
    account_under_review: "Su cuenta está bajo revisión",
    check_later: "¡Vuelva más tarde una vez que esté aprobado!",
    upload: "Subir",
    company_name: "nombre de empresa",
    company_address: "Dirección de la empresa",
    review_rejected: "Revisión rechazada",
    your_account_verified: "Tu cuenta verificada",
    log_out: "Cerrar sesión",
    sent_for_review: "¡Enviado para revisión!",
    error: "¡Error!",
    enter_company_name: "Por favor ingrese el nombre de la empresa",
    enter_company_address: "Por favor introduzca la dirección de la empresa",
    upload_all_documents: "Por favor cargue todos los documentos",
    upload_rejected_documents: "Por favor cargue todos los documentos rechazados"
  }
}