import {useState,useRef,useEffect} from 'react'
import dayjs from 'dayjs'
import SubmitName from './SubmitName';
import SubmitDate from './SubmitDate';
import Auth from 'utils/auth';
// import SubmitTime from './SubmitTime';
// import SubmitNote from './SubmitNote';
// import SubmitRate from './SubmitRate';
import SubmitMaterial from './SubmitMaterial';
import SubmitTruck from './SubmitTruck';
// import SubmitInterval from './SubmitInterval';
// import SubmitConstruction from './SubmitConstruction';
// import SubmitDropLocation from './SubmitDropLocation';
import { useCreateProject } from "hooks/useCreateProject";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Error from 'components/stateIcons/Error';
import { useNavigate } from 'react-router-dom'
import { useServerAssets } from 'hooks/useServerAssets';
export default function SubmitJob() {
  dayjs.extend(customParseFormat)
  const [stage,setStage] = useState(0);
  const [truckTypes,setTruckTypes] = useState([]);
  // const [construction,setConstruction] = useState();
  const [dates,setDates] = useState([]); // passed too SubmitDate to modifty, this is an array of working days.
  const formRef = useRef();
  const createProject = useCreateProject();
 const truckCategories = useServerAssets(`/assets/truckCategories`);
  const stages = [
    <SubmitName/>,
    <SubmitMaterial/>,
    <SubmitDate dates={dates} setDates={setDates}/>,
    <SubmitTruck truckTypes={truckTypes} setTruckTypes={setTruckTypes}/>,
  ];
  const navigate = useNavigate();
  function prevStage(evt){
    if(stage > 0){
      setStage(stage-1);
    }
  }
  function nextStage(evt){
    if(stage < stages.length-1 ){
      setStage(stage+1);
    }
  }
  async function submit(evt){
    evt.preventDefault();
    const start =    dayjs(formRef.current.elements.startDate.value, "YYYY-MM-DD")
    const end =    dayjs( formRef.current.elements.endDate.value, "YYYY-MM-DD")
    const submit={
      name:formRef.current.elements.jobName.value,
      materialHauled:formRef.current.elements.materialHauled.value,
      materialAmount:formRef.current.elements.materialAmount.value,
      createdBy:Auth.getUser().data._id, 
      constructionId: Auth.getUser().data.companyId._id,
      truckCatorgies:truckTypes, // array of strings
      truckTypes : truckTypes.map(type=>truckCategories.data.indexOf(type)), // array of numbers
      scheduledStart:start.format() ,
      scheduledEnd:end.format() ,
    }

    createProject.mutate(submit);
  }

  useEffect(() => {
    if(!createProject.data?.error && createProject.data?._id){
      navigate(`/dashboard?stage=ProjectDetail&data=${createProject.data?._id}`);
    } else if(createProject.data?.error) {
      alert(createProject.data.error.message)
    }
  }, [createProject.data])


  return (
    <div className='flex flex-col justify-top items-center'>
      <Error className="top-1 left-1" useMutation={createProject}/>
      <h1 className="text-white">New Project</h1> 

      
      <form ref={formRef} onSubmit={submit} className='h-3/5 flex items-center p-5'>
        {
          stages.map((thisStage,index)=>
          <>
          <div className={index === stage ? '' : 'hidden'}>
            {thisStage}
          </div>
          </>
          )
        }
      </form>
      <br/>
      {
          stage === stages.length-1 ? 
          <button className='oBtn h-[5rem] w-[10rem]' onClick={submit}>Submit Job</button>:
          <button className='oBtn h-[5rem] w-[10rem]' onClick={nextStage}>Next</button>
      }
      <br/>
      {
          stage === 0 ? 
          <></>:
          <button className='oBtn h-[5rem] w-[10rem]'  onClick={prevStage}>Prev</button>
      }

    </div>
  )
}
