import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import ChangeTimeTraveledByGroupBtn from 'components/modals/ChangeTimeTraveledByGroupBtn'
import DeleteAssignBtn from 'components/modals/DeleteAssignBtn'
import LoadingIcon from "components/stateIcons/LoadingIcon"
import RejectJobBtn from 'components/modals/RejectJobBtn'
import UserPermission from 'components/wrappers/UserPermission'
import dayjs from 'dayjs'
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import TableBtnDetail from "components/TableBtnDetail"
import goFetch from 'utils/goFetch'
import { useTicketsByCompId } from 'hooks/useTicketsByCompId';
import { useCompanyById } from 'hooks/useCompanyById';
import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useTruckById } from 'hooks/useTruckById';
import { useUserById } from 'hooks/useUserById';
import Auth from 'utils/auth'

export default function TicketDetail() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const groupBy = searchParams.get('data');
  const queryClient = useQueryClient();
//  const navigate = useNavigate();
  dayjs.extend(objectSupport);
  dayjs.extend(customParseFormat)

  const updateAssigns = useMutation({
    mutationFn: async (body)=>{
      const rspnse = await goFetch('/tickets/updateByGroup', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/tickets/groupBy/${groupBy}`] })
      }
    },
  });  
  const rejectByGroup =  useMutation({
    mutationFn: async ({groupBy,unreject})=>{
      const rspnse = await goFetch('/tickets/rejectByGroup', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({groupBy,unreject})
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/tickets/groupBy/${groupBy}`] })
      }
    },
  })


  const {data,isLoading,isError,error} =useQuery({queryKey: [`/tickets/groupBy/${groupBy}`], queryFn: async()=>{
    if(!groupBy) return 
    const rpnse = await goFetch(`/tickets/groupBy/${groupBy}`);
    const rpn = await rpnse.json();
    return rpn;
  }})

  const organQ = useCompanyById(data ? data[0]?.companyId : undefined)
  const driverQ = useUserById(data ? data[0]?.driverId?._id ?? data[0]?.driverId : undefined)
  const truckQ = useTruckById(data ? data[0]?.truckId  : undefined , data ? data[0]?.companyId : undefined )
  const assignsQ = useTicketsByCompId(data ? data[0]?.companyId : undefined);
  const driverRef = useRef();
  const truckRef = useRef();
  let allDrivers;
  let allTrucks;

  if(data?.length === 0){
    return <span className='text-white text-4xl'>Ticket Deleted</span>
  }


  if (isLoading || organQ.isLoading || driverQ.isLoading || truckQ.isLoading ||assignsQ.isLoading) {
    return  <LoadingIcon />
  }

  if (isError || organQ.isError || driverQ.isError || truckQ.isError ||assignsQ.isError) {
    return <span>ERROR SOMEWHER?! {error}</span>
  }
  const tickets = assignsQ.data;
  const driver = driverQ.data;
  const company = organQ.data;
  const truck = truckQ.data;

  function doUpdate(evt){
    const submit = {
      groupBy,
      truckId :truckRef.current.value,
      driverId:driverRef.current.value
    }
    updateAssigns.mutate(submit);
  }
  function acceptAssign(evt){
    const submit = {
      groupBy,
      truckId : truckRef.current.value,
      driverId: Auth.getUserData()._id
    }
    updateAssigns.mutate(submit);
  }

  function unReject(evt){
    rejectByGroup.mutate({groupBy,unreject:true});
  }

  function refreshMenu(){
    if(organQ.data){
      allDrivers = organQ.data.drivers;
      allTrucks = organQ.data.trucks;
      tickets?.map(ticket=>{
        // if we have tickets for this driver?
        // do we have tickets for thsi truck?
        const day = dayjs(ticket.startDate).format('DD/MM/YYYY')
        for(let i = 0; i < data.length;i++){
          const assignDay = dayjs(data[i].startDate).format('DD/MM/YYYY')
          if(assignDay === day && !ticket.rejected && ticket?.driverId?._id &&  ticket?.truckId?._id){
            allDrivers = allDrivers.filter(driver=>driver._id !== ticket?.driverId?._id);
            allTrucks = allTrucks.filter(truck=>truck._id !== ticket?.truckId?._id);
          }
        }
        return driver;
      })
    }
    }
    refreshMenu()

  return (
    <div className='text-slate-200'>
      <h1 className="stageTitle"> Ticket Details </h1>
      <h1 className='text-red-800'> {data[0].rejected ? 'JOB REJECTED' : '' } </h1>
      <h2> # of days: {data.length}</h2>    
      <h2>Company : {company.name} </h2>
      <div className='flex'>
        <h2>Travel Time: {data[0]?.travelTimeMinutes }Minutes </h2>
        <UserPermission perm='ceo'>
          <ChangeTimeTraveledByGroupBtn groupBy = {data[0]?.groupBy}/>
        </UserPermission>
      </div>
      <div className='flex'>
        <UserPermission perm='Companytrucks'>
          <h2>Hourly Rate: ${data[0]?.hourlyRate}/hr</h2>
        </UserPermission>
      </div>

      <UserPermission perm="signupForJob">
        <div className='flex'>
          <button className='oBtn' onClick={acceptAssign}>Accept Ticket</button>
          <select className='text-black' ref={truckRef}>
            {
              allTrucks?.map(truck=><option value={truck._id} key={truck._id}>{truck.licensePlate}/{truck.model}/{truck.make} </option> )
            }
          </select>
        </div>
      </UserPermission>

      <div className=' grid grid-cols-3'>
        <h2>Driver:{driver?.username} </h2> 
      </div>

      <div className=' grid grid-cols-3'>
        <h2>Truck:{truck?.model} </h2>
      </div>

      <UserPermission perm='ceo'>
      <div className=''>
          <select className='text-black p-2' ref={driverRef} >
            {
              allDrivers?.map(driver=><option name={driver.username} key={driver.username} value={driver._id}> {driver.username} </option> )
            }
          </select>
          <hr/>
          <select className='text-black p-2' ref={truckRef}>
            {
              allTrucks?.map(truck=><option value={truck._id} key={truck._id}>{truck.licensePlate}/{truck.model}/{truck.make} </option> )
            }
          </select>
      </div>
      <hr/>
          <div>
            <button className='oBtn' onClick={doUpdate}>Update</button>
          </div>
        </UserPermission>

      <h2 className='flex items-center justify-center'> Tickets </h2>

      <TableBtnDetail column= {['startDate','clockIn','clockOut','ticketNumber']} dataArray={data} clickNav="TruckTicket" />

      <UserPermission perm='ceo'>
      {
        data[0].rejected ?
        <button className='oBtn' onClick={unReject}>unreject</button>:<RejectJobBtn groupBy={groupBy}/>
      }
      </UserPermission>
      <UserPermission perm='ceo'>
        <DeleteAssignBtn groupBy={groupBy}/>
      </UserPermission>
      
      { updateAssigns.status !== 'idle' ? <div>{updateAssigns.status}</div> : <></>}
    </div>
  )
}
