import './JobDetail.css';
import {useEffect,useState} from "react";
import AwaitingDriver from "./AwaitingDriver";
import Scheduled from "./Scheduled";
import {useJobByJobId} from "hooks/useJobByJobId"
import {useMutateJob} from "hooks/useMutateJob"
import UserPermission from "components/wrappers/UserPermission";
import RefetchIcon from "components/stateIcons/RefetchIcon";
import DeleteJobBtn from "components/modals/DeleteJobBtn";
import { useSearchParams } from 'react-router-dom';
import LoadingIcon from "components/stateIcons/LoadingIcon";
import Map, { Marker,Source, Layer  } from "react-map-gl";
import dayjs from 'dayjs'
import "mapbox-gl/dist/mapbox-gl.css";
import truck from 'assets/truck.png'
import { useWakeLock } from 'react-screen-wake-lock';
const Status = {
  Pending:"Pending",
	AwaitingDrivers: "Scheduling",
  Scheduled:"Scheduled",
	DriversWorking: "Drivers Working",
	AwaitingAction: "Awaiting Action",
	AwaitingPayment: "Awaiting Payment",
	Completed: "Completed"
};
let didStatus;

export default function JobDetail() {
  const [driverPos, setDriverPos] = useState({});
  const [driverData, setDriverData] = useState([]);
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const {isLoading,isError,error,data:job,fetchStatus} = useJobByJobId(searchParams.get('data'));
  const jobMutate = useMutateJob(searchParams.get('data'));
    const { request, release } = useWakeLock({
  });  
  let displayStatus;
  useEffect(() => {
    if(job?.status === Status.Pending && !didStatus){
      didStatus = true;
     jobMutate.mutate({jobId:searchParams.get('data') , mutate:{status:"Scheduling"}} )
    }
  }, [job,searchParams,jobMutate])
  
  useEffect(() => {
    if(job?.locations){
      let totalLat = 0;
      let totalLong = 0;
      job.locations.forEach(loc=>{
        totalLat+=loc.latitude 
        totalLong+=loc.longitude
      })
      setDriverPos({
        ...driverPos,
        latitude: totalLat/job.locations.length,
        longitude: totalLong/job.locations.length,
        zoom: 3.5,
      });
    }
    const toDay = dayjs();

    if(job?.tickets){
      const todayAssignments = job.tickets.filter(assign=>{
        const assignDate = dayjs(assign.startDate);
        return  assignDate.date() === toDay.date()  && assignDate.month() === toDay.month() && assignDate.year() === toDay.year();
      })
      setDriverData([...todayAssignments] )
    }
    
  }, [job]);

  useEffect(() => {
    request()
  }, [request]);

  useEffect( () => () => {
    release()
  }, [release] );

  if (isLoading) {
    return  <LoadingIcon />
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  if(!job){
    return <span>Job Has been deleted</span>
  }

  switch(job.status){
    case Status.AwaitingDrivers:
      displayStatus = <AwaitingDriver job={job}/>
      break;
    case Status.Scheduled:
      displayStatus = <Scheduled job={job}/>
      break;
    case "Pending":
      displayStatus = <LoadingIcon />
      break;
    default:
      displayStatus = <AwaitingDriver job={job}/>
      break;
  }  
  
	return (
  <div className="w-full md:w-[700px]">
    <div className="detailWrap">
      <h1> {job?.name}</h1>
      {
        fetchStatus === 'fetching' ? 
        <RefetchIcon /> : null
      }
      {displayStatus ?? <div> Error in Staging </div>}
      <UserPermission perm="ceo">
        <DeleteJobBtn jobId={job._id} jobName={job.name} />
      </UserPermission>

    </div>


    {driverPos.latitude && driverPos.longitude ? 
          <Map
            mapboxAccessToken="pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg"
            initialViewState={{
              longitude: driverPos.longitude,
              latitude:  driverPos.latitude,
              zoom: 10.5,
            }}
            style={ window.innerWidth > 600 ?  {width: 700, height: 700}  : {width: 390, height: 200}  }
            mapStyle="mapbox://styles/mapbox/streets-v12"
          >
              {/* 
            <Marker
              longitude={driverPos.longitude}
              latitude={driverPos.latitude}
            /> */}
            {
              job?.locations ?  
                job.locations.map(loc=><Marker longitude={loc.longitude} latitude={loc.latitude} /> )
              : <></>
            }
            {
              driverData.map(driver=> driver.lastLocation ? <Marker longitude={driver.lastLocation.longitude} latitude={driver.lastLocation.latitude}> 
               <img src={truck} alt="logo" className='h-[3rem]'/>
              </Marker> : <></> ) 
            }            
            {
              driverData.map(driver=> driver.geoJsonRoute ? <Source id="my-data" type="geojson" data={JSON.parse(driver.geoJsonRoute)}>
                                        <Layer 
                                        type = 'line'
                                        source = {{type: 'geojson',data: JSON.parse(driver.geoJsonRoute) }}
                                         />
                                      </Source> : <></> ) 
            }
          </Map>:<>Waiting</>    
    }

  </div>);
}
