import {useRef,useEffect} from 'react'
import {useInfiniteQuery,useQueryClient} from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import goFetch from 'utils/goFetch'
import ParseDate from "components/wrappers/ParseDate"
import LoadingIcon from "components/stateIcons/LoadingIcon"

export default function SystemLogs() {
  const observerElem = useRef(null)
  const queryClient = useQueryClient()
  const limit = 10;
  const refetchInterval = 10000;

  const {data,refetch, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, isError,error} = useInfiniteQuery({
    queryKey: ['SystemLogs'], 
    queryFn: async ({pageParam = 1}) => {
      const rpnse = await goFetch(`/event/system/?page=${pageParam}&limit=${limit}`)
      const rpn = await rpnse.json();
      return rpn;
    },
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1
      return nextPage 
    }
    }
  )

  useEffect(() => {
    if(isSuccess ){
      let observer = new IntersectionObserver(()=>{
        fetchNextPage();
      });
      observer.observe(observerElem.current);
    }
  }, [isSuccess,fetchNextPage])

  useEffect(() => {
    
    const interval = setInterval(()=>{
      refetch({ refetchPage: (page, index) => index === 0 })
    },refetchInterval)

    return () => {
      queryClient.removeQueries('SystemLogs')
      clearInterval(interval)
    }
  }, [refetch,queryClient])
  
  if (isLoading) {
    return  <LoadingIcon/>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div>
      <h1 className="stageTitle"> System Logs </h1>
      <div className='h-full  overflow-y-auto bg-white'>
      { isSuccess && data.pages.map(page =>
          page.events.map((evt,index)=>(
            <Link  key={index} to={evt.action}>
              <div className="p-2 m-2 border border-black hover:bg-[#ffe564]"> 
                <h1>{evt.name}</h1> 
                <p className='max-w-2xl'>{evt.description}</p>  
                <ParseDate>{evt.timeStamp}</ParseDate>
              </div>
            </Link>
          ))
      )}
        <div ref={observerElem}>
          {isFetchingNextPage && hasNextPage ? 'Loading...' : 'No search left'}
        </div>      
      </div>
    </div>
  )
}
