import Auth from "utils/auth";
import LoadingIcon from "components/stateIcons/LoadingIcon"
import Stack from "components/Stack";
import { useTicketsByCompId } from 'hooks/useTicketsByCompId';
import { useTicketsGroupBy } from 'hooks/useTicketsGroupBy';

export default function CompanyTickets() {
  const companyId = Auth.getUserData().companyId; //searchParams.get('data');
  const assignsF = useTicketsByCompId(companyId);
  const [stacks] = useTicketsGroupBy(assignsF.data);

    
  if (assignsF.isLoading) {
    return  <LoadingIcon />
  }
  if (assignsF.isError) {
    return <span>Error: {assignsF.error.message}</span>
  }

  return (
    <div>
      <h1 className="stageTitle"> Tickets</h1>
      {
       stacks.map( (stack,index) => <div key={index}><Stack stackData={stack} /></div>)
      }
    </div>
  )
}
