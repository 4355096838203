import './JobCreateTicketModal.css'
import { forwardRef, useRef, useState } from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query'
import LoadingIcon from "components/stateIcons/LoadingIcon"
import SelectCompany from 'components/SelectCompany'
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import dayjs from 'dayjs';
import goFetch from 'utils/goFetch'
import { useCompanyAvailability } from 'hooks/useCompanyAvailability';
import Error from 'components/stateIcons/Error';
import MutateStatus from 'components/MutateStatus';
import { useJobByJobId } from "hooks/useJobByJobId"
import { useJobAssigned } from 'hooks/useJobAssigned'
import { useCompanyById } from "hooks/useCompanyById"
import { v4 as uuidv4 } from 'uuid';

const JobCreateTicketModal = forwardRef(( {jobId,companyId,modalData,setModalData,driverAssigned} , ref) => {
  dayjs.extend(objectSupport);
  dayjs.extend(customParseFormat)
  const queryClient = useQueryClient()
  const [error,setError] = useState(null);
  const formRef = useRef()
  const thisJobId = jobId ?? modalData.jobId;
  const driverOrgId = companyId ?? modalData.driverOrgId;
  const [maxRequestAllowed] = useCompanyAvailability(driverOrgId,thisJobId);
  const [numOfDrivers] = useJobAssigned(thisJobId)
  const [numToRequest,setNumToRequest] = useState(0);
  const [rateToRequest,setRateToRequest] = useState(85);
  const [travelInMinutes,setTravelInMinutes] = useState(0);
  
  if(!driverAssigned){
    driverAssigned = numOfDrivers;
  }

  function setOrgId(id){
    setModalData({...modalData,driverOrgId:id})
  }
  
  const createAssign = useMutation({
    mutationFn: async (newJob)=>{
      const rspnse = await goFetch('/tickets/create', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newJob)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    onSuccess: async (data) => {
      if(!data.error){
        queryClient.invalidateQueries({ queryKey: [`/jobs/byId/${thisJobId}`] })
        queryClient.invalidateQueries({ queryKey: [`/users/byId/${modalData.driverId}`] })
        queryClient.invalidateQueries({ queryKey: [`/companies/byId/${driverOrgId}`] })
        queryClient.invalidateQueries({ queryKey: [`/tickets/byCompany/${driverOrgId}`] })
      }
    },

  });
  
  const jobF = useJobByJobId(thisJobId)
  const organF = useCompanyById(driverOrgId)

  if(!driverOrgId || driverOrgId === 0){

    return  <dialog ref={ref} className=' text-center modalStyle' onClick={backgroundClose}>
              <h2 style={{paddingBottom:"3px"}}>Companies with available drivers:</h2>
              <SelectCompany setOrgId={setOrgId}/> 
            </dialog>
  }
  if (jobF.isLoading ) {
    return  <dialog ref={ref} onClick={backgroundClose}>
              <LoadingIcon/>
            </dialog>
  }

  if (jobF.isError ) {
    return  <dialog ref={ref} onClick={backgroundClose}> 
              <span>
                <h1>Error: {jobF.error?.message}</h1>
              </span> 
            </dialog>
  }


  function backgroundClose(e){
    const dia = ref.current.getBoundingClientRect()
    if(e.clientX < dia.left ||
      e.clientX > dia.right ||
      e.clientY < dia.top ||
      e.clientY> dia.bottom
      ){
        ref.current.close()
      }
  }
  // const driver = driverF.data.user; // Driver data
  const job = jobF.data; // This job data
  const trucking = organF.data // And the company the driver is wrokign for.

  const start = dayjs(job?.scheduledStart)
  const end = dayjs(job?.scheduledEnd)

  const dailyStartTime = dayjs(job?.dailyStartTime);
  const estimatedWorkHours = job?.estimatedWorkHours;
  const workDays = job?.workDays;
  const shiftLengthHours = estimatedWorkHours; // in hours.


  const numOfHours = end.diff(start,'hours')
  const numOfDays = Math.ceil(numOfHours/24);

  let dates = [];
  let times = [];

if(workDays.length){
  dates = workDays.map(wDay=>dayjs(wDay))
} else {
  for (let i = 0; i < numOfDays; i++) {
    dates.push(start.add(i,'day'));
  }
}
  let offSetMax = job?.trucksPerInterval;
  let offSetMin = job?.truckIntervalMinutes;
  let startTimes = 1
  if(offSetMax){
    startTimes = Math.ceil(job?.trucksPerInterval/offSetMax);
  }

  for (let i = 0; i < startTimes; i++) {
    if(offSetMin){
      times.push(dailyStartTime.add(i*offSetMin,'minutes').format('HH:mm'));
    } else {
      times.push(dailyStartTime.format('HH:mm'));
    }
  }  
  
  // function changeDistance(event){
  //   setTravelInMinutes(event.target.value)
  // }
  function checkRate(event){
    setRateToRequest(event.target.value)
  }
  function checkRequest(event){
    if(Number(event.target.value) <= maxRequestAllowed || event.target.value === ""){
      setNumToRequest(event.target.value)
      setError(null)
    }else{
      setError("Cannot request more trucks than available")
      setNumToRequest(event.target.value)
    }
  }

  function handleSubmit(evt){

    if(numToRequest > job?.numberOfTrucksNeeded){
      alert ('too many')
      return;
    }
    evt.preventDefault();
    let submit = [];
    for(let i=0;i<numToRequest;i++){
      const groupBy = uuidv4()
      for(let day of dates){
        const startDateObj = dayjs(day)
        const obj = {
          companyId:trucking._id,
          jobId: job?._id,
          startDate:startDateObj,
          endDate:startDateObj.add(shiftLengthHours,"hours"),
          buyerId: job?.createdBy,
          hourlyRate:rateToRequest,
          travelTimeMinutes:travelInMinutes,
	        maxDestinationIndex: job.locations.length-1,
          groupBy,
          //driverId: driver?._id,
          //truckId:
          //hourRateByItem:      
        }
        submit.push(obj);
      }
    }

    setError(null)

    createAssign.mutate(submit);

  }

  return (
      <dialog className='modalStyle' ref={ref} onClick={backgroundClose}>
        {
          companyId ? <></> :<SelectCompany setOrgId={setOrgId} current={driverOrgId}/> 
        }
        

        <div className='modalInfo'>
          <Error className="top-1 left-1" useMutation={createAssign}/>
          <div className="titleContainer" style={{display:"flex",flexDirection:"row"}}>
          <h1>{trucking?.name}</h1>
          </div>
         
          { error ? <> <h1 className='text-red-500'>{error}</h1> </>: <></>}
          <form onSubmit = {handleSubmit} ref={formRef} method="dialog" className="flex flex-col">
            <div style={{display:"flex",flexDirection:"row"}}>
              <div className="flex flex-col p-2 max-h-[50rem] overflow-y-auto">
                <h2> Job date: {start.format('M/DD')} - {end.format('M/DD')} </h2>
                <h2> Shift: {dailyStartTime.format('h:MM A')}</h2>
                <h2> Hours: {estimatedWorkHours}</h2>
                <h2>{trucking?.name} has {maxRequestAllowed} drivers available</h2>
                <h2> Job needs : {job?.numberOfTrucksNeeded} trucks </h2>
                <hr/>
                {maxRequestAllowed && job?.numberOfTrucksNeeded-driverAssigned > 0 ? 
                  <>
                    <h1>Request Draft</h1>
                    <label className='flex justify-between'> Rate: <input name="rate" type="number"  min="1"  value={rateToRequest} onChange={checkRate}/></label>
                    <div className='requestFormContainer' style={{display:"flex",flexDirection:"column"}}>
                      <label className='flex justify-between'>Trucks: <input name="numToRequest" min="0"  type="number" value={numToRequest} onChange={checkRequest}/> / {maxRequestAllowed > job?.numberOfTrucksNeeded ? job?.numberOfTrucksNeeded : maxRequestAllowed}
                      </label>
                    </div>
                  </>:
                  <></>
                }
              </div>

              </div>
            <div>
              {
                job?.numberOfTrucksNeeded-driverAssigned > 0 ? 
                <>
                  <button onClick={handleSubmit} className="oBtn">Submit Request</button>
                  <button onClick={()=>ref.current.close()} className="oBtn" type="button">Cancel</button>
                  <MutateStatus useMutation={createAssign} />
                </>
                :
                <></>
              }
            </div>
          </form>
          </div>
      </dialog>
  );
});

export default JobCreateTicketModal;