import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Auth from 'utils/auth'

export const useUserMutate = () => {
  const queryClient = useQueryClient()
  let cacheUserId;
  return useMutation({
    mutationFn: async ({userId,body})=>{
      cacheUserId = userId;
      console.log(body,userId)
      const rspnse = await goFetch(`/users/mutate/${userId}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    // Always refetch after error or success:
    onSettled: () => {
      // if(onSettled) onSettled();
      if(Auth.getUserData()._id === cacheUserId){
        queryClient.invalidateQueries({ queryKey: [`/users/me`] })  
      }
      queryClient.invalidateQueries({ queryKey: [`/users/byId/${cacheUserId}`] })
    },
  })
}