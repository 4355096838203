import InviteNewCompanyBtn from "components/modals/InviteNewCompanyBtn"
import LoadingIcon from "components/stateIcons/LoadingIcon"
import TableBtnDetail from "components/TableBtnDetail"
import UserPermission from "components/wrappers/UserPermission"
import { useAllCompanies } from 'hooks/useAllCompanies'
import CreateUserBtn from "components/modals/CreateUserBtn"
import { useEffect,useState } from "react"
export default function AllCompanies() {
  
  const {data,isLoading,isError,error} = useAllCompanies()
  const [truckCo,setTruckCo] = useState([]);
  const [constructionCo,setConstructionCO] = useState([]);
  const [haulr,setHaulr] = useState([]);

  useEffect(() => {
    if(data){
      let truck =[];
      let construct = [];
      data?.forEach(company=>{
        if(company.type === 'trucking'){
          truck.push(company)
        } else if(company.type === 'construction'){
          construct.push(company)
        } else if(company.name === 'haulrcorp'){
          setHaulr([company])
        }
      })
      setTruckCo(truck)
      setConstructionCO(construct)
    }
  }, [data])
  
  if (isLoading) {
    return  <LoadingIcon/>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }
  return (
    <div>
      <h1 className="text-white"> Companies </h1>
      <div className="flex">
        <UserPermission perm="ceo">
          <InviteNewCompanyBtn />
          {/* <CreateUserBtn /> */}
        </UserPermission>
      </div>
      <br/>
      <TableBtnDetail column= {['name','owner','type']} dataArray={haulr} clickNav="CompanyDetail" className='w-[40rem]' />
      <br/>
      <TableBtnDetail column= {['name','owner','type']} dataArray={truckCo} clickNav="CompanyDetail" className='w-[40rem]' />
      <br/>
      <TableBtnDetail column= {['name','owner','type']} dataArray={constructionCo} clickNav="CompanyDetail" className='w-[40rem]' />
    </div>
  )
}
