import LoadingIcon from "components/stateIcons/LoadingIcon"
import { Localization } from 'utils/localization';
import TableBtnDetail from "../TableBtnDetail"
import {useUserProjects} from "hooks/useUserProjects"
import {useState,useEffect} from 'react';
const local = new Localization({
  en:{
    allJobs:"All active jobs"
  },
  es:{
    allJobs:"Todos los trabajos activos"
  }
}).strings;


export default function UserProjects() {
  const result = useUserProjects();
  const [newProjects, setNewProjects] = useState([])
  const [inProgress, setInProgress] = useState([])
  const [completed, setCompleted] = useState([])

  useEffect(() => {
    if(result.data){
      const newProj = [];
      const progress = [];
      const comp = [];
      result.data.forEach(project=>{
        switch(project.state){
          case "New":
            newProj.push(project);
            break;
          case "In Progress":
            progress.push(project);
            break;
          case "Completed":
            comp.push(project);
            break;
        }
        setNewProjects(newProj);
        setInProgress(progress);
        setCompleted(comp);

      });
    }
  
  }, [result.data])
  

  if (result.isLoading) {
    return  <LoadingIcon/>
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>
  }

  return(

       <div className="stageFormat ">
          <h1 className="stageTitle"> Projects  </h1>
          <h1 className="stageTitle"> All: {result.data.length}</h1>
          <TableBtnDetail column= {['name','scheduledStart','scheduledEnd','dateCreated']} dataArray={result.data} clickNav="ProjectDetail" />
          <h1 className="stageTitle"> New: {newProjects.length}</h1>
          <TableBtnDetail column= {['name','scheduledStart','scheduledEnd','dateCreated']} dataArray={newProjects} clickNav="ProjectDetail" />
          <h1 className="stageTitle"> In Progress: {inProgress.length}</h1>
          <TableBtnDetail column= {['name','scheduledStart','scheduledEnd','dateCreated']} dataArray={inProgress} clickNav="ProjectDetail" />
          <h1 className="stageTitle"> Completed: {completed.length}</h1>
          <TableBtnDetail column= {['name','scheduledStart','scheduledEnd','dateCreated']} dataArray={completed} clickNav="ProjectDetail" />
          
       </div>
   )
}