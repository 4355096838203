import {useMutation} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useDeleteJob = () => {
  return useMutation({
    mutationFn: async (jobId)=>{
      const rspnse = await goFetch(`/jobs/${jobId}`, {
        method: 'DELETE'
      });
      const rpn = await rspnse.json();
      return rpn;
    },
  })
}