import './landing-styles.css'
import "../assets/fonts/RobotoCondensed-Bold.ttf"
import { Tab, Tabs } from '@mui/material';
import Footer from 'components/Footer';
import { Localization } from '../utils/localization';
import { landingStrings } from './Landing.local';
import {useEffect, useState} from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const strings = new Localization(landingStrings).strings;
  const [showDriver, setShowDriver] = useState(0);

  function onChangeTabs(e, newValue) {
    if (newValue) {
      setShowDriver(1);
    } else {
      setShowDriver(0);
    }
  }

  useEffect(() => {
    let _userInfo = localStorage.getItem( "userInfo" );
    if ( _userInfo ) {
      _userInfo = JSON.parse( _userInfo ) || {};
    } else {
      _userInfo = {};
    }
    console.log("_userInfo Landing", _userInfo)
    if ( Object.keys( _userInfo ).length > 0 ) {
      const _company = _userInfo?.companyId;
      if ( _userInfo?.role == 'driver' ) {
        // open driver
      }
      else if ( _company?.type == 'construction' ) {

      } else if ( _company?.type == 'trucking' ) {
        navigate( '/company' );
      } else if ( _company?.role == 'driver' ) {

      } else {
        navigate( "/drivers" )
      }
    }
  }, []);

  return (
    <div className="Landing">
      <div className="wrapper">
        <header className='font-Robo text-white flex flex-col'>
        <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: "rgb(255, 216, 21)"
              }
            }}
            textColor='white'
            className='headerTabs flex flex-row items-center'
           value={showDriver}
           onChange={onChangeTabs}
          >
            <Tab label={strings.buyerTab}/>
            <Tab label={strings.driverTab}/>
          </Tabs>
          { showDriver ?
           <div className='headerBox flex flex-col items-center '>
           <h1 className='text-left text-[50px]'>
             <strong>
             {strings.driverStrong1}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.driverString1}
             <br/>
             <strong>
             {strings.driverStrong2}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.driverString2}
             <br/>
             <strong>
             {strings.driverStrong3}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.driverString3}
             <br/>
           </h1>
         </div>
          :
            <div className='headerBox flex flex-col items-center '>
           <h1 className='text-left text-[50px]'>
           <strong>
           {strings.buyerStrong1}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.buyerString1}
             <br/>
             <strong>
             {strings.buyerStrong2}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.buyerString2}
             <br/>
             <strong>
             {strings.buyerStrong3}
             </strong>
             <br className="mediaLineBreak"/>
             {strings.buyerString3}
             <br/>
           </h1>
           </div>
         }
        </header>
        <div className='headerAnchors'>
          <a id="howA" href='#howDiv'>{strings.howItWorks}</a>
          {/* <a href='#priceDiv'>OUR PRICES</a> */}
          <hr></hr>
        </div>

        <div className="left">
          <h1>
            {strings.whoWeAre}
          </h1>
          <p>
          {strings.whoWeAreMsg}
          </p>
        </div>
        <div className="right">
          <h1>
            {strings.whatWeOffer}
          </h1>
          <p>
          {strings.whatWeOfferMsg}

          </p>
          </div>
        <div className="left" id='howDiv'>
          <h1>
            {strings.howToUse}
          </h1>
          <p>
            {strings.howToUseMsg1}
            <br></br>
            {strings.howToUseMsg2}
            <br></br>
            <br></br>
            {strings.driverHowToUse}
          </p>
        </div>
        <div className="right" id="services">
        <h1>
          {strings.ourServices}
        </h1>
          <p>
          {strings.ourServicesMsg}
          </p>
        </div>
        {/* <div id='priceDiv'>
        <PricingTable />
        </div> */}
          <h1 className="whiteText">
            {strings.comingSoon}
          </h1>
        {/* <div className="appStore">
          < AppStoreTemp className="scaleDouble"/>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default Landing;

// Photo by Aleksandar Pasaric: https://www.pexels.com/photo/three-yellow-excavators-near-front-end-loader-1238864/

// Photo by Ilya: https://www.pexels.com/photo/construction-vehicles-at-work-5328418/

// Photo by Ikbal Alahmad: https://www.pexels.com/photo/heavy-equipment-on-construction-site-10421763/

// Photo by Ivan: https://www.pexels.com/photo/front-load-loader-beside-white-dump-truck-188679/