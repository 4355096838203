export const DriverLocaliseString = {
  en:{
      previous_option: "Previous",
      next_option: "Next",
      of_option: "of",
      view_option: "View",
      name_table_heading: "Name",
      owner_table_heading: "Owner",
      status_table_heading: "Status",
      postal_address_table_heading: "Postal Address",
      first_name_detail: "First Name",
      last_name_detail: "Last Name",
      email_detail: "Email",
      phone_number_detail: "Phone Number",
      name_detail: "Name",
      company_id_detail: "Company ID",
      documents_detail: "Documents",
      accept_detail: "Accept",
      reject_detail: "Reject",
      review_comment_detail: "Review Comment",
      click_to_view_zoom_detail: "Click to View Zoom",
      submit_approved_detail: "Submit Approved",
      submit_rejected_detail: "Submit Rejected",
      cancel_detail: "Cancel",
      review_comment_general: "Review Comment - General",
      search_by_placeholder: "Search by First/Last Name, Email, Phone",
      company_name_detail: "Company Name",
      driver_id_detail: "Driver ID",
      loading: "Loading",
      please_wait_detail: "Please wait - Fetching Data",
      reject_reviewComment : "Please enter reason for Rejection! (General Comment)",
      reject_reviewComment_2 : "Please enter reason for document Rejection! (Documents Comment)",
      VERIFIED: "VERIFIED",
      ACCEPTED: "ACCEPTED",
      REJECTED: "REJECTED",
      NEEDS_REVIEW: "NEEDS_REVIEW",
      select_status: "Select a status",
      CREATED: "CREATED",
  },
  es:{
    previous_option: "Previo",
    next_option: "Próximo",
    of_option: "de",
    view_option: "Vista",
    name_table_heading: "Nombre",
      owner_table_heading: "Dueño",
      status_table_heading: "Estado",
      postal_address_table_heading: "Direccion postal",
      first_name_detail: "Nombre de pila",
      last_name_detail: "Apellido",
      email_detail: "Correo electrónico",
      phone_number_detail: "Número de teléfono",
      name_detail: "Nombre",
      company_id_detail: "ID de la compañía",
      documents_detail: "Documentos",
      accept_detail: "Acepta",
      reject_detail: "Rechazar",
      review_comment_detail: "Comentario de revisión",
      click_to_view_zoom_detail: "Haga clic para ver ampliado",
      submit_approved_detail: "Enviar Aprobado",
      submit_rejected_detail: "Enviar rechazado",
      cancel_detail: "Cancelar",
      review_comment_general: "Comentario de revisión: general",
      search_by_placeholder: "Buscar por nombre/apellido, correo electrónico, teléfono",
      company_name_detail: "nombre de empresa",
      driver_id_detail: "Identificación del conductor",
      loading: "Cargando",
      please_wait_detail: "Espere por favor - Obteniendo datos",
      reject_reviewComment : "¡Ingrese el motivo del rechazo! (Comentario general)",
      reject_reviewComment_2 : "¡Ingrese el motivo del rechazo del documento! (Comentario de documentos)",
      VERIFIED: "VERIFICADA",
      ACCEPTED: "ACEPTADA",
      REJECTED: "RECHAZADA",
      NEEDS_REVIEW: "NECESITA_REVISIÓN",
      select_status: "Seleccione un estado",
      CREATED: "CREADA",
  }
}