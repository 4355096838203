import LocalizedStrings from 'react-localization';
export class Localization{
    constructor(localizedStringObject){
        this.strings=new LocalizedStrings(localizedStringObject);
        const lang = localStorage.getItem("language");
        if(lang){
            this.strings.setLanguage(lang);
        }
    }
}
