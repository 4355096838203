// import LoadingIcon from "components/stateIcons/LoadingIcon"
// import InviteNewUserBtn from "components/modals/InviteNewUserBtn";
// import {useQuery} from '@tanstack/react-query'
// import TableBtnDetail from "components/TableBtnDetail";
// import UserPermission from "components/wrappers/UserPermission";
import  {useEffect,useState} from 'react'
import goFetch from 'utils/goFetch'
import quickbooksToken from "utils/quickbooksToken";
import { useSearchParams } from 'react-router-dom';

// import Auth from "utils/auth";
export default function Quickbooks() {
  const [searchParams] = useSearchParams(); //searchParams.get('data')
  const code = searchParams.get('code');
  // const state = searchParams.get('state');
  const realmId = searchParams.get('realmId');
  const [quickToken, setQuickToken] = useState(quickbooksToken.getToken())
  // console.log({code,state,realmId})
  // const companyId = Auth.getUserData().companyId; 
  // const {data,isLoading,isError,error} = useQuery({ queryKey: [`/users/byCompany/${companyId}`], queryFn: async()=>{
  //   if(!companyId) return {};
  //   const rpnse = await goFetch(`/users/byCompany/${companyId}`);
  //   const rpn = await rpnse.json();
  //   return rpn;
  // }})  
  
  // if (isLoading) {
  //   return  <LoadingIcon />
  // }

  // if (isError) {
  //   return <span>Error: {error.message}</span>
  // }

  useEffect(() => {
    if(realmId){
      quickbooksToken.saveTokens({realmId});
    }
  }, [realmId])
  

  async function quickbooksConnect(evt){
    const rpn = await goFetch(`/quickbooks/getAuth`);
    const body = await rpn.json()
    // console.log(body)
    window.location.replace(body.authUri)
  }

  async function fetchToken(evt){
    // const rpn = await goFetch(`/quickbooks/updateQuickbooks`);
    // const body = await rpn.json()
    // console.log(window.location.href);
    const rspnse = await goFetch(`/quickbooks/getToken`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({uri:window.location.href,realmId})
    });    
    const body = await rspnse.json()
    // console.log(body)
    quickbooksToken.saveTokens(body)
    setQuickToken(quickbooksToken.getToken())
//    console.log(token);
  }

  async function updateAccts(evt){
    const token = {...quickToken,realmId};
    // const rspnse = 
    await goFetch(`/quickbooks/updateQuickbooks`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token)
    });    
    // const body = await rspnse.json()
    // console.log(body)
    
  }
  async function clearToken(){
    setQuickToken({})

  }
  // console.log(quickToken)
  return (
    <div>
      <h1 className="stageTitle"> Quickbooks </h1>
      <button className="oBtn" onClick={clearToken}>clear tokens</button>
      {
        quickToken.access_token ?
        <>
        <button className="oBtn" onClick={updateAccts}>Update</button>
        </>
        : code ? <button className="oBtn" onClick={fetchToken}>Fetch tokens</button> : <button className="oBtn" onClick={quickbooksConnect}>  Connect to Quickbooks </button>
      }
    </div>
  )
}
