export default function ParseDate(props) {
	let test = new Date(props.children)
	const options = props?.options ?? {hour12: true, timeStyle: "short", dateStyle:'short'};
	if(Array.isArray(props.children)){
		test = new Date(props.children[0])
	}
	return (
		<>
			{test.toLocaleString('en-US', {timeZone: 'America/Denver', ...options})}
		</>
	);
}
