import Auth from '../../utils/auth';
export default function UserPermission({perm,children,falseDisplay}) {
  return (
    <> 
    {
      Auth.userPerm(perm) ? children :
      <>{falseDisplay??''}</>
    }
    </>
)
}

