import {useState , useEffect} from 'react'
import { useAllCompanies } from 'hooks/useAllCompanies'
export default function SubmitConstruction({construction,setConstruction,projectId}) {
  const {data} = useAllCompanies()
  const [companies,setCompanies] = useState()
  useEffect(() => {
    if(data && setConstruction){
      let arraye= [];
      data.forEach(company=>{
        if(company.type === 'construction'){
          arraye.push({ 
            id:company._id,
            name:company.name
          })
        }
      })
      setCompanies(arraye)
      if(arraye[0]){
        setConstruction(arraye[0].id)
      }
    }
  
  }, [data,setConstruction])

  function changeCon(e){
    setConstruction(e.target.value);
  }
  
  return (
    <label className="flex flex-col items-center">
      {
        projectId?
        <><h1 className='text-white text-center'>
            This is creating for a project 
          </h1>
          {projectId}
        </>:
        <> <h1 className='text-white text-center'>
        Construction company you are creating this job for?
          </h1>
        <select value={construction} onChange={changeCon}>
          {companies?.map(comp=><option value={comp.id} key={comp.name}>{comp.name} </option> )}
        </select> </>
      }
      
    </label>
  )
}
