import LoadingIcon from "components/stateIcons/LoadingIcon"
import InviteNewUserBtn from "components/modals/InviteNewUserBtn";
import {useQuery} from '@tanstack/react-query'
import TableBtnDetail from "components/TableBtnDetail";
import UserPermission from "components/wrappers/UserPermission";
import goFetch from 'utils/goFetch'
import Auth from "utils/auth";
export default function CompanyUsers() {

  const companyId = Auth.getUserData().companyId; 
  const {data,isLoading,isError,error} = useQuery({ queryKey: [`/users/byCompany/${companyId}`], queryFn: async()=>{
    if(!companyId) return {};
    const rpnse = await goFetch(`/users/byCompany/${companyId}`);
    const rpn = await rpnse.json();
    return rpn;
  }})  
  
  if (isLoading) {
    return  <LoadingIcon />
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div>
      <h1 className="stageTitle"> Users for company </h1>
      <UserPermission perm="addBtns">
        <InviteNewUserBtn/>
      </UserPermission>
      <TableBtnDetail column= {['username','role','firstName','lastName','phoneNr','email']} dataArray={data} clickNav="UserDetail" />
    </div>
  )
}
