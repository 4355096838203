import { useUserData } from "../../hooks/useUserDataOnce";
import './styles.css';
import { VerificationBannerLocaliseString } from "./VerificationBanner.local";
import { Localization } from "utils";

export default function VerificationBanner( { companyType } ) {
    const strings = new Localization(VerificationBannerLocaliseString).strings;

    console.log("VerificationBanner", companyType);
    const { data: apiUser, isFetching } = useUserData()
    const companyStatus = apiUser?.companyId?.status || ''
    const isTruckingCompany = companyType == 'trucking'

    const onRejectionPress = () => {
        // navigation.navigate(Routes.landing.review.rejectedScreen)
    }

    const onDocUploadPress = () => {
        // navigation.navigate(Routes.landing.review.rejectedScreen)
    }
    console.log("isFetching", isFetching, companyStatus, apiUser);
    return (
        !isFetching &&
            companyStatus == 'CREATED' ?
            <div className={'div-verification-banner needs-review'}>
                <p className="p-heading p-h-needs-review">{strings.created_upload_documents}</p>
                <p className="p-content p-c-needs-review">{strings.created_content}</p>
            </div>
            :

            companyStatus == 'NEEDS_REVIEW' ?
                <div className="div-verification-banner needs-review" >
                    <p className="p-heading p-h-needs-review">{strings.need_review}</p>
                    { !isTruckingCompany ?
                        <p className="p-content p-c-needs-review">{strings.need_review_trucking}</p>
                        :
                        <p className="p-content p-c-needs-review">{strings.need_review_construction}</p>
                    }
                </div>
                :
                companyStatus == 'REJECTED' ?
                    <div className="div-verification-banner failed" >
                        <p className="p-heading p-h-failed">
                            {strings.rejected}
                        </p>
                        <p className="p-content p-c-failed">
                            {strings.rejected_content}
                        </p>
                    </div>
                    :
                    companyStatus == 'VERIFIED' ?
                    <div className="div-verification-banner completed">
                        <p className="p-heading p-h-completed">
                            {strings.verified}
                        </p>
                        <p className="p-content p-h-completed">
                            {strings.verified_content}
                        </p>
                    </div>
                    :
                    null


    )
}