import {useQuery,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useTruckById = (truckId,companyId) => {
  const queryClient = useQueryClient();
  return useQuery({queryKey: [`/trucks/byId/${truckId }`], queryFn: async()=>{
    if(!truckId) return {};
    const rpnse = await goFetch(`/trucks/byId/${truckId}`);
    const rpn = await rpnse.json();
    return rpn;
  }, 
  initialData: ()=>{
    if(companyId){
      return queryClient.getQueryData([`/trucks/byCompany/${companyId}`])?.find((d)=> d._id === truckId)
    }
  }

  // refetchInterval:2000
})
}