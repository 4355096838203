import {useRef, useState} from 'react'
import JobCreateTicketModal from 'components/modals/JobCreateTicketModal'
import AssignedDriversTable from './AssignedDriversTable'
import ParseDate from 'components/wrappers/ParseDate'
import ChangeJobRateBtn from 'components/modals/ChangeJobRateBtn'
import Auth from 'utils/auth'
import { useJobAssigned } from 'hooks/useJobAssigned'

export default function AwaitingDriver({job}) {
  const [modalData,setModalData] = useState({})
  const [numOfDrivers,companyItems] = useJobAssigned(job._id)
  const modalRef = useRef()

  function showModal(evt){
    evt?.preventDefault();
    const jobId = job._id;
    setModalData({driverOrgId:0,jobId})
    modalRef.current?.showModal()
  }

const startDay = job.scheduledStart.slice(0,10);

const endDay = job.scheduledEnd.slice(0,10);

function showRateByRole(){
  if(Auth.getUserData().permissions.find(role=>role==='jobRate')){
    return <>
      <h2 className='strong2 inline'> Hourly Rate:&nbsp;</h2>
      <h2 className='inline'>${job?.brokerRate ?? '0.00'}</h2>
      <ChangeJobRateBtn jobId={job?._id}/>
      <br/>
    </>  
  }

  if(Auth.getUserData().permissions.find(role=>role==='CompanyJobs')){
    return <>
      <h2 className='strong2 inline'> Hourly Rate:&nbsp;</h2>
      <h2 className='inline'>{job?.brokerRate ?? '$0.00'}</h2>
      <br/>
    </>  
  }
  return <></>  
}


  return (
    <div className='awaitingDetails'>
    <h2 className='strong2 inline'> Total Trucks Needed:&nbsp;{job?.numberOfTrucksNeeded}</h2>
    <h2 className='strong2'> Total Trucks assigned:&nbsp;{numOfDrivers}</h2>
    {
      job?.projectId ? 
      <><h2>Project Name: {job.projectId.name}</h2></>:
      <></>
    }
    { showRateByRole()}
    {job?.truckIntervalMinutes ? <> <h2 className='strong2 inline'>Trucks Per Interval:&nbsp;</h2><h2 className='inline'> {job?.trucksPerInterval} Trucks Every&nbsp;{job?.truckIntervalMinutes} Minutes</h2> </>: <></>}
    {
      startDay === endDay ? 
      (<>
      <h2 className='strong2 inline'>Job Date:&nbsp;</h2>
      <h2 className='inline'><ParseDate options={{dateStyle:'short'}}>{job.scheduledStart} </ParseDate></h2>
      <br/>
      </>
      )
      :
      (
      <>
      <h2 className='strong2 inline'>Job Dates:&nbsp;</h2>
      <h2 className='inline'><ParseDate options={{dateStyle:'short'}}>{job.scheduledStart}</ParseDate> - <ParseDate options={{dateStyle: 'short'}}>{job.scheduledEnd}</ParseDate>
      </h2>
      <br/>
      </>
      )
    }
      <h2 className='strong2 inline'> Shift Start Time:&nbsp;</h2>
      <h2 className='inline'><ParseDate options={{timeStyle:'short'}}>{job.dailyStartTime}</ParseDate></h2>
      <br/>
      <h2 className='strong2 inline'> Shift Length:&nbsp;</h2>
      <h2 className='inline'>{job.estimatedWorkHours} hours</h2>

    <AssignedDriversTable companyItems={companyItems} showModal={showModal}/>
    <JobCreateTicketModal ref={modalRef} modalData={modalData} setModalData={setModalData} driverAssigned={numOfDrivers} />

    </div>
  )
}
