import {useState,useEffect} from "react";
import Auth from "utils/auth"
import UserDropdown from './UserDropdown'
import MenuDropup from './MenuDropup'

//const { Localization } = require('../utils/localization' );
export default function BottomNav(props) {
  const [items,setItems] = useState(Auth.getUserData().permissions)
  let {localStrings} = props;
  useEffect(()=>{
    setItems(Auth.getUserData().permissions)
  },[setItems])
  return (
    <div className='flex items-center justify-around top-0 bg-[var(--orange)] w-full'>
      <MenuDropup items={items} localStrings={localStrings}/>
      <UserDropdown localStrings={localStrings}/>
    </div>
  )
}
