import './Footer.css';
import { Link } from 'react-router-dom';
import { footerStrings } from './Footer.local';
import { Localization } from '../utils/localization';

export default function Footer(){

  const strings = new Localization(footerStrings).strings;

  const bottomToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

    return (
        <footer>
        <div className="footerInfo">
        <div className="footerBox">
          <h2>{strings.about}</h2>
          <Link to='/about' onClick={bottomToTop}>{strings.aboutUs}</Link>
          <Link to='/faq' onClick={bottomToTop}>{strings.faqs}</Link>
          
        </div>
        <div className="footerBox">
          <h2>{strings.legal}</h2>
          <Link to='/privacypolicy' onClick={bottomToTop}>{strings.privacyPolicy}</Link>
          <Link to='/fulfillment' onClick={bottomToTop}>Fulfillment Policy</Link>
          <Link to='/tos' onClick={bottomToTop}>{strings.termsOfService}</Link>
          <Link to='/useragreement' onClick={bottomToTop}>{strings.userAgreement}</Link>
        </div>
        <div className="footerBox">
          <h2>{strings.social}</h2>
          <a href='https://www.facebook.com/people/Haulr/100090195770944/' target='_blank' rel="noreferrer">{strings.facebook}</a>

          <a href='https://instagram.com/haulrcorp?igshid=YmMyMTA2M2Y=' target='_blank' rel="noreferrer">{strings.instagram}</a>
        </div>
        <div className="footerBox">
          <h2>{strings.contact}</h2>
          <Link to='/contact' onClick={bottomToTop}>{strings.getInTouch}</Link>
        </div>
        </div>
        <p className="copyright">
            &#169;HaulrCorp 2024
        </p>
      </footer>
    )
}