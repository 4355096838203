import {useQuery,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'

export const useCompanyById = (companyId) => {
  const queryClient = useQueryClient()

  return useQuery({queryKey: [`/companies/byId/${companyId}`], queryFn: async()=>{
    if(!companyId) return {};

    const rpnse = await goFetch(`/companies/byId/${companyId}`);
    const rpn = await rpnse.json();
    return rpn;
  }, 
  initialData: ()=>{
      return queryClient.getQueryData(['allCompanies'])?.find((d)=> d.id === companyId );
  }
  // refetchInterval:2000
})
}