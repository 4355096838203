import "../assets/fonts/RobotoCondensed-Bold.ttf"
import './Navbar.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../utils/auth';
import { navbarStrings } from './Navbar.local';
import goFetch from "utils/goFetch";
import { Localization } from 'utils/localization';
import UserDropdown from './UserDropdown'
import { useServerAssets } from "hooks/useServerAssets";

export default function Navbar(props) {

    const [showNavbar] = useState(true)
    const [localize,setLocal] = useState(localStorage.getItem("language"));
    const strings = new Localization(navbarStrings).strings;
    const supportedLangs = useServerAssets('/assets/supportLanguage')

    const updateLanguage = async (newCode) =>{
      if(supportedLangs?.data.includes(newCode)){
        localStorage.setItem("language",newCode);
        setLocal(newCode);
        // await goFetch('/users/setUserLanguage/'); //goFetch gets localStorage.language and adds it to the header
      }
    }
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
       setIsHover(true);
    };
    const handleMouseLeave = () => {
       setIsHover(false);
    };


    function getStyling() {
        if (showNavbar) {
            return "flex"
        } else {
            return "none"
        }
    }

    return (

    <nav className="flex flex-row text-white items-center bg-[#202020] justify-between">
        <div className="flex items-center justify-center flex justify-center items-center ">
            <div className="text-[48px] brand-title ">
            <Link to="/">
                HAUL'R
            </Link>
            </div>
        </div>
            <div className="navbar-links" style={{display:getStyling()}}>

                    { !props.logged ?
                    <ul>
                    {/* <li><Link to="/">{strings.home}</Link></li> */}
                    {Auth.getUserData().companyName}
                    <li><Link to="/contact">{strings.contact}</Link></li>
                    <li><Link to="/login">{strings.login}</Link></li>
                    </ul>
                    :
                    <ul>
                    {/* <li><Link to="/">{strings.home}</Link></li> */}
                    <li><Link to="/userhome">{strings.dashboard}</Link></li>
                    <UserDropdown/>
                    </ul>
                    }
                    {/* cannot get button to highlight depending on selection */}
                    <div className='langBtns'>
                    {supportedLangs?.data?.map((lang)=>
                        <button className='langBtn oBtn hidden md:block'
                            style={localize === lang ?
                                {background: isHover ? 'var(--lightYellow)' : 'var(--yellow)',borderColor:'var(--yellow)', height:"25px",width:"25px",alignSelf:"center",marginRight:2,fontSize:"small"} :
                                {background: 'var(--lightYellow)',color:'gray',border:'solid black 1px',height:"25px",alignSelf:"center",marginRight:2,fontSize:"small"}
                            }
                            onClick={()=>updateLanguage(lang)}
                            key={lang}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {localize === lang ? <u>{lang}</u>:<b>{lang}</b>}
                        </button>
                    )}
                    </div>
            </div>
    </nav>
    )
}
