
import LoadingIcon from "components/stateIcons/LoadingIcon"
import { useUserData } from "hooks/useUserData"
import { useTicketUpdateByDriver } from "hooks/useTicketUpdateByDriver";
import goFetch from 'utils/goFetch'
import { useState, useEffect, useRef } from "react";
import Map, { Marker,Source, Layer  } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useWakeLock } from 'react-screen-wake-lock';
import { useDriverEvent } from "hooks/useDriverEvent";
import Auth from "utils/auth";
import dayjs from "dayjs";
import { useQueryClient } from '@tanstack/react-query'
import ParseDate from "components/wrappers/ParseDate";
import { useTicketToday } from "hooks/useTicketToday";
import './Driving.css'

export default function Driving() {
  const [driverPos, setDriverPos] = useState({});
  const [showDirection, setShowDirections] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [geoJson, setGeoJson] = useState({});
  const [layerStyle, setLayerStyle] = useState({});
  const [truckFull, setTruckFull] = useState(0);
  const [ticket, setAssignment] = useState({});
  const [workState, setState] = useState('loggedIn'); // States are 'loggedIn'-clockIn>'workingJob'-clockOut>'returnTruck' /
  const [routeTo, setRouteTo] = useState([]);
  const queryClient = useQueryClient()
  const [minutesToDestination, setArrivalTime] = useState(0);
  let map = useRef()
  const userData = useUserData(); // Server state
  const driver = Auth.getUserData(); // Local state
  const assign = useTicketToday(driver?._id);
  const driverEvent = useDriverEvent();
  const sendDriverData = useTicketUpdateByDriver();
  
  const { request, release } = useWakeLock();   // Lock screen.
  let dataInterval;
  useEffect(() => {
    if(assign.data){
      setAssignment(assign.data);
    }
  },[assign.data])
  
  useEffect(() => {
    if(ticket?.clockIn && ticket.clockOut){
      if(userData?.data?.companyId?.address?.longitude){
       const path = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${driverPos.longitude},${driverPos.latitude};${userData.data.companyId.address.longitude},${userData.data.companyId.address.latitude}?steps=true&continue_straight=true&geometries=geojson&access_token=pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg`;
       setPath(path);
      }
    } else
    if(driverPos.zoom && ticket?._id){
      const path = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${driverPos.longitude},${driverPos.latitude};${ticket.jobId.locations[ticket.currentDestinationIndex].longitude},${ticket.jobId.locations[ticket.currentDestinationIndex].latitude}?steps=true&continue_straight=true&geometries=geojson&access_token=pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg`;
      setPath(path);
    }
  }, [driverPos,ticket]);

  useEffect(()=>{
    if(geoJson.type && layerStyle.id ){
      const submit = {
        geoJson,
        layerStyle,
        latitude:driverPos.latitude,
        longitude:driverPos.longitude,
        minutesToDestination: minutesToDestination,
        ticketId:ticket?._id
      }
      sendDriverData.mutate(submit);
    }
  },[geoJson,layerStyle,ticket])

  useEffect(()=>{
    if(  ticket?.currentDestinationIndex !== undefined ){
      setTruckFull(ticket?.currentDestinationIndex);
    }
  },[ticket?.currentDestinationIndex])

  function unloadTruckEvent(evt){
    setTruckFull(0);
    alert('Truck is now unloaded')
    const submit = {
      name:"UnloadTruck",
      description: "Unloaded truck",
      trigger: "Driver clicked button while driving",
      latitude:driverPos.latitude,
      longitude:driverPos.longitude,
      ticketId:ticket?._id
    };

    driverEvent.mutate(submit);
    queryClient.invalidateQueries({ queryKey: [`${driver?._id}todayTickets`] })
  }

  function loadTruckEvent(evt){
    const amnt = prompt("weight amount? ticket#");
    if(amnt){
      setTruckFull(1);
      const submit = {
        name:"LoadTruck",
        description: `Loaded@ ${amnt} `,
        trigger: "Driver clicked button while driving",
        latitude:driverPos.latitude,
        longitude:driverPos.longitude,
        ticketId:ticket?._id
      };
      driverEvent.mutate(submit);
    }
  }

  async function setPath(path){
    const query = await fetch(path ,{ method: 'GET' });
    const json = await query.json();
    if(!json.routes) return;
    const data = json.routes[0];
    const totalTripDuriation = json.routes[0].legs[0].duration;
    const inMin = totalTripDuriation/60;
    setArrivalTime( inMin.toFixed(2) );
    const textRoute = json.routes[0].legs[0].steps.map(step => step.maneuver);
    setRouteTo(textRoute)
    const route = data.geometry.coordinates;
    const geojson2 = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: route
      }
    };
    setGeoJson({
      ...geoJson, ...geojson2
    });
    setLayerStyle( {...{
      id: 'route',
      type: 'line',
      source: {
        type: 'geojson',
        data: geoJson
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#3887be',
        'line-width': 5,
        'line-opacity': 0.75
      }
    }})
   }

  function getNewPos(){
    navigator.geolocation.getCurrentPosition((pos) => {
      setDriverPos({
        ...driverPos,
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        accuracy: pos.coords.accuracy ,
        zoom: 3.5,
      });
      map?.current?.flyTo({center: [ pos.coords.longitude , pos.coords.latitude]})
      
    });    
  }

  useEffect(() => {

   dataInterval = setInterval(getNewPos, 5000);
    
    navigator.geolocation.getCurrentPosition((pos) => {
      setDriverPos({
        ...driverPos,
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        accuracy: pos.coords.accuracy ,
        zoom: 3.5,
      });
    });
    request()
    return () =>{
      clearInterval(dataInterval)
      release()
    }
  }, []);



  useEffect( ()=>{
    if(ticket.clockIn && ticket.clockOut){
      setState( 'returnTruck' )
    } else if(ticket.clockIn){
      setState('workingJob')
    }
   } , [ticket?.clockIn])

   function returnWorkOpts(){
    switch(workState){
      case 'loggedIn':
        return (<>
        <h2 className="text-white text-lg">Go to: { ticket?.jobId?.locations[ticket.currentDestinationIndex].locationType } </h2>
        <h2> @ {ticket.jobId.locations[ticket.currentDestinationIndex].address.postalAddress}</h2>
        <h2> {ticket.jobId.locations[ticket.currentDestinationIndex].address.city}</h2>
        <h2 className="text-white text-lg">Est Arrive In:{minutesToDestination} minutes </h2>
        <h2> Start time: <ParseDate>{ticket.startDate}</ParseDate> </h2>
        <button className="oBtn w-4/5 h-[3rem] text-xl my-3" onClick={clockIn}> Clock in </button>
        </> )
      case 'workingJob':
        return (<>
        <h2> Truck status: {truckFull === 0 ? 'Empty':'Full'}</h2>
        { truckFull === 0 ?
          <button  className="oBtn w-4/5 h-[3rem] text-xl my-3" onClick={loadTruckEvent}> Truck Loaded </button> :
          <button  className="oBtn w-4/5 h-[3rem] text-xl my-3" onClick={unloadTruckEvent}> Truck Unloaded </button>
        }
        <h2 className="text-white text-lg">Go to: { ticket?.jobId?.locations[ticket.currentDestinationIndex].locationType } </h2>
        <h2> @ {ticket.jobId.locations[ticket.currentDestinationIndex].address.postalAddress}</h2>
        <h2> {ticket.jobId.locations[ticket.currentDestinationIndex].address.city}</h2>
        <h2 className="text-white text-lg">Est Arrive In:{minutesToDestination} minutes </h2>
        <button className="oBtn w-4/5 h-[3rem] text-xl my-3" onClick={clockOut}> Clock out </button>
        <h2 className=""> Notes: {ticket.jobId.notes}</h2>
        </>)
      case 'returnTruck':
        return (<>
        <h2 className="text-white text-lg">Go to:  Back to garage </h2>
        <h2> @ {userData?.data?.companyId.address.postalAddress}</h2>
        <h2> {userData?.data?.companyId.address.city}</h2>
        <h2 className="text-white text-lg">Est Arrive In:{minutesToDestination} minutes </h2>
        <button className="oBtn w-4/5 h-[3rem] text-xl my-3" onClick={()=>Auth.logout()}> Logout </button>
        </>)
      default:
        return (<></>)
    }
  }
  

  
  if (assign.isLoading) {
    return  <LoadingIcon />
  }

  if (assign.isError) {
    return <span>Error: {assign.error.message}</span>
  }

  function clockIn(evt){
    if(ticket){
      const time = dayjs();
      if( window.confirm(`Clocked In @ ${time.format('DD/MM/YYYY HH:mm')}`) ){
        goFetch(`/tickets/clockIn/${ticket._id}`)
        queryClient.setQueryData([`${driver._id}todayTickets`],oldData=>{
        if(oldData){
          oldData.clockIn = new Date();
          setState( 'workingJob' )
        }
      })
      }
    }
  }
  function clockOut(evt){
    if(ticket){
      const time = dayjs();
      if( window.confirm(`Clocked Out @ ${time.format('DD/MM/YYYY HH:mm')}`) ){
        goFetch(`/tickets/clockOut/${ticket._id}`)
        queryClient.setQueryData([`${driver._id}todayTickets`],oldData=>{
          if(oldData){
            oldData.clockIn = new Date();
            setState( 'returnTruck' )
          }
        })
      }
    }
  }


   return(
      <div className="w-full md:w-3/4 items-center flex flex-col">
        <h1 className="text-white">
            {ticket._id ? returnWorkOpts() : <> No Job today</>}
        </h1>

          {   driverPos.latitude && driverPos.longitude ? 
            <>
              {/* <h3 className="bg-slate-200">Gps Accuracy {driverPos?.accuracy} meters</h3> */}
              {/* <h2 className="text-white">Arrive In:{minutesToDestination} minutes </h2> */}
              <button className="oBtn w-4/5 h-[3rem] text-xl my-5" onClick={()=>{setShowDirections(!showDirection)}}> Show Directions </button>
              <div className ="text-sm text-slate-200">
                {
                  showDirection? 
                  <>
                    { 
                      routeTo[0] ? <h2 className="text-white">{routeTo[0].instruction} </h2> : <> </>
                    }
                    { 
                      routeTo[1] ? <h3 className="text-white">{routeTo[1].instruction} </h3> : <> </>
                    }
                    { 
                      routeTo[2] ? <h3 className="text-white">{routeTo[2].instruction} </h3> : <> </>
                    }
                  </> :
                  <>
                  </>
                }
              </div>

              <button className="oBtn w-4/5 h-[3rem] text-xl  my-3" onClick={()=>{setShowMap(!showMap)}}> Show Map </button>
              {
                showMap ? 
              <Map
                mapboxAccessToken="pk.eyJ1IjoibGlrZWFmb3giLCJhIjoiY2xqdGQ0YmQ4MDI3MzNobWpsMGt2YXh1NiJ9.413FeRc4xCHFgYgxsTG2tg"
                initialViewState={{
                  longitude: driverPos.longitude,
                  latitude:  driverPos.latitude,
                  zoom: 10,
                }}
                style={{width: 300, height: 200}}
                ref={map}
                mapStyle="mapbox://styles/mapbox/streets-v12"
              >
                <Source id="my-data" type="geojson" data={geoJson}>
                  <Layer {...layerStyle} />
                </Source>
                <Marker
                  longitude={driverPos.longitude}
                  latitude={driverPos.latitude}
                />
              </Map>               
                :
                <></>
              }

            </>:
            <>
            </>
            
          }

        </div>
    )
}