import './Contact.css'
import { useState } from 'react';
import goFetch from "../utils/goFetch";
import { contactStrings } from './Contact.local';
import { Localization } from 'utils';
import Footer from 'components/Footer';
export default function Contact() {

    const strings = new Localization(contactStrings).strings;
    const [formData, setFormData] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit =async( event) => {
        event.preventDefault();
        setSubmitting(true);
        const rspnse = await goFetch('/users/contact/', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
          });    
        await rspnse.json();
        alert("Thank you for contacting us!");
        setFormData({name:'',message:'',email:''})              
        
        setTimeout(() => {
          setSubmitting(false);
        }, 3000)
    }

    return (
    <>
    <div className="Contact">
        <div className="contactWrapper">
            <h1>
                {strings.questions}
            </h1>
            {submitting &&
                <div>{strings.submittingForm}</div>
            }
            <form className='contactForm' onSubmit={handleSubmit}>
                <fieldset className='contactFieldset'>
                    <label>
                        <h2>{strings.name}</h2>
                        <input type="name" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })  }  maxLength="100" placeholder='John Doe'/>
                        <p>{strings.emailAddress}</p>
                        <input type="email"  value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} maxLength="100" placeholder='johndoe@email.com'/>
                        <p>{strings.message}</p>
                        <textarea className="message" message="message" value={formData.message}  onChange={e => setFormData({ ...formData, message: e.target.value })}maxLength="500" placeholder={strings.messagePlacehold}/>
                    </label>
                    <br></br>
                    
                    <button 
                    className='oBtn' 
                    onSubmit={handleSubmit}>{strings.submit}</button>
                 </fieldset> 
            </form>
        </div>
    </div>
    <Footer/>
    </>)
}