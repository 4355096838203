import {useRef,useState} from 'react'
import {useMutation,useQueryClient} from '@tanstack/react-query'
import goFetch from 'utils/goFetch'
import Error from 'components/stateIcons/Error';
import ModalMaterialUI from './ModalMaterialUI';
import MutateStatus from 'components/MutateStatus';
import EditIcon from '@mui/icons-material/Edit';

export default function ChangeJobRateBtn({jobId}) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient()
  const rateRef = useRef()

  const changeRate = useMutation({
    jobId:null,
    mutationFn: async (infobody)=>{
      const rspnse = await goFetch(`/jobs/mutate/${infobody.jobId}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infobody)
      });    
      const rpn = await rspnse.json();
      return rpn;
    },
    // Always refetch after error or success:
    onSettled: () => {
      setOpen(false)
      queryClient.invalidateQueries({ queryKey: [`/jobs/byId/${jobId}`] })
    },
  })
  function handleSubmit(evt){
    evt.preventDefault();
    changeRate.mutate( {  brokerRate:rateRef.current.value ,jobId } )
  }


  return (
    <>
      <button onClick={() => setOpen(true)} className="eBtn"><EditIcon/></button>
      
      <ModalMaterialUI open={open} onClose={ () => setOpen(false) }>
        <Error className="top-1 left-1" useMutation={changeRate}/>
            <h1>Change Rate</h1>
            New Rate:&nbsp;$<input placeholder='0.00' ref={rateRef}/>/hour
            <div className='m-2'>
              <button onClick={() => setOpen(false)} className="oBtn">Close</button>
              <button onClick={handleSubmit} className="oBtn">Update</button>
            </div>
            
        <MutateStatus useMutation={changeRate} />
      </ModalMaterialUI>    
    </>
  )
}
