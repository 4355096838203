export const sideBarLocalizeStrings = {
  en:{
      sidebar_option_driver: "Driver",
      sidebar_option_company: "Company",
      sidebar_option_logout: "Log Out",
      sidebar_option_settings: "Settings",
  },
  es:{
    sidebar_option_driver: "Conductor",
    sidebar_option_company: "Compañía",
    sidebar_option_logout: "Cerrar sesión",
    sidebar_option_settings: "Ajustes",
  }
}