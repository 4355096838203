export const VerificationBannerLocaliseString = {
  en:{
    created_upload_documents: "Upload Documents",
    created_content: "Your company is created, please upload the required documents to get verified.",
    need_review: "Under Review",
    need_review_trucking: "Your company is under review, once approved you will be able to publish projects and jobs.",
    need_review_construction: "Your company is under review, once approved you will be able to take and assign jobs.",
    rejected: "Review Rejected",
    rejected_content: "Your company verification has been rejected.",
    verified: "Review Completed",
    verified_content: "Your company verification has been completed.",
  },
  es:{
    created_upload_documents: "subir documentos",
    created_content: "Su empresa está creada, cargue los documentos requeridos para ser verificada.",
    need_review: "Bajo revisión",
    need_review_trucking: "Tu empresa está en revisión, una vez aprobada podrás publicar proyectos y trabajos.",
    need_review_construction: "Su empresa se encuentra en revisión, una vez aprobada podrá tomar y asignar trabajos.",
    rejected: "Revisión rechazada",
    rejected_content: "La verificación de su empresa ha sido rechazada.",
    verified: "Revisión completada",
    verified_content: "Se ha completado la verificación de su empresa.",
  }
}