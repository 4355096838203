export const navbarStrings = {
    en:{
        welcome:"Welcome",
        home:"Home",
        contact:"Contact",
        login:"Login",
        logout:"Logout",
        dashboard:"Dashboard"
    },
    es:{
        welcome:"Bienvenido",
        home:"Hogar",
        contact:"Contacto",
        login:"Acceso",
        logout:"Cerrar Sesión",
        dashboard:"Panel"
    }
};